import { httpMerchantLimit } from "api/admin/merchant";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import TransactionItem from "components/ui/TransactionItem";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const MerchantLimit: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [minLimit, setMinLimit] = useState(0);
  const [maxLimit, setMaxLimit] = useState(0);
  const userID = params.user as string;

  const {
    data: { vendor, rate },
  } = useQuery(["vendorLimit", userID], () => httpMerchantLimit(userID), {
    initialData: {
      vendor: {},
      rate: {
        buy: {
          usd: 0,
        },
        sell: {
          usd: 0,
        },
      },
    },
    onSuccess: (data) => {
      setMinLimit(data.vendor.minLimit);
      setMaxLimit(data.vendor.maxLimit);
    },
    onError: (err) => {
      navigate(-1);
    },
  });

  const {
    execute: setTheLimit,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/merchants/setLimit/${userID}`, {
        min: minLimit,
        max: maxLimit,
      }),
    false
  );

  const handleSubmit = () => {
    // if (!minLimit || Number(minLimit) < 1)
    //   return showDanger("Amount cannot be less than 0");
    // if (!maxLimit || Number(maxLimit) < 1)
    //   return showDanger("Amount cannot be less than 0");
    if (maxLimit < minLimit)
      return showDanger("Max limit cannot be less than min limit");

    setTheLimit();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message);
    }
  }, [value]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">@{vendor?.limit} limit & balances</h3>
          <div className="w-3/6">
            <Search type="merchant" />
          </div>
        </div>

        <div className="mt-14 flex gap-8">
          <div className="w-7/12">
            <div className="w-full flex gap-5 flex-wrap">
              <div className="border border-gray-500 rounded-lg w-5/12 py-5 px-3">
                <div className="flex justify-between text-sm">
                  <span className="font-light">Naira</span>
                  <i className="fa fa-bitcoin bg-orange-100 p-1 rounded"></i>
                </div>
                <p className="font-medium text-xl my-1">
                  {currency("ngn")} {moneyFormat(vendor?.balance || 0)}
                </p>
                <p className="text-sm font-light">
                  {currency("usd")}{" "}
                  {moneyFormat((vendor?.balance || 0) / (rate?.sell?.usd || 0))}
                </p>
              </div>
            </div>

            <div className="mt-20">
              <h4>Set Limit</h4>
              <div className="mt-5 flex gap-5">
                <div className="relative w-6/12">
                  <input
                    type="number"
                    className="rounded border-gray-400 w-full py-3"
                    value={minLimit}
                    onChange={(e) => setMinLimit(parseInt(e.target.value))}
                  />
                  <span className="font-light absolute top-3.5 right-8">
                    MIN
                  </span>
                </div>
                <div className="relative w-6/12">
                  <input
                    type="number"
                    className="rounded border-gray-400 w-full py-3"
                    value={maxLimit}
                    onChange={(e) => setMaxLimit(parseInt(e.target.value))}
                  />
                  <span className="font-light absolute top-3.5 right-8">
                    MAX
                  </span>
                </div>
              </div>

              <VButton
                status={status}
                onClick={handleSubmit}
                className="bg-black text-white rounded-lg px-20 py-3 font-light mt-8  w-4/12"
              >
                Save
              </VButton>
            </div>
          </div>

          <div className="w-5/12 bg-orange-50 rounded-lg py-10 px-8">
            <h4 className="mb-5">@{vendor?.userName}</h4>

            <TransactionItem title="Username" value={`@${vendor?.userName}`} />
            <TransactionItem
              title="Registered Full Name"
              value={`${vendor?.firstName || ""} ${vendor?.lastName || ""}`}
            />
            <TransactionItem title="e-mail" value={vendor?.email} />
            <TransactionItem title="Phone Number" value="" />
            <TransactionItem
              title="Date registered"
              value={DateTime.fromISO(vendor?.regDate).toFormat("D")}
            />

            <div className="mt-10 flex gap-x-20 gap-y-10 flex-wrap">
              <Link to={`${adminRoutes.merchants}/${vendor?.id}/limit`}>
                <span
                  className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                >
                  Limit & Balances
                </span>
              </Link>
              <Link to={`${adminRoutes.merchants}/${vendor?.id}/transactions`}>
                <span
                  className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                >
                  Transactions
                </span>
              </Link>
              <Link to={`${adminRoutes.merchants}/${vendor?.id}/analytics`}>
                <span
                  className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                >
                  Analytics
                </span>
              </Link>
            </div>
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default MerchantLimit;
