import { httpUSearchMerchant } from "api/admin/merchant";
import { httpUSearchTransaction } from "api/admin/transaction";
import { httpUSearchUser } from "api/admin/user";
import { httpUSearchTransaction as httpUSearchTransactionS } from "api/support/transaction";
import { httpUSearchUser as httpUSearchUserS } from "api/support/user";
import useAsync from "hooks/useAsync";
import useGetSubdomain from "hooks/useGetSubdomain";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adminRoutes, subdomainRoutes } from "routes/routes";
import TransactionInfo from "../../screens/__ADMIN__/transactions/TransactionInfo";
import Spinner from "./Spinner";

interface SearchProps {
  type: "user" | "merchant" | "transaction";
}
const Search: React.FC<SearchProps> = ({ type }) => {
  const [text, setText] = useState("");

  const { subdomain } = useGetSubdomain();

  const isSupport = subdomain === subdomainRoutes.support;
  const isForTransaction = type === "transaction";

  const performSearch = () => {
    if (type === "merchant") return httpUSearchMerchant(text);
    if (isForTransaction) return httpUSearchTransaction(text);
    return httpUSearchUser(text);
  };

  const performSearchForSupport = () => {
    if (isForTransaction) return httpUSearchTransactionS(text);
    return httpUSearchUserS(text);
  };

  const { execute, status, value } = useAsync(
    () => (isSupport ? performSearchForSupport() : performSearch()),
    false
  );

  useEffect(() => {
    if (text.length > 2) execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const placeholder = () => {
    if (type === "merchant") return `Search Merchant`;
    if (isForTransaction) return `Search ID`;
    return `Search User`;
  };

  const nameToShow = () => {
    const tr = value?.transaction;
    if (type === "merchant") return `${value?.merchant?.userName}`;
    if (isForTransaction)
      return (
        <TransactionInfo full transaction={tr} />
        // <>
        //   <h4 className="mb-5">
        //     <span className="text-black text-opacity-50">Type:</span>{" "}
        //     {capitalize(tr?.transactionType)}
        //   </h4>
        //   <TransactionItem
        //     title="Amount"
        //     value={`${currency("ngn")} ${moneyFormat(
        //       Number(tr.amount) - Number(tr.fee)
        //     )}`}
        //     isAmount
        //   />
        //   <TransactionItem
        //     title="Fee"
        //     value={`${currency("ngn")} ${moneyFormat(tr.fee)}`}
        //   />
        //   <TransactionItem title="Transaction ID" value={tr.transactionID} />
        //   <TransactionItem title="Bank name" value={bank?.bankName} />
        //   <TransactionItem title="Account Number" value={bank?.accountNumber} />
        //   <TransactionItem title="Account name" value={bank?.accountName} />
        //   <TransactionItem
        //     title="User"
        //     underline
        //     value={`@${tr?.user?.userName}`}
        //   />
        //   <TransactionItem
        //     title="Registered full name"
        //     value={`${tr?.user?.firstName} ${tr?.user?.lastName}`}
        //   />
        //   <TransactionItem
        //     title="Merchant"
        //     underline
        //     value={`@${tr?.merchant || ""}`}
        //   />
        //   <TransactionItem
        //     title="Date & Time"
        //     value={`${DateTime.fromISO(tr.timestamp).toFormat(
        //       "DD"
        //     )} ${DateTime.fromISO(tr.timestamp).toFormat("t")}`}
        //   />
        //   <TransactionItem
        //     title="Status"
        //     isStatus
        //     value={tr.status}
        //     status={
        //       tr.status === "pending"
        //         ? "text-orange-500 capitalize"
        //         : tr.status === "successful"
        //         ? "text-green-500 capitalize"
        //         : "text-red-500 capitalize"
        //     }
        //   />
        // </>
      );

    return `${value?.user?.firstName} ${value?.user?.lastName}`;
  };

  const link = () => {
    if (type === "merchant")
      return `${adminRoutes.merchants}/${value?.merchant?.id}/analytics`;
    if (isForTransaction) return `#`;
    return `${adminRoutes.balances}/user/${value?.user.id}`;
  };

  return (
    <div className="relative">
      <input
        className="w-full px-10 py-3 bg-gray-200 rounded-lg bg-opacity-70 placeholder:text-gray-400 pl-14"
        placeholder={placeholder()}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <i className="absolute text-gray-400 fa fa-search top-4 left-5"></i>
      {status === "pending" && <Spinner className="absolute top-4 right-5" />}

      {value?.success ? (
        <div className="absolute z-20 w-full px-5 py-8 bg-white rounded shadow-2xl">
          {isForTransaction ? (
            <div>{nameToShow()}</div>
          ) : (
            <Link to={link()}>
              <div>{nameToShow()}</div>
            </Link>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Search;
