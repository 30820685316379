import { get } from "utilities/network";

export const httpDashboardStats = () => {
  return get(`support/stats/dashboard`);
};

export const httpBalances = (type: string) => {
  return get(`support/balances/${type}`);
};

export const httpGetRate = () => {
  return get(`support/transactions/rate`);
};

export const httpAnalytics = (type: string, which: string, date: string) => {
  return get(`support/analytics/${type}?which=${which}&date=${date}`);
};

export const httpTransactionAnalytics = (type: string, date: Date | string) => {
  return get(
    `support/analytics/transactionAnalytics?date=${date}&type=${type}`
  );
};
