import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import { VendorLinkItem } from "components/misc";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";

const Withdrawal: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav title="Withdrawals" />

        <div className="mt-10">
          <VendorLinkItem
            onClick={() => navigate(`${vendorRoutes.withdrawals}/user`)}
            title="Withdrawals for Users"
          />
          <VendorLinkItem
            title="Withdrawals for Admin"
            onClick={() => navigate(`${vendorRoutes.withdrawals}/admin`)}
          />
        </div>
      </Main>
    </Container>
  );
};

export default Withdrawal;
