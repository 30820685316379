import { httpMerchantTransactions } from "api/admin/merchant";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Pagination from "components/ui/Pagination";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import Toggle from "components/ui/Toggle";
import { Itransaction } from "components/ui/Transaction";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import currency, { moneyFormat } from "utilities/currency";
import TransactionInfo from "../transactions/TransactionInfo";

const MerchantTransactions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [transaction, setTransaction] = useState<Itransaction | null>(null);
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 50;

  const userID = params.user as string;

  const [value, setValue] = useState("Deposit Naira");

  const type =
    value === "Deposit Naira"
      ? "deposit"
      : value === "Withdraw Naira"
      ? "withdrawal"
      : "earning";

  const {
    data: { transactions, vendor, total, page },
  } = useQuery(
    ["merchantTransactions", userID, type, currentPage],
    () => httpMerchantTransactions(userID, type, limit, currentPage),
    {
      initialData: {
        transactions: [],
      },
    }
  );

  const handleClick = (tr: Itransaction) => {
    if (transaction?.id === tr.id) {
      setTransaction(null);
      setIsOpen(false);
    } else {
      setTransaction(tr);
      setIsOpen(true);
    }
  };

  const transactionAmount = (tr: Itransaction) =>
    `${currency("ngn")} ${moneyFormat(tr?.amount)}`;

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">@{vendor?.userName} transactions</h3>
          <div className="w-3/6">
            <Search type="merchant" />
          </div>
        </div>

        <div className="my-10">
          <Toggle
            data={["Deposit Naira", "Withdraw Naira", "Earned Naira"]}
            value={value}
            setValue={setValue}
          />
        </div>

        <div className="mt-10 flex gap-8">
          <div className="w-7/12">
            <Table
              header={[
                "Merchant",
                `${value.split(" ")[0] + " amount"}`,
                "Date & Time",
              ]}
              data={[
                ...(transactions || []).map((transaction: Itransaction) => [
                  (transaction.transactionType === "withdrawal" &&
                    transaction?.place === "external") ||
                  (transaction.transactionType === "earning" &&
                    transaction?.address) ? (
                    <span className="text-xs bg-orange-300 rounded px-1">
                      sell.bitoshi.africa
                    </span>
                  ) : (
                    `@${vendor?.userName}`
                  ),
                  `${transactionAmount(transaction)}`,
                  `${DateTime.fromISO(transaction.timestamp).toFormat(
                    "DD"
                  )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`,
                  () => handleClick(transaction),
                ]),
              ]}
            />
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              limit={limit}
              page={page}
              total={total}
            />
          </div>

          {isOpen && <TransactionInfo transaction={transaction} />}
        </div>
      </Main>
    </Container>
  );
};
export default MerchantTransactions;
