import { httpFetchTransactions } from "api/admin/transaction";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Filter from "components/ui/Filter";
import Pagination from "components/ui/Pagination";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import Toggle from "components/ui/Toggle";
import { Itransaction } from "components/ui/Transaction";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import currency, { cryptoFormat, moneyFormat } from "utilities/currency";
import { capitalize } from "utilities/misc";
import TransactionInfo from "./TransactionInfo";

const types = ["naira", "market", "send-receive", "swap", "recurring"];

const typesToggle: any = {
  naira: [
    "Deposit Naira",
    "Send Money",
    "Earned Naira",
    "Refund",
    "Pay Bills",
    "Reverse Deposits",
  ],
  market: ["Buy", "Sell"],
  "send-receive": ["Send", "Receive", "Send Money", "Refund", "Pay Bills"],
  swap: ["Swap"],
  recurring: ["Active plans", "Paused plans", "Cancelled plans", "Purchases"],
};
const typesTitle: any = {
  naira: "Naira",
  market: "Buy & Sell",
  "send-receive": "Send & Receive",
  swap: "Swap",
  recurring: "Recurring Buy",
};

const transactionTypes: any = {
  "Deposit Naira": "deposit",
  "Send Money": "withdrawal",
  "Pay Bills": "bill",
  Refund: "refund",
  "Earned Naira": "earning",
  Buy: "buy",
  Sell: "sell",
  Send: "send",
  Receive: "receive",
  Swap: "swap",
  "Active plans": "active",
  "Paused plans": "paused",
  "Cancelled plans": "stopped",
  Purchases: "purchases",
  "Reverse Deposits": "reverse-deposits",
};

const limit = 50;

const Transactions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [transaction, setTransaction] = useState<Itransaction | null>(null);
  const [filter, setFilter] = useState("external");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const params = useParams();
  const type = params.type as string;

  useEffect(() => {
    if (!types.includes(type)) {
      navigate(adminRoutes.dashboard);
    }
    setValue(typesToggle[type][0]);
  }, [type, navigate]);

  const [value, setValue] = useState(typesToggle[type][0]);

  useEffect(() => {
    setTransaction(null);
  }, [value]);

  const cryptoType = type === "naira" ? "naira" : "crypto";

  const {
    data: { transactions, total, page },
    refetch,
  } = useQuery(
    ["transactions", cryptoType, value, filter, currentPage],
    () =>
      httpFetchTransactions(
        cryptoType,
        transactionTypes[value],
        filter,
        undefined,
        limit,
        currentPage
      ),
    {
      initialData: {
        transactions: [],
      },
    }
  );

  const handleClick = (tr: Itransaction) => {
    if (transaction?.id === tr.id) {
      setTransaction(null);
      setIsOpen(false);
    } else {
      setTransaction(tr);
      setIsOpen(true);
    }
  };

  const transactionAmount = (tr: Itransaction) =>
    tr?.currencyType === "naira" ||
    ["bill", "withdrawal", "refund"].includes(tr?.transactionType)
      ? `${currency("ngn")} ${moneyFormat(tr?.amount)}`
      : `${cryptoFormat(tr?.amount || 0)} ${tr?.crypto?.toUpperCase() || ""}`;

  const firstTitle = () => {
    if (type === "send-receive" || type === "swap") {
      return "Type";
    } else {
      return "User";
    }
  };

  const firstItem = (transaction: Itransaction) => {
    if (value === "Send") {
      return `Sent ${transaction.crypto?.toUpperCase()}`;
    } else if (value === "Receive") {
      return `Received ${transaction.crypto?.toUpperCase()}`;
    } else if (value === "Swap") {
      return `${transaction.fromCrypto?.toUpperCase()} - ${transaction.crypto?.toUpperCase()}`;
    } else if (
      transaction.transactionType === "withdrawal" &&
      transaction.place === "external"
    ) {
      return (
        <span className="px-1 text-xs bg-orange-300 rounded">
          sell.bitoshi.africa
        </span>
      );
    }
    return `@${transaction?.user?.userName}`;
  };

  const secondTitle = () => {
    if (
      value === "Deposit Naira" ||
      value === "Withdraw Naira" ||
      value === "Earned Naira"
    ) {
      return value.split(" ")[0] + " amount";
    } else if (value === "Buy" || value === "Sell") {
      return "Amount";
    } else if (value === "Send" || value === "Receive" || value === "Swap") {
      return "User";
    } else {
      return "Asset";
    }
  };

  const secondItem = (transaction: Itransaction) => {
    if (value === "Send" || value === "Receive" || value === "Swap") {
      return `@${transaction?.user?.userName}`;
    } else if (type === "recurring") {
      return transaction.crypto?.toUpperCase();
    }
    return `${transactionAmount(transaction)}`;
  };

  const thirdTitle = () => {
    if (type === "recurring" && value !== "Purchases") return "Frequency";
    else if (type === "recurring" && value === "Purchases") return "Amount";
    return "Date & Time";
  };

  const thirdItem = (transaction: Itransaction) => {
    if (type === "recurring") {
      if (value === "Purchases") {
        return `${currency("ngn")} ${transaction.amount}`;
      }
      return capitalize(transaction.frequency);
    }
    return `${DateTime.fromISO(transaction.timestamp).toFormat(
      "DD"
    )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`;
  };

  const filters = () => {
    if (value === "Buy") {
      return [
        {
          label: "Buy to external wallet",
          value: "external",
        },
        {
          label: "Buy to Bitoshi wallet",
          value: "bitoshi",
        },
      ];
    } else if (value === "Sell") {
      return [
        {
          label: "Sell from external wallet",
          value: "external",
        },
        {
          label: "Sell from Bitoshi wallet",
          value: "bitoshi",
        },
      ];
    } else if (value === "Send" || value === "Receive") {
      return [
        {
          label: "External transfer",
          value: "external",
        },
        {
          label: "Internal transfer",
          value: "bitoshi",
        },
      ];
    } else if (value === "Swap") {
      return [
        {
          label: "Bitoshi Internal Swap",
          value: "bitoshi",
        },
        {
          label: "Bitoshi - External Swap",
          value: "bitoshi-external",
        },
        {
          label: "External - Bitoshi Swap",
          value: "external-bitoshi",
        },
        {
          label: "External Wallet Swap",
          value: "external",
        },
        {
          label: "Pending Swap",
          value: "pending",
        },
      ];
    }

    return [
      {
        label: "Swap",
        value: "swap",
      },
    ];
  };

  useEffect(() => {
    if (filters().length) {
      setFilter(filters()[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">{typesTitle[type]} Transactions</h3>
          <div className="w-3/6">
            <Search type="transaction" />
          </div>
        </div>
        <div className="my-10">
          {type !== "swap" && (
            <Toggle
              data={typesToggle[type]}
              value={value}
              setValue={setValue}
            />
          )}
        </div>

        <div>
          {["market", "send-receive", "swap"].includes(type) && (
            <div>
              <span className="text-sm">Filter by:</span>
              <Filter data={filters()} setValue={setFilter} value={filter} />
            </div>
          )}
        </div>

        <div className="flex gap-8 mt-14">
          <div className="w-7/12">
            <Table
              header={[firstTitle(), secondTitle(), thirdTitle()]}
              data={[
                ...(transactions || []).map((transaction: Itransaction) => [
                  firstItem(transaction),
                  secondItem(transaction),
                  thirdItem(transaction),
                  () => handleClick(transaction),
                ]),
              ]}
              selected={
                transaction
                  ? [
                      firstItem(transaction),
                      secondItem(transaction),
                      thirdItem(transaction),
                      () => handleClick(transaction),
                    ]
                  : undefined
              }
            />
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              limit={limit}
              page={page}
              total={total}
            />
          </div>
          {isOpen && (
            <TransactionInfo transaction={transaction} refetch={refetch} />
          )}
        </div>
      </Main>
    </Container>
  );
};
export default Transactions;

export const billsTitle: { [key: string]: string } = {
  airtime: "Airtime",
  mobile_data: "Mobile Data",
  cable: "Cable TV",
  electricity: "Electricity",
  betting: "Sport Betting",
  default: "Bill",
};
