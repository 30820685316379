import { get } from "utilities/network";

export const httpFetchUsers = (
  limit: number,
  page: number,
  order: string = "A-Z"
) => {
  return get(`support/users?limit=${limit}&page=${page}&order=${order}`);
};

export const httpUserBalances = (userID: string) => {
  return get(`support/balances/user/${userID}`);
};

export const httpUserWallet = (userID: string) => {
  return get(`support/users/userWallet/${userID}`);
};

export const httpUserRevenue = (
  id: string,
  type: string,
  date: Date | string
) => {
  return get(`support/users/revenue/${id}?date=${date}&type=${type}`);
};

export const httpUserTransactionAnalytics = (
  id: string,
  type: string,
  date: Date | string
) => {
  return get(
    `support/users/transactionAnalytics/${id}?date=${date}&type=${type}`
  );
};

export const httpAcquisitions = (type: string, date: Date | string) => {
  return get(`support/analytics/acquisitions?date=${date}&type=${type}`);
};

export const httpUserTransactions = (
  id: string,
  currencyType: string,
  transactionType: string,
  place: string
) => {
  return get(
    `support/users/transactions/${id}?transactionType=${transactionType}&place=${place}&currencyType=${currencyType}`
  );
};

export const httpUSearchUser = (userName: string) => {
  return get(`support/users/search?search=${userName}`);
};
