const wallets = {
  btc: {
    name: "Bitcoin",
    short: "BTC",
  },
  eth: {
    name: "Ethereum",
    short: "ETH",
  },
  bnb: {
    name: "Binance Coin",
    short: "BNB",
  },
  trx: {
    name: "Tron",
    short: "TRX",
  },
  ltc: {
    name: "Litecoin",
    short: "LTC",
  },
  usdt: {
    name: "TetherUS",
    short: "USDT",
  },
  usdc: {
    name: "USD Coin",
    short: "USDC",
  },
};

export const cryptos = Object.keys(wallets);

export const additionalCryptos = ["usdtrx", "usdtbsc", "usdt20", "bnbbsc"];
export const additionalUSDTCryptos = ["usdtrx", "usdtbsc", "usdt20"];

export const refactoredCryptoKey = (coin: string) => {
  if (additionalUSDTCryptos.includes(coin)) {
    return "usdt";
  } else if (["bnbbsc"].includes(coin)) {
    return "bnb";
  } else {
    return coin;
  }
};

export const externalUsdtNetwork = {
  usdt: "trx",
  usdtrx: "trx",
  usdtbsc: "bnb",
  usdt20: "eth",
  bnbbsc: "bnb",
};

export default wallets;
