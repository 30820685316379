import { httpDashboardStats } from "api/admin/stats";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Table from "components/ui/Table";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { cryptos } from "utilities/wallets";

const Dashboard: React.FC = () => {
  const navigation = useNavigate();

  const {
    data: { stats },
  } = useQuery("dashboardStats", httpDashboardStats, {
    initialData: {
      stats: {
        totalRegisteredUsers: 0,
        totalVendorsBalance: 0,
        totalUsersBalance: 0,
        totalBalance: 0,
        cryptoBalances: {},
        usersNairaBalance: 0,
        totalActiveUsers: 0,
        totalCryptoBalances: 0,
        totalRecurring: 0,
        rate: {
          buy: {
            usd: 0,
          },
          sell: {
            usd: 0,
          },
        },
      },
    },
  });

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-8 mx-5 text-left">
        <div className="flex gap-12">
          <div className="bg-black rounded-lg text-white p-8 w-4/12">
            <h4 className="text-sm font-normal mb-2">Your Total Balance</h4>
            <p className="text-3xl font-medium">
              <span className="text-2xl">{currency("ngn")}</span>{" "}
              {`${moneyFormat(stats.totalBalance)}`}
            </p>
          </div>
          <div className="bg-orange-100 rounded-lg flex items-center p-8 w-3/12">
            <i className="fa fa-user text-2xl text-orange-300 mr-3"></i>
            <div>
              <h4 className="text-3xl mb-1 font-medium">
                {stats.totalRegisteredUsers}
              </h4>
              <p className="text-sm">Registered users</p>
            </div>
          </div>
          <div className="bg-orange-100 rounded-lg flex items-center p-8 w-3/12">
            <i className="fa fa-user text-2xl text-orange-300 mr-3"></i>
            <div>
              <h4 className="text-3xl mb-1 font-medium">
                {stats.totalActiveUsers}
              </h4>
              <p className="text-sm">Active users</p>
            </div>
          </div>
        </div>

        <div className="mt-14">
          <h4 className="font-normal text-lg mb-5">Total Balances</h4>

          <div className="flex gap-10">
            <Table
              header={["#", "Naira", "Dollar"]}
              data={[
                [
                  "Merchants",
                  `${currency("ngn")} ${moneyFormat(
                    stats.totalVendorsBalance
                  )}`,
                  `${currency("usd")} ${moneyFormat(
                    stats.totalVendorsBalance / stats.rate.buy.usd
                  )}`,
                  () => navigation(`${adminRoutes.balances}/merchant`),
                ],
                [
                  "Users",
                  `${currency("ngn")} ${moneyFormat(stats.totalUsersBalance)}`,
                  `${currency("usd")} ${moneyFormat(
                    stats.totalUsersBalance / stats.rate.buy.usd
                  )}`,
                  () => navigation(`${adminRoutes.balances}/user`),
                ],
                [
                  "Naira",
                  `${currency("ngn")} ${moneyFormat(stats.usersNairaBalance)}`,
                  `${currency("usd")} ${moneyFormat(
                    stats.usersNairaBalance / stats.rate.buy.usd
                  )}`,
                  () => navigation(`${adminRoutes.balances}/naira`),
                ],
                [
                  "Crypto",
                  `${currency("ngn")} ${moneyFormat(
                    stats.totalCryptoBalances
                  )}`,
                  `${currency("usd")} ${moneyFormat(
                    stats.totalCryptoBalances / stats.rate.buy.usd
                  )}`,
                  () => navigation(`${adminRoutes.balances}/crypto`),
                ],
                [
                  "Recurring",
                  `${currency("ngn")} ${moneyFormat(stats.totalRecurring)}`,
                  `${currency("usd")} ${moneyFormat(
                    stats.totalRecurring / stats.rate.buy.usd
                  )}`,
                  () => navigation(`${adminRoutes.transactions}/recurring`),
                ],
                [
                  "BTC",
                  `${currency("ngn")} ${moneyFormat(
                    stats.cryptoBalances["btc"]
                  )}`,
                  `${currency("usd")} ${moneyFormat(
                    stats.cryptoBalances["btc"] / stats.rate.buy.usd
                  )}`,
                  () => navigation(`${adminRoutes.balances}/btc`),
                ],
              ]}
            />

            <Table
              header={["#", "Naira", "Dollar"]}
              data={[
                ...cryptos
                  .filter((c) => c !== "btc")
                  .map((crypto) => [
                    crypto.toUpperCase(),
                    `${currency("ngn")} ${moneyFormat(
                      stats.cryptoBalances[crypto]
                    )}`,
                    `${currency("usd")} ${moneyFormat(
                      stats.cryptoBalances[crypto] / stats.rate.buy.usd
                    )}`,
                    () => navigation(`${adminRoutes.balances}/${crypto}`),
                  ]),
              ]}
            />
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default Dashboard;
