import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import { Input } from "components/misc";
import VButton from "components/ui/VButton";
import useGetVendor from "hooks/useGetVendor";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { showDanger } from "utilities/misc";

const EnterAmount: React.FC = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const location: any = useLocation();
  const { vendor } = useGetVendor();

  useEffect(() => {
    if (!location?.state?.bank?.accountNumber) {
      return navigate(vendorRoutes.withdraw);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (Number(amount) < 500) {
      return showDanger("You can't withdraw less than 500NGN");
    }
    navigate(`${vendorRoutes.withdraw}/confirm`, {
      state: { bank: location?.state?.bank, amount },
    });
  };

  const max = () => {
    setAmount(vendor.balance);
  };

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav title="Withdraw Naira" />

        <div className="mt-10">
          <div className="relative">
            <Input
              value={amount}
              placeholder=""
              label="Amount to withdraw"
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              className="text-right pr-24 text-lg font-medium py-3"
            />
            <span className="text-black text-opacity-50 absolute right-5 top-12">
              NGN
            </span>
            <button
              onClick={max}
              className="text-orange-400 absolute right-16 top-12"
            >
              Max
            </button>
          </div>
          <p className="text-right">
            <span className="text-gray-600">Available balance:</span>{" "}
            <span>
              {currency("naira")} {moneyFormat(vendor.balance)}
            </span>
          </p>

          <div className="mt-10">
            <p className="text-gray-700 text-left mb-2 block">I will receive</p>
            <div className="py-5 rounded-lg bg-orange-50 text-left px-5">
              <p className="text-lg font-medium">
                <span className="text-black text-opacity-50 mr-3">NGN</span>
                {moneyFormat(amount)}
              </p>
            </div>
          </div>

          <VButton
            onClick={handleSubmit}
            className="bg-orange-300 py-4 mt-10 text-white w-full"
          >
            Withdraw
          </VButton>
        </div>
      </Main>
    </Container>
  );
};

export default EnterAmount;
