import { ReactNode } from "react";

const Container: React.FC<{ children: ReactNode; isVendor?: boolean }> = ({
  children,
  isVendor,
}) => (
  <div className={`flex min-h-screen ${isVendor ? "flex-col" : ""}`}>
    {children}
  </div>
);

export default Container;
