import useGetSubdomain from "hooks/useGetSubdomain";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { publicRoutes, subdomainRoutes } from "routes/routes";
import GradientButton from "../ui/GradientButton";

const Header: React.FC<{ plain?: boolean }> = ({ plain }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const { subdomain } = useGetSubdomain();

  const renderScript = () => {
    if (subdomain === subdomainRoutes.default)
      return (
        <Helmet>
          {/* @ts-ignore */}
          <script src="//fw-cdn.com/1900682/2697037.js" chat="true"></script>
          <script>
            {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '157125310792882');
fbq('track', 'PageView');
`}
          </script>
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=157125310792882&ev=PageView&noscript=1"/>`,
            }}
          />
        </Helmet>
      );
    return <div />;
  };

  return (
    <>
      {renderScript()}
      <div className="">
        <div
          className={`${
            plain ? "bg-white" : "bg-black text-white"
          } flex z-10 justify-between fixed w-full md:flex-row pt-8 pb-7 lg:px-20`}
        >
          <h1 className="pl-5 lg:pl-0">
            <Link to={publicRoutes.home}>
              <img
                className="object-contain md:w-[110px] w-[100px]"
                alt="Bitoshi.africa logo"
                src={`/images/bitoshi-logo${plain ? "-black" : ""}.png`}
              />
            </Link>
          </h1>
          <button
            onClick={() => setShowMenu((p) => !p)}
            type="button"
            className={`pr-5 lg:hidden fa fa-${
              showMenu ? "times" : "bars"
            } lg:pr-0 absolute right-4 mt-2`}
          ></button>
          <nav
            className={`bg-black lg:bg-transparent absolute lg:relative pt-10 lg:pt-0 flex lg:block items-center flex-col lg:w-auto w-full mt-10 lg:mt-0 h-screen lg:h-auto z-10 max-h-screen ${
              showMenu ? "block text-white" : "hidden lg:block"
            }`}
          >
            <ul className="flex flex-col self-start ml-10 text-left lg:ml-0 lg:flex-row lg:gap-x-16">
              <li className="mb-14 lg:mb-0">
                <a className="font-medium" href="/">
                  Home
                </a>
              </li>
              <li className="mb-14 lg:mb-0">
                <button
                  onClick={() => setShowProducts((p) => !p)}
                  className="font-medium"
                >
                  Products{" "}
                  <i
                    className={`fa fa-chevron-${showProducts ? "up" : "down"}`}
                  ></i>
                </button>
                <ul
                  className={`lg:bg-white lg:absolute mt-3 lg:mt-0 z-10 lg:top-16 lg:left-16 shadow-xl rounded-lg py-5 lg:px-8 ${
                    showProducts ? "block" : "hidden"
                  }`}
                >
                  <li className="">
                    <Link to={publicRoutes.externalTrade}>
                      <button className="font-medium text-white lg:text-black text-opacity-70">
                        External Buy/Sell
                      </button>
                    </Link>
                  </li>
                  <li className="mt-4">
                    <Link to={publicRoutes.recurringTrade}>
                      <button className="font-medium text-white lg:text-black text-opacity-70">
                        Recurring Buy
                      </button>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="mb-14 lg:mb-0">
                <Link to={publicRoutes.about}>
                  <button className="font-medium">About Us</button>
                </Link>
              </li>
              <li className="mb-14 lg:mb-0">
                <Link to={publicRoutes.helpcenter}>
                  <button className="font-medium">Help center</button>
                </Link>
              </li>
              <li className="mb-14 lg:mb-0">
                <a target="_blank" className="font-medium" href="/blog">
                  Blog
                </a>
              </li>
            </ul>

            <div className="flex mt-10 gap-x-2 md:gap-x-10 lg:hidden">
              <GradientButton
                hasBorder
                image="/images/playstore.svg"
                smallText="Get it on"
                bigText="Play Store"
                link="https://play.google.com/store/apps/details?id=com.bitoshi"
              />
              <GradientButton
                hasBorder
                image="/images/appstore.png"
                smallText="Get it on"
                bigText="App Store"
                link="https://apps.apple.com/us/app/bitoshi-africa/id1627285591"
              />
            </div>
          </nav>
          <li className="absolute self-center block mb-8 md:mb-9 lg:mb-0 lg:relative bottom-1 md:right-24 lg:right-2 right-20 lg:bottom-1.5">
            <a
              className={`font-medium py-2.5 lg:py-3 px-7 md:px-10 lg:px-8 rounded-lg ${
                plain ? "bg-black text-white" : "bg-white text-black"
              }`}
              href={process.env.REACT_APP_ONELINK}
            >
              Get started
            </a>
          </li>
        </div>
      </div>
    </>
  );
};

export default Header;
