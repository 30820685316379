import { httpBalances } from "api/admin/stats";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { capitalize } from "utilities/misc";
import { cryptos } from "utilities/wallets";

const types = ["merchant", "user", "naira", "crypto", ...cryptos];
const noEs = ["naira", ...cryptos];

const Balances: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const type = params.type as string;

  useEffect(() => {
    if (!types.includes(type)) {
      navigate(adminRoutes.dashboard);
    }
  }, [type, navigate]);

  const {
    data: { users, rate },
  } = useQuery(["balances", type], () => httpBalances(type), {
    initialData: {
      users: [],
      rate: {
        buy: {
          usd: 0,
        },
        sell: {
          usd: 0,
        },
      },
    },
  });

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-8 mx-10 text-left">
        <h3 className="text-2xl">
          {`${capitalize(type)}${noEs.includes(type) ? "" : "s"}`} Balances
        </h3>
        <div className="my-10">
          <Search type="user" />
        </div>
        <div>
          <Table
            header={["Username", "e-mail", "Balance", ""]}
            data={[
              ...users?.map((user: any) => [
                user.userName,
                user.email,
                `${currency("ngn")} ${moneyFormat(user.balance)}`,
                `${currency("usd")} ${moneyFormat(
                  user.balance / rate.buy.usd
                )}`,
                type === "user"
                  ? () => navigate(`${adminRoutes.balances}/user/${user?.id}`)
                  : null,
              ]),
            ]}
          />
        </div>
      </Main>
    </Container>
  );
};
export default Balances;
