import { DateTime } from "luxon";
import { useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";

export interface Itransaction {
  amount: string;
  createdAt: string;
  crypto: string;
  id: number;
  status: "pending" | "successful" | "cancelled";
  currencyType: "naira" | "crypto";
  transactionType:
    | "deposit"
    | "withdrawal"
    | "earning"
    | "buy"
    | "sell"
    | "send"
    | "receive"
    | "swap"
    | "recurring"
    | "bill"
    | "refund";
  vendorId: number;
  userID: number;
  updatedAt: string;
  transactionID: string;
  transactionHash: string | null;
  timestamp: string;
  rate: string | null;
  place: string;
  fee: string;
  receiverID: number;
  paid: boolean;
  address: string;
  user?: { id: string; userName: string; firstName: string; lastName: string };
  merchant?: string;
  bank?: string;
  recurring?: { name: string; frequency: string };
  frequency: string;
  targetAmount: number;
  amountPurchased: number;
  nextSchedule: string;
  name: string;
  fromCrypto: string;
  fromAmount: string;
  receiver?: {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
  };
  vendor?: {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
  };
  network?: string;
}

const transactionTitle = (transactionType: string) => (
  <span
    className={`${
      transactionType === "deposit" ? "text-red-500" : "text-green-500"
    }`}
  >
    {transactionType === "deposit"
      ? "Withdrew"
      : transactionType === "earning"
      ? "Earned"
      : "Deposited"}
  </span>
);

export const transactionTime = (timestamp: string) =>
  `${DateTime.fromISO(timestamp).toFormat("DD")} at ${DateTime.fromISO(
    timestamp
  ).toFormat("t")}`;

const transactionAmount = (amount: string | number) =>
  `${currency("ngn")}${moneyFormat(amount)}`;

const transactionPaidStatus = (paid: boolean, status: string) => (
  <span
    className={`${
      paid && status !== "cancelled"
        ? "text-green-500 bg-green-100"
        : "text-red-500 bg-red-100"
    } p-0.5 rounded text-xs`}
  >
    {status === "cancelled" ? "Failed" : paid ? "Paid" : "Unpaid"}
  </span>
);

export const transactionStatus = (status: string) => (
  <p
    className={`text-white ${
      status === "pending" ? "bg-orange-500" : "bg-green-500"
    } text-sm px-3 rounded`}
  >
    {status === "pending" ? "Pending" : "Confirmed"}
  </p>
);

const Transaction: React.FC<{ transaction: Itransaction }> = ({
  transaction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const canOpen =
    transaction.transactionType !== "deposit" ||
    (transaction.transactionType === "deposit" && transaction.paid);
  const navigate = useNavigate();

  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(false);
  };

  const open = () => {
    if (transaction.transactionType === "deposit") {
      if (transaction.status === "cancelled") return;
      if (transaction.status === "pending") {
        if (!canOpen) return;
        return navigate(
          `${vendorRoutes.withdrawals}/transaction/${transaction.id}`
        );
      }
      return setIsOpen((p) => !p);
    } else if (transaction.transactionType === "withdrawal") {
      if (transaction.status === "pending") {
        return navigate(
          `${vendorRoutes.deposits}/transaction/${transaction.id}`
        );
      }
      return setIsOpen((p) => !p);
    }
  };
  const bank =
    transaction?.address && transaction?.address !== "sell.bitoshi.africa"
      ? JSON.parse(transaction.address)
      : {};
  return (
    <>
      <div
        onClick={open}
        className="relative flex justify-between px-5 py-8 my-3 bg-white rounded-lg shadow-lg cursor-pointer"
      >
        <div>
          <h4>{transactionTitle(transaction.transactionType)} Naira</h4>
          <p className="mt-1 text-xs font-light text-black text-opacity-50">
            {transactionTime(transaction.timestamp)}
          </p>
        </div>
        <div className="text-right">
          {transaction.transactionType === "withdrawal" &&
          transaction.place === "external" ? (
            <span className="absolute px-1 text-xs bg-orange-300 rounded right-2 top-2">
              sell.bitoshi.africa
            </span>
          ) : (
            ""
          )}
          <p>
            {transactionAmount(
              transaction.transactionType === "withdrawal" &&
                transaction?.place !== "external"
                ? Number(transaction.amount) - Number(transaction.fee)
                : transaction.amount
            )}
          </p>
          {transaction.transactionType === "deposit" &&
            transactionPaidStatus(transaction.paid, transaction.status)}
        </div>
      </div>

      <ReactModal
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setIsOpen(false)}
        isOpen={isOpen}
        className="w-screen h-screen"
      >
        <div
          onClick={closeModal}
          className="relative w-full h-full bg-gray-500 bg-opacity-50"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute bottom-0 flex justify-center w-full bg-white rounded-t-2xl"
          >
            <div className="absolute w-20 h-1 bg-white rounded-lg -top-5"></div>

            <div className="w-full px-3 pt-5 pb-14">
              {transaction.transactionType === "withdrawal" &&
              transaction.place === "external" ? (
                <p className="text-center">
                  <span className="px-1 text-xs bg-orange-300 rounded">
                    sell.bitoshi.africa
                  </span>
                </p>
              ) : (
                ""
              )}
              <h4 className="text-center">
                {transaction.transactionType === "deposit"
                  ? "Withdrew"
                  : "Deposited"}{" "}
                Naira
              </h4>
              <div className="px-5 py-5 mt-5 border border-orange-300 rounded-lg">
                <div className="flex justify-between mb-4 font-light">
                  <h5 className="text-sm">Amount</h5>
                  <p className="text-sm text-black text-opacity-60">
                    {transactionAmount(
                      transaction.transactionType === "deposit"
                        ? Number(transaction.amount) - Number(transaction.fee)
                        : transaction.amount
                    )}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-light">
                  <h5 className="text-sm">Transaction ID</h5>
                  <p className="text-sm text-black text-opacity-60">
                    {transaction.transactionID}
                  </p>
                </div>

                {transaction.transactionType === "deposit" && (
                  <div className="flex justify-between mb-4 font-light">
                    <h5 className="text-sm">Registered Full Name</h5>
                    <p className="text-sm text-black text-opacity-60">
                      {transaction.user?.firstName} {transaction.user?.lastName}
                    </p>
                  </div>
                )}
                {transaction.transactionType === "deposit" && (
                  <div className="flex justify-between mb-4 font-light">
                    <h5 className="text-sm">Username</h5>
                    <p className="text-sm text-black text-opacity-60">
                      @{transaction.user?.userName}
                    </p>
                  </div>
                )}

                <div className="flex justify-between mb-4 font-light">
                  <h5 className="text-sm">
                    {transaction.transactionType === "deposit"
                      ? "Vendor"
                      : "User "}{" "}
                    Bank Name
                  </h5>
                  <p className="text-sm text-black text-opacity-60">
                    {bank?.bankName}
                  </p>
                </div>
                <div className="flex justify-between mb-4 font-light">
                  <h5 className="text-sm">
                    {transaction.transactionType === "deposit"
                      ? "Vendor"
                      : "User "}{" "}
                    Account Name
                  </h5>
                  <p className="text-sm text-black text-opacity-60">
                    {bank?.accountName}
                  </p>
                </div>
                <div className="flex justify-between mb-4 font-light">
                  <h5 className="text-sm">
                    {transaction.transactionType === "deposit"
                      ? "Vendor"
                      : "User "}{" "}
                    Account Number
                  </h5>
                  <p className="text-sm text-black text-opacity-60">
                    {bank?.accountNumber}
                  </p>
                </div>

                <div className="flex justify-between mb-5 font-light">
                  <h5 className="text-sm">Date & Time</h5>
                  <p className="text-sm text-black text-opacity-60">
                    {transactionTime(transaction.timestamp)}
                  </p>
                </div>
                {/* <div className="flex justify-between mb-5 font-light">
                  <h5 className="text-sm">id</h5>
                  <p className="text-sm text-black text-opacity-60">
                    {currency("ngn")} {moneyFormat(5000)}
                  </p>
                </div> */}
                <div className="flex justify-between mb-5 font-light">
                  <p></p>
                  {transactionStatus(transaction.status)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default Transaction;
