import { PINInput } from "components/misc";
import ReactModal from "react-modal";
import VButton from "./VButton";

const PINModal: React.FC<{
  isOpen: boolean;
  close: () => void;
  pin: string;
  setPin: (value: string) => void;
  proceed: () => void;
  status?: string;
}> = ({ isOpen, close, pin, setPin, proceed, status }) => {
  return (
    <ReactModal
      shouldCloseOnOverlayClick={true}
      onRequestClose={close}
      isOpen={isOpen}
      className="h-screen w-screen"
    >
      <div
        onClick={close}
        className="h-full w-full bg-gray-500 bg-opacity-50 relative"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="absolute bottom-0 py-28 w-full bg-white px-5 flex flex-col items-center justify-center rounded-t-lg "
        >
          <div className="rounded-lg w-20 h-1 bg-white absolute -top-5"></div>
          <h4 className="text-xl mb-5">Enter your PIN to Continue</h4>
          <PINInput
            pin={pin}
            placeholder=""
            label=""
            setPin={setPin}
            type="password"
            boxLength={4}
          />
          <VButton
            onClick={proceed}
            status={status}
            className="bg-orange-300 py-4 mt-10 text-white w-full"
          >
            Continue
          </VButton>
        </div>
      </div>
    </ReactModal>
  );
};

export default PINModal;
