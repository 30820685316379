import Footer, { FooterCTA } from "components/layout/Footer";
import Header from "components/layout/Header";
import { Helmet } from "react-helmet-async";

const About: React.FC = () => {
  return (
    <>
      <Header />
      <Helmet>
        <title>Bitoshi Africa | Learn about us</title>
        <meta
          name="description"
          content="Bitoshi.africa is here to simplify access to cryptocurrencies; learn more about us."
        />
        <meta
          name="keywords"
          content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto | About Bitoshi"
        />
      </Helmet>

      <div className="flex flex-col items-center bg-no-repeat bg-cover justify-center w-full pt-40 bg-black bg-center py-14 bg-pattern md:py-44">
        <h2 className="mb-4 text-4xl font-bold text-white">Mission</h2>
        <div className="font-thin text-center text-white text-opacity-80 mx-8 max-w-[450px]">
          Our mission at Bitoshi is to become the top-performing and most
          reliable crypto exchange in Africa. To achieve this, we are focusing
          on developing cutting-edge products and responding to our customers'
          needs with exceptional speed.
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-10 mt-20">
        <div className="text-center">
          <h2 className="mb-4 text-4xl font-bold md:text-3xl">Vision</h2>
          <p className="leading-6 text-black text-opacity-70 md:max-w-[550px]">
            Our vision at Bitoshi is to enable Nigerians and other Africans the
            opportunity to trade cryptocurrencies with ease and security. Our
            vision is centered on accessibility, security, innovation, and
            community-building, as we aim to establish ourselves as a
            trustworthy platform for cryptocurrency trading in the region.
          </p>
        </div>
      </div>

      <div className="px-5 md:px-10 lg:px-40">
        <div className="flex flex-col items-center justify-center mt-32 lg:flex-row lg:gap-x-20 gap-y-8">
          <div className="flex justify-center lg:w-1/2">
            <img
              className="lg:max-w-[500px]"
              src="/images/about-the-brand.png"
              alt="About the brand"
            />
          </div>
          <div className="text-left lg:w-1/2">
            <h3 className="mb-4 text-2xl font-bold md:text-3xl">
              About Us <br className="hidden lg:block" /> (The brand)
            </h3>
            <p className="font-light text-black text-opacity-60">
              Bitoshi is a cryptocurrency fintech company based in Nigeria. We
              are passionate about building products that are in line with the
              needs of users interested in cryptocurrency in Africa and beyond.{" "}
              <br />
              Founded in 2020, Bitoshi has developed from a web app to a mobile
              app with the aim of simplifying access to cryptocurrencies. 
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center py-20 mt-20 text-white bg-black lg:px-40">
        <h3 className="mb-10 text-3xl font-bold">Values</h3>

        <div className="flex flex-col text-left md:flex-row md:gap-x-10 gap-y-10">
          <div className="max-w-[350px] rounded-lg border border-white border-opacity-30 py-14 px-8 items-center">
            <span className="bg-[#FF933A] rounded-full py-2 px-2">
              <i className="fa fa-users"></i>
            </span>
            <h3 className="mt-8 mb-2"> Customers:</h3>
            <p className="font-thin text-white text-opacity-90">
              At our company, customers are our top priority. We are dedicated
              to providing the best possible experience for our clients,
              ensuring their comfort and satisfaction with our services. Our
              goal is to create long-lasting relationships with our customers,
              built on trust and excellent service.
            </p>
          </div>
          <div className="max-w-[350px] rounded-lg border border-white border-opacity-30 py-14 px-8 items-center">
            <span className="bg-[#1557FF] rounded-full py-2 px-2">
              <i className="fa fa-layer-group"></i>
            </span>
            <h3 className="mt-8 mb-2"> Transparency:</h3>
            <p className="font-thin text-white text-opacity-90">
              We believe in operating with integrity and honesty and providing
              clear communication with our customers. This means being open
              about our processes, fees, and any potential risks involved with
              our products. 
            </p>
          </div>
        </div>

        <div className="max-w-[740px] mx-7 flex flex-col gap-y-10 mt-10 text-left">
          <div className="items-center px-8 border border-white rounded-lg border-opacity-30 py-14">
            <span className="bg-[#009B65] rounded-full py-2 px-2">
              <i className="fa fa-cog"></i>
            </span>
            <h3 className="mt-8 mb-2"> Creativity:</h3>
            <p className="font-thin text-white text-opacity-90">
              We pride ourselves on being innovative and creative in developing
              products that meet the needs of our customers. We constantly seek
              to identify and respond to emerging trends, and to connect the
              dots between our customers' needs and our product offerings. We
              strive to create products that are user-friendly, and intuitive,
              and solve real-world problems.
            </p>
          </div>

          <div className="items-center px-8 border border-white rounded-lg border-opacity-30 py-14">
            <span className="bg-[#9667ED] rounded-full py-2 px-2">
              <i className="fa fa-heart"></i>
            </span>
            <h3 className="mt-8 mb-2"> Communication:</h3>
            <p className="font-thin text-white text-opacity-90">
              We believe in staying in touch with our customers, listening to
              their feedback, and being responsive to their needs. We strive to
              provide clear and timely communication, ensuring that our
              customers are always informed about our products and services.
              Regardless of the means of communication, we are always available
              to answer questions and provide support.
            </p>
          </div>
        </div>
      </div>

      <h3 className="mt-32 mb-10 text-3xl font-bold">
        <span>The Team</span>
      </h3>
      <div className="grid items-center justify-center grid-cols-2 lg:grid-cols-3 mx-5 lg:px-40 gap-x-5 gap-y-14">
        <div className="flex flex-col items-center justify-center">
          <img
            className="rounded-xl xl:max-w-[300px]"
            src="/images/timilehin-zubair.png"
            alt="Timilehin Zubair"
          />
          <h4 className="mt-4 mb-2 font-bold md:text-xl">Timilehin Zubair</h4>
          <p className="font-thin">CEO</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            className="bg-[#E0DCFF] rounded-xl xl:max-w-[300px]"
            src="/images/lekan-lawal.png"
            alt="Lekan Lawal"
          />
          <h4 className="mt-4 mb-2 font-bold md:text-xl">Lekan Lawal</h4>
          <p className="font-thin">Engineering</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            className="rounded-xl xl:max-w-[300px]"
            src="/images/fiyin.png"
            alt="Fiyinfoluwa Akin-John"
          />
          <h4 className="mt-4 mb-2 font-bold md:text-xl">
            Fiyinfoluwa Akin-John
          </h4>
          <p className="font-thin">Growth Lead</p>
        </div>

        <div className="flex flex-col items-center justify-center">
          <img
            className="bg-[#BFD8FF] rounded-xl xl:max-w-[300px]"
            src="/images/amen.png"
            alt="Amen Ipadeola"
          />
          <h4 className="mt-4 mb-2 font-bold md:text-xl">Amen Ipadeola</h4>
          <p className="font-thin">Customer Success</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            className="rounded-xl xl:max-w-[300px]"
            src="/images/diekolola.png"
            alt="Diekolola Animashaun"
          />
          <h4 className="mt-4 mb-2 font-bold md:text-xl">
            Diekolola Animashaun
          </h4>
          <p className="font-thin">Design</p>
        </div>
      </div>

      <div className="px-5 mt-28 md:px-10 lg:px-40">
        <div className="flex flex-col-reverse items-center justify-center mt-16 md:flex-row lg:gap-x-20 gap-y-10">
          <div className="text-left md:w-1/2">
            <h3 className="mb-4 text-2xl font-bold md:text-3xl">
              About Us (The founder)
            </h3>
            <p className="font-light text-black text-opacity-60">
              Zubair Timilehin is the founder and CEO of Bitoshi. africa. With a
              degree in computer science focusing on product development,  he
              founded Bitoshi in 2020. A cryptocurrency startup that aims to
              simplify access to cryptocurrencies. Under his leadership, Bitoshi
              has grown to a user base of customers and has processed over $20
              million in transactions.
            </p>
            <p className="mt-2 font-light text-black text-opacity-60">
              Zubair's philosophy as a product owner is to prioritize customer
              needs, use data to inform decisions and adopt an agile approach.
              He believes that the best products are those that address
              real-life problems. In his free time, Zubair enjoys researching
              startups, playing video games, and traveling the world.
            </p>
          </div>
          <div className="flex justify-center md:w-1/2">
            <img
              className="w-[400px] rounded-lg"
              src="/images/timi.png"
              alt="About the founder"
            />
          </div>
        </div>
      </div>
      <FooterCTA />
      <Footer />
    </>
  );
};

export default About;
