import { get } from "utilities/network";

export const httpFetchMerchants = () => {
  return get("admin/merchants");
};

export const httpMerchantLimit = (userID: string) => {
  return get(`admin/merchants/limit/${userID}`);
};

export const httpMerchantTransactionAnalytics = (
  id: string,
  date: Date | string
) => {
  return get(`admin/merchants/transactionAnalytics/${id}?date=${date}`);
};

export const httpMerchantTransactions = (
  id: string,
  type: string,
  limit: number,
  page: number
) => {
  return get(
    `admin/merchants/transactions/${id}?type=${type}&limit=${limit}&page=${page}`
  );
};

export const httpUSearchMerchant = (userName: string) => {
  return get(`admin/merchants/search?search=${userName}`);
};
