import { httpGetVendor } from "api/vendor/auth";
import { currentVendor } from "helpers/storage";
import { useState } from "react";
import { useQuery } from "react-query";

const useGetVendor = () => {
  const [vendor, setVendor] = useState<any>(currentVendor());

  const { refetch: refetchVendor } = useQuery("vendor", httpGetVendor, {
    initialData: { user: vendor },
    onSuccess: (data) => {
      setVendor(data?.user);
    },
  });
  return { vendor, refetchVendor };
};

export default useGetVendor;
