import { httpVendorDeposit } from "api/admin/transaction";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import TransactionItem from "components/ui/TransactionItem";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { capitalize, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const DepositInfo: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params.id);

  useEffect(() => {
    if (!id || isNaN(id)) {
      navigate(adminRoutes.dashboard);
    }
  }, [id, navigate]);

  const {
    data: { transaction },
    refetch,
  } = useQuery(["depositsInfo", id], () => httpVendorDeposit(id), {
    initialData: {
      transactions: {},
    },
    onSuccess: (data) => {
      if (!data.transaction) {
        return navigate(adminRoutes.deposits);
      }
    },
  });

  const bank =
    transaction?.address && transaction?.address !== "sell.bitoshi.africa"
      ? JSON.parse(transaction.address)
      : {};

  const { execute, status, value } = useAsync(
    () => post(`admin/merchants/acceptDeposit/${id}`, {}),
    false
  );

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message);
      refetch();
    }
  }, [value, refetch]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-8 mx-10 text-left">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">
            {capitalize(transaction?.status)} deposit
          </h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="mt-14 bg-orange-50 rounded-lg px-5 py-10">
          <h3 className="text-2xl font-medium">
            {currency("ngn")} {moneyFormat(transaction?.amount || 0)}
          </h3>

          <div className="mt-5 flex justify-between">
            <TransactionItem
              title="Merchant:"
              value={`@${transaction?.merchant}`}
            />
            <TransactionItem title="Bank name:" value={bank?.bankName} />
            <TransactionItem
              title="Account number:"
              value={bank?.accountNumber}
            />
            <TransactionItem title="Account name:" value={bank?.accountName} />
          </div>

          {transaction?.paid ? null : (
            <div className="text-center mt-8">
              <VButton
                status={status}
                onClick={execute}
                className="bg-black text-white rounded-lg px-20 py-3 font-light"
              >
                Payment Completed
              </VButton>
            </div>
          )}
        </div>
      </Main>
    </Container>
  );
};
export default DepositInfo;
