import Spinner from "components/ui/Spinner";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import { showDanger, showSuccess, validatePassword } from "utilities/misc";
import { post } from "utilities/network";

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const {
    execute: register,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/auth/register`, {
        email,
        password,
        userName,
      }),
    false
  );

  const handleSubmit = () => {
    if (!validatePassword(password)) {
      return showDanger("Password should be at least 6 characters long");
    }
    register();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Registration Successful");
      navigate(adminRoutes.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="px-5 flex items-center w-full justify-center h-screen">
      <div className="h-96 w-full md:w-3/4">
        <h2 className="text-2xl">Admin Register</h2>
        <div className="shadow-xl bg-white py-8 rounded px-3">
          <div className="mb-5">
            <label className="text-black block text-sm text-left mb-2">
              Name
            </label>
            <input
              type={"text"}
              className="border-0 rounded bg-gray-200 w-full py-3"
              placeholder="Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <label className="text-black block text-sm text-left mb-2">
              Email
            </label>
            <input
              type={"email"}
              className="border-0 rounded bg-gray-200 w-full py-3"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <label className="text-black block text-sm text-left mb-2">
              Password
            </label>
            <input
              type={"password"}
              className="border-0 rounded bg-gray-200 w-full py-3"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button
            onClick={handleSubmit}
            className="rounded-2xl px-10 py-3 bg-black text-white mt-5"
          >
            {status === "pending" ? <Spinner /> : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
