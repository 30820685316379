import { ReactNode } from "react";

const ShadowContainer: React.FC<{
  children: ReactNode;
  noShadowBg?: boolean;
}> = ({ children, noShadowBg = false }) => (
  <div
    className={`bg-white  ${
      noShadowBg ? "shadow-lg md:shadow-none md:bg-transparent" : "shadow-lg"
    } shadow-gray-300 rounded-lg py-10 px-5 md:px-10 lg:px-40 flex flex-col items-center w-full`}
  >
    {children}
  </div>
);

export default ShadowContainer;
