import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "routes";
import { socket, SocketContext } from "socket";
import ScrollToTop from "utilities/scrollToTop";
import "./App.css";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  },
});

function App() {
  return (
    <React.StrictMode>
      <div id="App" className="App">
        <QueryClientProvider client={queryClient}>
          <SocketContext.Provider value={socket}>
            <HelmetProvider>
              <Router>
                <ScrollToTop />
                <Routes />
              </Router>
            </HelmetProvider>
          </SocketContext.Provider>
        </QueryClientProvider>
        <ToastContainer />
      </div>
    </React.StrictMode>
  );
}

export default App;
