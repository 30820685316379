import { httpFetchTransactions } from "api/vendor/transactions";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import Transaction, { Itransaction } from "components/ui/Transaction";
import VendorToggle from "components/ui/VendorToggle";
import { useState } from "react";
import { useQuery } from "react-query";

const Deposit: React.FC = () => {
  const [status, setStatus] = useState("Pending");

  const TRANSACTION_TYPE = "withdrawal";
  const STATUS = status === "Pending" ? "pending" : "successful";
  const CHANNEL = "user";

  const {
    data: { transactions },
  } = useQuery(
    ["depositsTransactions", CHANNEL, TRANSACTION_TYPE, STATUS],
    () => httpFetchTransactions(CHANNEL, TRANSACTION_TYPE, STATUS),
    {
      initialData: { transactions: [] },
    }
  );

  return (
    <Container isVendor>
      <VendorSidebar />
      <Main isVendor>
        <VendorNav title="Deposits" />

        <div className="mt-10">
          <VendorToggle
            value={status}
            setValue={setStatus}
            data={["Pending", "Completed"]}
          />
        </div>
        <div className="text-left mt-8">
          {transactions.map((trn: Itransaction) => (
            <Transaction transaction={trn} key={trn.id} />
          ))}
        </div>
      </Main>
    </Container>
  );
};

export default Deposit;
