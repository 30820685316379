interface FilterProps {
  data: { label: string; value: string }[];
  value: string;
  setValue: (value: string) => void;
}
const Filter: React.FC<FilterProps> = ({ data, value, setValue }) => (
  <select
    className="bg-gray-200 mx-3 text-center py-1 px-10 rounded-lg border-none"
    onChange={(e) => setValue(e.target.value)}
    value={value}
    name="filter"
    id="filter"
  >
    {data.map((item, index) => (
      <option key={item.value} value={item.value}>
        {item.label}
      </option>
    ))}
  </select>
);
export default Filter;
