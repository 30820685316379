import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import { PINInput } from "components/misc";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import { showDanger, showSuccess } from "utilities/misc";
import { patch } from "utilities/network";

const CreatePIN: React.FC = () => {
  const navigate = useNavigate();
  const [pin, setPin] = useState("");

  const {
    execute: createPIN,
    status,
    value,
  } = useAsync(
    () =>
      patch(`vendor/profile/addPIN`, {
        pin,
      }),
    false
  );

  const handleSubmit = () => {
    if (pin.length !== 4) {
      return showDanger("PIN should be 4 digits long");
    }
    createPIN();
  };

  useEffect(() => {
    if (value?.data?.data?.success) {
      showSuccess(value?.data?.message || "PIN created successfully");
      navigate(vendorRoutes.profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav
          title="Create PIN"
          subtitle="Add a 4-digit PIN to secure your account and authorize transactions"
        />

        <div className="mt-20">
          <PINInput
            pin={pin}
            placeholder=""
            label=""
            setPin={setPin}
            type="password"
            boxLength={4}
          />

          <VButton
            onClick={handleSubmit}
            status={status}
            className="w-full py-4 mt-10 text-white bg-orange-300"
          >
            Proceed
          </VButton>
        </div>
      </Main>
    </Container>
  );
};

export default CreatePIN;
