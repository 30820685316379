import { httpFetchMerchants } from "api/admin/merchant";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import TransactionItem from "components/ui/TransactionItem";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const Merchants: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [user, setUser] = useState<any>(null);

  const {
    data: { merchants },
  } = useQuery("merchants", httpFetchMerchants, {
    initialData: {
      merchants: [],
    },
  });

  const toggleOpen = (u: any) => {
    if (isOpen && u.email === user?.email) return setIsOpen(false);

    setUser(u);
    return setIsOpen(true);
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left">
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center gap-x-4">
            <h3 className="text-2xl">Merchants</h3>
            <Link to={`${adminRoutes.merchants}/assign`}>
              <span className="bg-black text-white text-sm rounded-lg py-1 px-5 font-light">
                Assign Vendors
              </span>
            </Link>
          </div>
          <div className="w-3/6">
            <Search type="merchant" />
          </div>
        </div>

        <div className="mt-14 flex gap-8">
          <div className="w-7/12">
            <Table
              header={["Username", "e-mail", "Phone number"]}
              data={[
                ...merchants?.map((user: any) => [
                  user.userName,
                  user.email,
                  user.phoneNumber,
                  () => toggleOpen(user),
                ]),
              ]}
            />
          </div>
          {isOpen && (
            <div className="w-5/12 bg-orange-50 rounded-lg py-10 px-8">
              <h4 className="mb-5">@{user?.userName}</h4>

              <TransactionItem title="Username" value={`@${user?.userName}`} />
              <TransactionItem
                title="Registered Full Name"
                value={`${user?.firstName || ""} ${user?.lastName || ""}`}
              />
              <TransactionItem title="e-mail" value={user?.email} />
              <TransactionItem title="Phone Number" value="" />
              <TransactionItem
                title="Date registered"
                value={DateTime.fromISO(user?.regDate).toFormat("D")}
              />

              <div className="mt-10 flex gap-x-20 gap-y-10 flex-wrap">
                <Link to={`${adminRoutes.merchants}/${user.id}/limit`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Limit & Balances
                  </span>
                </Link>
                <Link to={`${adminRoutes.merchants}/${user.id}/transactions`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Transactions
                  </span>
                </Link>
                <Link to={`${adminRoutes.merchants}/${user.id}/analytics`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Analytics
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </Main>
    </Container>
  );
};
export default Merchants;
