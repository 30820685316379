const VENDOR_STORAGE_KEY = "vendor";
const VENDOR_TOKEN_STORAGE_KEY = "vendorToken";
const ADMIN_TOKEN_STORAGE_KEY = "AdminToken";
const ADMIN_STORAGE_KEY = "Admin";
const SUPPORT_TOKEN_STORAGE_KEY = "SupportToken";
const SUPPORT_STORAGE_KEY = "Support";

const saveData = (key: string, data: any) =>
  localStorage.setItem(key, JSON.stringify(data));

const getData = (key: string) => JSON.parse(localStorage.getItem(key)!);

export const currentVendor = () => getData(VENDOR_STORAGE_KEY);
export const currentAdmin = () => getData(ADMIN_STORAGE_KEY);
export const currentSupport = () => getData(SUPPORT_STORAGE_KEY);

export const setCurrentVendor = (vendor: any) =>
  saveData(VENDOR_STORAGE_KEY, vendor);
export const setCurrentAdmin = (admin: any) =>
  saveData(ADMIN_STORAGE_KEY, admin);
export const setCurrentSupport = (support: any) =>
  saveData(SUPPORT_STORAGE_KEY, support);

export const removeCurrentVendor = () =>
  localStorage.removeItem(VENDOR_STORAGE_KEY);
export const removeCurrentAdmin = () =>
  localStorage.removeItem(ADMIN_STORAGE_KEY);
export const removeCurrentSupport = () =>
  localStorage.removeItem(SUPPORT_STORAGE_KEY);

export const currentVendorAuthToken = () => getData(VENDOR_TOKEN_STORAGE_KEY);
export const currentAdminAuthToken = () => getData(ADMIN_TOKEN_STORAGE_KEY);
export const currentSupportAuthToken = () => getData(SUPPORT_TOKEN_STORAGE_KEY);

export const setCurrentVendorAuthToken = (token: string) =>
  saveData(VENDOR_TOKEN_STORAGE_KEY, token);
export const setCurrentAdminAuthToken = (token: string) =>
  saveData(ADMIN_TOKEN_STORAGE_KEY, token);
export const setCurrentSupportAuthToken = (token: string) =>
  saveData(SUPPORT_TOKEN_STORAGE_KEY, token);

export const removeCurrentVendorAuthToken = () =>
  localStorage.removeItem(VENDOR_TOKEN_STORAGE_KEY);
export const removeCurrentAdminAuthToken = () =>
  localStorage.removeItem(ADMIN_TOKEN_STORAGE_KEY);
export const removeCurrentSupportAuthToken = () =>
  localStorage.removeItem(SUPPORT_TOKEN_STORAGE_KEY);

export {
  VENDOR_STORAGE_KEY,
  VENDOR_TOKEN_STORAGE_KEY,
  ADMIN_STORAGE_KEY,
  ADMIN_TOKEN_STORAGE_KEY,
  SUPPORT_STORAGE_KEY,
  SUPPORT_TOKEN_STORAGE_KEY,
};
