import { httpGetSupport } from "api/support/auth";
import { currentSupport } from "helpers/storage";
import { useState } from "react";
import { useQuery } from "react-query";

const useGetSupport = () => {
  const [support, setSupport] = useState<any>(currentSupport());

  const { refetch: refetchSupport } = useQuery("support", httpGetSupport, {
    initialData: { user: support },
    onSuccess: (data) => {
      setSupport(data?.user);
    },
  });
  return { support, refetchSupport };
};

export default useGetSupport;
