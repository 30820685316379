import { httpFetchTransactions } from "api/vendor/transactions";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorSidebar from "components/layout/VendorSidebar";
import Transaction, { Itransaction } from "components/ui/Transaction";
import VButton from "components/ui/VButton";
import useGetVendor from "hooks/useGetVendor";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { vendor } = useGetVendor();

  const CHANNEL = "user";
  const TRANSACTION_TYPE = "all";
  const STATUS = "all";

  const {
    data: { transactions },
  } = useQuery(
    ["pendingTransactions", CHANNEL, TRANSACTION_TYPE, STATUS],
    () => httpFetchTransactions(CHANNEL, TRANSACTION_TYPE, STATUS),
    {
      initialData: { transactions: [] },
    }
  );

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <div className="py-8 pt-12 text-white bg-black rounded-lg">
          <h4 className="mb-2 text-sm font-normal">Your Total Balance</h4>
          <p className="text-3xl font-medium">
            <span className="text-2xl">{currency("ngn")}</span>{" "}
            {`${moneyFormat(0)}`}
            {/* {`${moneyFormat(vendor.balance)}`} */}
          </p>
          <VButton
            onClick={() =>
              navigate(
                vendor?.pin
                  ? vendorRoutes.withdraw
                  : `${vendorRoutes.profile}/create-pin`
              )
            }
            className="mt-8"
          >
            <i className="mr-2 fa fa-arrow-up"></i>
            Withdraw
          </VButton>
        </div>

        <div className="mt-8 text-left">
          <h4 className="text-lg">Transactions</h4>

          {transactions.map((trn: Itransaction) => (
            <Transaction transaction={trn} key={trn.id} />
          ))}
        </div>
      </Main>
    </Container>
  );
};

export default Dashboard;
