import GradientButton from "components/ui/GradientButton";
import { Link } from "react-router-dom";
import { publicRoutes } from "routes/routes";

const Footer: React.FC = () => (
  <div className="px-6 text-left text-white bg-scroll bg-black pt-14 md:pt-20 md:px-20">
    <div className="flex flex-col pr-12 md:flex-row md:justify-between">
      <div className="md:w-1/6">
        <h1>
          <Link to={publicRoutes.home}>
            <img
              className="object-contain w-[130px]"
              alt="Bitoshi.africa logo"
              src="/images/bitoshi-logo.png"
            />
          </Link>
        </h1>
        <p className="mt-3 ml-1 text-white text-opacity-70">
          All you need to buy, sell, send and receive cryptocurrencies
        </p>
      </div>

      <div className="flex justify-between my-10 md:justify-evenly md:my-0 md:w-2/6">
        <div className="w-1/2 md:w-auto">
          <h4 className="text-lg font-medium">Company</h4>
          <ul>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href={publicRoutes.about}
              >
                About Us
              </a>
            </li>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href="#newsletter"
              >
                Newsletter
              </a>
            </li>
          </ul>
        </div>

        <div className="w-1/2 md:w-auto ml-[68px]">
          <h4 className="text-lg font-medium text-left">Community</h4>
          <ul>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href="https://twitter.com/Bitoshiafrica?t=Undi-jWe4vADuDHGAYVoSQ&s=09"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href="https://www.linkedin.com/company/bitoshiafrica/"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
            </li>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href="https://instagram.com/bitoshi.africa?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>

            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href="https://t.me/+c4ek89XILkc2OTk8"
                target="_blank"
                rel="noreferrer"
              >
                Telegram
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex justify-between my-10 md:justify-evenly md:my-0 md:w-2/6">
        <div className="w-1/2 md:w-auto">
          <h4 className="text-lg font-medium">Products</h4>
          <ul>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href={publicRoutes.externalTrade}
              >
                External Buy/Sell
              </a>
            </li>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href={publicRoutes.recurringTrade}
              >
                Recurring Buy
              </a>
            </li>
          </ul>
        </div>
        <div className="w-1/2 ml-20 md:w-auto">
          <h4 className="text-lg font-medium text-left">Help</h4>
          <ul>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href={publicRoutes.helpcenter}
              >
                FAQs
              </a>
            </li>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href={publicRoutes.helpcenter}
              >
                Help Center
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex justify-between mb-10 -mr-12 md:mr-0 md:flex-col md:w-1/6">
        <div className="w-2/3 md:w-auto">
          <h4 className="text-lg font-medium">Contact Us</h4>
          <ul>
            <li className="mt-3">
              <a
                id="contact"
                href="mailto:contact@bitoshi.africa"
                className="flex flex-row items-center font-light text-white text-opacity-80 gap-x-2.5"
              >
                <i className="text-xl text-white fa fa-envelope"></i>
                <span>contact@bitoshi.africa</span>
              </a>
            </li>
            <li className="mt-3">
              <a
                id="whatsapp"
                rel="noreferrer"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=2347025217529"
                className="flex flex-row items-center font-light text-white text-opacity-80 gap-x-2.5"
              >
                <i className="text-xl text-white fab fa-whatsapp"></i>
                <span>+234 702 5217 529</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="w-1/3 ml-10 md:w-auto md:ml-0">
          <h4 className="text-lg font-medium text-left md:mt-10">Legal</h4>
          <ul>
            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href={publicRoutes.privacyPolicy}
              >
                Privacy Policy
              </a>
            </li>

            <li className="mt-3">
              <a
                className="font-light text-white text-opacity-80"
                href={publicRoutes.terms}
              >
                Terms of Use
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p className="mt-5 font-light pb-14">
      &copy; {new Date().getFullYear()} Bitoshi. All rights reserved
    </p>
  </div>
);

export const FooterCTA: React.FC = () => (
  <div className="px-5 pt-20 pb-10 mt-32 text-white md:px-20 bg-pattern">
    <h3 id="download" className="text-3xl font-bold">
      Get the Bitoshi app. <br /> Ready, set, go!
    </h3>
    <div className="flex flex-col items-center mt-10 mb-10 md:flex-row md:justify-center md:gap-x-5">
      <GradientButton
        hasBorder
        image="/images/playstore.svg"
        smallText="Get it on"
        bigText="Play Store"
        link="https://play.google.com/store/apps/details?id=com.bitoshi"
      />
      <GradientButton
        hasBorder
        image="/images/appstore.png"
        smallText="Get it on"
        bigText="App Store"
        link="https://apps.apple.com/us/app/bitoshi-africa/id1627285591"
      />
    </div>
  </div>
);

export default Footer;
