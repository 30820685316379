import { httpGetRate } from "api/support/stats";
import { httpFetchPlanTransactions } from "api/support/transaction";
import { Itransaction } from "components/ui/Transaction";
import TransactionItem from "components/ui/TransactionItem";
import VButton from "components/ui/VButton";
import { DateTime } from "luxon";
import { useState } from "react";
import ReactModal from "react-modal";
import { useQuery } from "react-query";
import currency, { cryptoFormat, moneyFormat } from "utilities/currency";
import {
  capitalize,
  frequencyTime,
  showDanger,
  showSuccess,
} from "utilities/misc";
import { post } from "utilities/network";

const depositTransactionStatus = (transaction: Itransaction) => {
  let status: string = transaction.status;

  if (transaction.status === "pending") {
    if (transaction.paid) {
      status = "Paid";
    } else {
      status = "Unpaid";
    }
  }
  return status;
};

const TransactionInfo: React.FC<{
  transaction: Itransaction | null;
  refetch?: () => void;
  full?: boolean;
}> = ({ transaction, refetch, full = false }) => {
  if (!transaction) return <div />;

  switch (transaction.transactionType) {
    case "deposit":
      return (
        <Deposit transaction={transaction} refetch={refetch} full={full} />
      );
    case "withdrawal":
      return (
        <Withdrawal transaction={transaction} refetch={refetch} full={full} />
      );
    case "earning":
      return <Earning transaction={transaction} full={full} />;
    case "swap":
      return <Swap transaction={transaction} full={full} />;
    case "send":
      return <Send transaction={transaction} full={full} />;
    case "receive":
      return <Receive transaction={transaction} full={full} />;
    case "buy":
      return <Buy transaction={transaction} full={full} />;
    case "sell":
      return <Sell transaction={transaction} full={full} />;
    default:
      return transaction.frequency ? (
        <Recurring transaction={transaction} full={full} />
      ) : (
        <Purchase transaction={transaction} full={full} />
      );
  }
};

export const Deposit = ({
  transaction,
  refetch,
  full,
}: {
  transaction: Itransaction;
  refetch?: () => void;
  full?: boolean;
}) => {
  let bank =
    transaction?.address && transaction?.address !== "sell.bitoshi.africa"
      ? JSON.parse(transaction.address)
      : {};

  const [confirmModal, setConfirmModal] = useState(false);

  const close = () => {
    setConfirmModal(false);
  };

  const handleSubmit = () => {
    post(`support/transactions/paymentReceived/${transaction.id}`, {})
      .then(({ data }) => {
        if (data.success) {
          if (refetch) {
            refetch();
          }
          showSuccess("Payment marked as successful");
          setConfirmModal(false);
        }
      })
      .catch((e) => {
        showDanger("Something went wrong");
        setConfirmModal(false);
      });
  };

  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <ReactModal
        shouldCloseOnOverlayClick={true}
        onRequestClose={close}
        isOpen={confirmModal}
        className="w-screen h-screen"
      >
        <div
          onClick={close}
          className="relative w-full h-full bg-gray-500 bg-opacity-50"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute bottom-0 flex flex-col items-center justify-center w-full px-5 bg-white rounded-t-lg py-28 "
          >
            <div className="absolute w-20 h-1 bg-white rounded-lg -top-5"></div>
            <h4 className="mb-5 text-xl">Payment received?</h4>

            <div className="flex mt-10 text-center">
              <VButton
                onClick={handleSubmit}
                className="mr-20 text-white bg-green-500"
              >
                Yes
              </VButton>
              <VButton
                onClick={() => setConfirmModal((p) => !p)}
                className="text-white"
              >
                No
              </VButton>
            </div>
          </div>
        </div>
      </ReactModal>
      <h4 className="mb-5">Deposited NGN</h4>

      <TransactionItem
        title="Amount"
        value={`${currency("ngn")} ${moneyFormat(
          Number(transaction.amount) - Number(transaction.fee)
        )}`}
        isAmount
      />
      <TransactionItem
        title="Fee"
        value={`${currency("ngn")} ${moneyFormat(transaction.fee)}`}
      />
      <TransactionItem
        title="Reference ID"
        value={transaction?.user?.userName}
      />
      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />
      <TransactionItem title="Bank name" value={bank?.bankName} />
      <TransactionItem title="Account Number" value={bank?.accountNumber} />
      <TransactionItem title="Account name" value={bank?.accountName} />
      {/* <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />
      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />
    */}
      <TransactionItem
        title="Merchant"
        underline
        value={`@${transaction?.merchant || ""}`}
      />
      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="Status"
        isStatus
        value={depositTransactionStatus(transaction)}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />

      {transaction.status === "cancelled" && (
        <div className="mt-10 text-center">
          <VButton
            onClick={() => setConfirmModal((p) => !p)}
            className="text-white"
          >
            Payment Received
          </VButton>
        </div>
      )}
    </div>
  );
};

export const Withdrawal = ({
  transaction,
  full,
  refetch,
}: {
  transaction: Itransaction;
  full?: boolean;
  refetch?: () => void;
}) => {
  let bank =
    transaction?.address && transaction?.address !== "sell.bitoshi.africa"
      ? JSON.parse(transaction.address)
      : {};

  const [confirmModal, setConfirmModal] = useState(false);

  const close = () => {
    setConfirmModal(false);
  };

  const handleSubmit = () => {
    post(`support/transactions/paymentSent/${transaction.id}`, {})
      .then(({ data }) => {
        if (data.success) {
          if (refetch) {
            refetch();
          }
          showSuccess("Payment marked as successful");
          setConfirmModal(false);
        }
      })
      .catch((e) => {
        showDanger("Something went wrong");
        setConfirmModal(false);
      });
  };

  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <ReactModal
        shouldCloseOnOverlayClick={true}
        onRequestClose={close}
        isOpen={confirmModal}
        className="w-screen h-screen"
      >
        <div
          onClick={close}
          className="relative w-full h-full bg-gray-500 bg-opacity-50"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="absolute bottom-0 flex flex-col items-center justify-center w-full px-5 bg-white rounded-t-lg py-28 "
          >
            <div className="absolute w-20 h-1 bg-white rounded-lg -top-5"></div>
            <h4 className="mb-5 text-xl">Payment sent?</h4>

            <div className="flex mt-10 text-center">
              <VButton
                onClick={handleSubmit}
                className="mr-20 text-white bg-green-500"
              >
                Yes
              </VButton>
              <VButton
                onClick={() => setConfirmModal((p) => !p)}
                className="text-white"
              >
                No
              </VButton>
            </div>
          </div>
        </div>
      </ReactModal>
      {transaction.place === "external" ? (
        <span className="px-1 text-xs bg-orange-300 rounded">
          sell.bitoshi.africa
        </span>
      ) : (
        ""
      )}
      <h4 className="mb-5">Withdrew NGN</h4>
      <TransactionItem
        title="Amount"
        value={`${currency("ngn")} ${moneyFormat(
          Number(transaction.amount) - Number(transaction.fee)
        )}`}
        isAmount
      />
      <TransactionItem
        title="Fee"
        value={`${currency("ngn")} ${moneyFormat(transaction.fee)}`}
      />
      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />
      <TransactionItem title="Bank name" value={bank?.bankName} />
      <TransactionItem title="Account Number" value={bank?.accountNumber} />
      <TransactionItem title="Account name" value={bank?.accountName} />
      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />
      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />
      <TransactionItem
        title="Merchant"
        underline
        value={`@${transaction?.merchant || ""}`}
      />
      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="Status"
        isStatus
        value={transaction.status}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />
      {transaction.status === "pending" && (
        <div className="mt-10 text-center">
          <VButton
            onClick={() => setConfirmModal((p) => !p)}
            className="text-white"
          >
            Payment Sent
          </VButton>
        </div>
      )}
    </div>
  );
};

export const Earning = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      {transaction.address ? (
        <span className="px-1 text-xs bg-orange-300 rounded">
          {transaction.address}
        </span>
      ) : (
        ""
      )}
      <h4 className="mb-5">Earned NGN</h4>

      <TransactionItem
        title="Amount"
        value={`${currency("ngn")} ${moneyFormat(transaction.amount)}`}
        isAmount
      />

      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />

      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />

      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="Status"
        isStatus
        value={transaction.status}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />
    </div>
  );
};

export const Purchase = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  const rate = transaction.rate ? JSON.parse(transaction.rate) : {};
  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <h4 className="mb-5">Purchases</h4>

      <TransactionItem
        title="Asset"
        value={transaction?.crypto?.toUpperCase()}
      />

      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />

      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />

      <TransactionItem
        title="Amount purchased"
        value={`${currency("ngn")} ${moneyFormat(transaction.amount)}`}
        sub={
          <p className="mt-1 mb-3 text-sm font-light text-opacity-50">
            {cryptoFormat(
              Number(transaction.amount) /
                rate?.buy?.usd /
                rate?.[transaction.crypto]
            )}{" "}
            {" " + transaction.crypto?.toUpperCase()}
          </p>
        }
      />

      <TransactionItem
        title="Name of plan"
        value={transaction?.recurring?.name}
      />

      <TransactionItem
        title="Purchase Date"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="Purchase amount/frequency"
        value={`${currency("ngn")} ${moneyFormat(
          transaction.amount
        )}/${capitalize(transaction?.recurring?.frequency || "")}`}
      />
    </div>
  );
};

export const Recurring = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  const endDate = DateTime.fromISO(transaction.timestamp).plus({
    [frequencyTime[transaction.frequency]]:
      transaction.targetAmount / Number(transaction.amount) - 1,
  });

  const {
    data: { transactions = [] },
  } = useQuery(
    ["planTransactions", transaction.id, transaction.crypto],
    () => httpFetchPlanTransactions(transaction.id, transaction.crypto),
    {
      initialData: [],
    }
  );
  const {
    data: { prices, rate },
  } = useQuery("rate", httpGetRate, {
    initialData: { prices: [], rate: {} },
  });

  const coin = prices?.find(
    (price: any) => price.symbol === transaction.crypto
  );

  const purchases =
    transactions?.filter((t: any) => t.type === "purchased") || [];

  const calculatedAverage =
    purchases.reduce((acc: any, t: any) => {
      let rate = JSON.parse(t.rate);
      return acc + Number(rate[t.crypto]);
    }, 0) / purchases.length;

  const costOfPurchases = purchases.reduce((acc: any, t: any) => {
    return acc + Number(t.amount);
  }, 0);

  const amountPurchased = purchases.reduce((acc: any, t: any) => {
    let rate = JSON.parse(t.rate);
    return (
      acc + Number(t.amount) / Number(rate?.buy?.usd) / Number(rate[t.crypto])
    );
  }, 0);

  const amountPurchasedNaira =
    amountPurchased * rate?.buy?.usd * coin?.current_price || 0;
  const calculatedEarnings = () => {
    return amountPurchasedNaira - costOfPurchases;
  };

  const calculatedBalance = amountPurchasedNaira + calculatedEarnings();

  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <h4 className="mb-5">{capitalize(transaction.status)} Plan</h4>

      <TransactionItem title="Asset" value={transaction.crypto.toUpperCase()} />

      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />

      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />

      <TransactionItem
        title="Amount purchased"
        value={`${currency("ngn")} ${moneyFormat(transaction.amountPurchased)}`}
        // sub={
        //   <p className="mt-1 mb-3 text-sm font-light text-opacity-50">
        //     {cryptoFormat(
        //       Number(transaction.amount) /
        //         rate?.buy?.usd /
        //         rate?.[transaction.crypto]
        //     )}{" "}
        //     {" " + transaction.crypto.toUpperCase()}
        //   </p>
        // }
      />

      <TransactionItem
        title="Target amount"
        value={`${currency("ngn")} ${moneyFormat(transaction.targetAmount)}`}
      />

      <TransactionItem
        title="Start date"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />

      <TransactionItem
        title="End date"
        value={`${endDate.toFormat("DD")} ${endDate.toFormat("t")}`}
      />

      <TransactionItem
        title="Purchase amount/frequency"
        value={`${currency("ngn")} ${moneyFormat(
          transaction.amount
        )}/${capitalize(transaction?.frequency || "")}`}
      />
      <TransactionItem
        title={`${capitalize(frequencyTime[transaction.frequency])}s left`}
        value={
          (transaction.targetAmount - transaction.amountPurchased) /
          Number(transaction.amount)
        }
      />

      <TransactionItem
        title="Next purchase"
        value={
          transaction.nextSchedule
            ? DateTime.fromISO(transaction.nextSchedule).toFormat("DD")
            : ""
        }
      />
      <TransactionItem title="Name of plan" value={transaction.name} />

      <TransactionItem
        title="Average price"
        value={`${currency("ngn")} ${moneyFormat(
          calculatedAverage * rate?.buy?.usd
        )}`}
      />

      <TransactionItem
        title="Balance"
        value={`${cryptoFormat(
          calculatedBalance / rate?.buy?.usd / coin?.current_price
        )} ${transaction?.crypto?.toUpperCase()} / ${currency(
          "ngn"
        )} ${moneyFormat(calculatedBalance)} / ${currency("usd")}${moneyFormat(
          calculatedBalance / rate?.buy?.usd
        )}`}
      />

      <TransactionItem
        title="Earnings"
        value={`${currency("ngn")} ${moneyFormat(calculatedEarnings())}`}
      />
    </div>
  );
};

export const Swap = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <h4 className="mb-5">Swapped {transaction.fromCrypto.toUpperCase()}</h4>

      <TransactionItem
        title={`${transaction.fromCrypto.toUpperCase()} Amount`}
        value={`${
          transaction.fromAmount
        } ${transaction.fromCrypto.toUpperCase()}`}
      />

      <TransactionItem
        title={`${transaction.crypto.toUpperCase()} Amount`}
        value={`${transaction.amount} ${transaction.crypto.toUpperCase()}`}
      />

      <TransactionItem
        title="Fee"
        value={`${cryptoFormat(
          transaction.fee
        )} ${transaction.fromCrypto.toUpperCase()}`}
      />

      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />

      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />

      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />

      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />

      <TransactionItem
        title="Status"
        isStatus
        value={transaction.status}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />
    </div>
  );
};

export const Send = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  const rate = transaction.rate ? JSON.parse(transaction.rate) : {};
  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <h4 className="mb-5">Sent {transaction.crypto.toUpperCase()}</h4>
      <TransactionItem
        title={`Amount`}
        value={`${transaction.amount} ${transaction.crypto.toUpperCase()}`}
        isAmount
        sub={
          <p className="mt-1 mb-3 text-sm font-light text-opacity-50">
            <span className="mr-4">
              {`N${moneyFormat(
                Number(transaction.amount) *
                  rate[transaction.crypto] *
                  rate.sell.usd
              )}`}
            </span>
            <span>
              {`$${moneyFormat(
                Number(transaction.amount) * rate[transaction.crypto]
              )}
              `}
            </span>
          </p>
        }
      />
      {transaction.place === "external" && (
        <>
          <TransactionItem
            title="#Hash"
            value={transaction.transactionHash || ""}
          />
        </>
      )}
      {transaction.place === "external" && (
        <TransactionItem title="External Address" value={transaction.address} />
      )}
      {rate?.isInternal && (
        <TransactionItem
          title="Recipient Address"
          value={transaction.address}
        />
      )}
      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="Transaction Fee"
        value={`${currency("ngn")} ${moneyFormat(transaction.fee)}`}
      />
      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />
      {/* //// Receiver */}
      {transaction.place === "bitoshi" && (
        <TransactionItem
          title="Recipient username"
          underline
          value={`@${
            rate?.isInternal ? rate?.receiver : transaction?.receiver?.userName
          }`}
        />
      )}
      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />
      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />
      <TransactionItem
        title="Status"
        isStatus
        value={transaction.status}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />
    </div>
  );
};

export const Receive = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  const rate = transaction.rate ? JSON.parse(transaction.rate) : {};
  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <h4 className="mb-5">Received {transaction.crypto.toUpperCase()}</h4>
      <TransactionItem
        title={`Amount`}
        value={`${transaction.amount} ${transaction.crypto.toUpperCase()}`}
        isAmount
        sub={
          <p className="mt-1 mb-3 text-sm font-light text-opacity-50">
            <span className="mr-4">
              {`N${moneyFormat(
                Number(transaction.amount) *
                  rate[transaction.crypto] *
                  rate.sell.usd
              )}`}
            </span>
            <span>
              {`$${moneyFormat(
                Number(transaction.amount) * rate[transaction.crypto]
              )}
              `}
            </span>
          </p>
        }
      />
      {transaction.place === "external" && (
        <TransactionItem
          title="#Hash"
          value={transaction.transactionHash || ""}
        />
      )}
      {/* <TransactionItem title="External Address" value={transaction.address} /> */}
      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />

      {transaction.place === "bitoshi" && (
        <TransactionItem
          title="Sender username"
          underline
          value={`@${transaction?.receiver?.userName || rate?.sender}`}
        />
      )}

      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />
      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />
      <TransactionItem
        title="Status"
        isStatus
        value={transaction.status}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />
    </div>
  );
};

export const Buy = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  const rate = transaction.rate ? JSON.parse(transaction.rate) : {};
  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <h4 className="mb-5">Bought {transaction.crypto.toUpperCase()}</h4>
      <TransactionItem
        title={`Amount`}
        value={`${transaction.amount} ${transaction.crypto.toUpperCase()}`}
        isAmount
        sub={
          <p className="mt-1 mb-3 text-sm font-light text-opacity-50">
            <span className="mr-4">
              {`N${moneyFormat(
                Number(transaction.amount) *
                  rate[transaction.crypto] *
                  rate.buy.usd
              )}`}
            </span>
            <span>
              {`$${moneyFormat(
                Number(transaction.amount) * rate[transaction.crypto]
              )}
              `}
            </span>
          </p>
        }
      />
      {transaction.place === "external" && (
        <>
          <TransactionItem
            title="#Hash"
            value={transaction.transactionHash || ""}
          />
        </>
      )}
      <TransactionItem title="Rate" value={`N${rate?.buy?.usd || ""}/$`} />
      <TransactionItem
        title="Price"
        value={`N${
          moneyFormat(rate?.buy?.usd * rate?.[transaction.crypto]) || ""
        }/${transaction.crypto?.toUpperCase()}`}
      />
      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />
      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />
      {transaction.place === "external" && (
        <TransactionItem title="External Address" value={transaction.address} />
      )}
      {rate?.isInternal && (
        <TransactionItem
          title="Recipient User"
          underline
          value={`@${rate?.receiver}`}
        />
      )}
      {rate?.isInternal && (
        <TransactionItem
          title="Recipient Address"
          value={transaction.address}
        />
      )}
      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />
      {transaction.place === "external" && (
        <TransactionItem
          title="Fee"
          value={`${currency("ngn")} ${moneyFormat(transaction.fee)}`}
        />
      )}

      <TransactionItem
        title="Status"
        isStatus
        value={transaction.status}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />
    </div>
  );
};

export const Sell = ({
  transaction,
  full,
}: {
  transaction: Itransaction;
  full?: boolean;
}) => {
  const rate = transaction.rate ? JSON.parse(transaction.rate) : {};
  return (
    <div
      className={`${full ? "" : "w-5/12"} bg-orange-50 rounded-lg py-10 px-8`}
    >
      <h4 className="mb-5">Sold {transaction.crypto.toUpperCase()}</h4>
      <TransactionItem
        title={`Amount`}
        value={`${transaction.amount} ${transaction.crypto.toUpperCase()}`}
        isAmount
        sub={
          <p className="mt-1 mb-3 text-sm font-light text-opacity-50">
            <span className="mr-4">
              {`N${moneyFormat(
                Number(transaction.amount) *
                  rate[transaction.crypto] *
                  rate.sell.usd
              )}`}
            </span>
            <span>
              {`$${moneyFormat(
                Number(transaction.amount) * rate[transaction.crypto]
              )}
              `}
            </span>
          </p>
        }
      />
      {transaction.place === "external" && (
        <>
          <TransactionItem
            title="#Hash"
            value={transaction.transactionHash || ""}
          />
        </>
      )}
      <TransactionItem title="Rate" value={`N${rate?.sell?.usd || ""}/$`} />
      <TransactionItem
        title="Price"
        value={`N${
          moneyFormat(rate?.sell?.usd * rate?.[transaction.crypto]) || ""
        }/${transaction.crypto?.toUpperCase()}`}
      />
      <TransactionItem
        title="Date & Time"
        value={`${DateTime.fromISO(transaction.timestamp).toFormat(
          "DD"
        )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`}
      />
      <TransactionItem
        title="User"
        underline
        value={`@${transaction?.user?.userName}`}
      />
      {transaction.place === "bitoshi" && rate?.isInternal && (
        <TransactionItem title="Sender" underline value={`@${rate?.sender}`} />
      )}

      <TransactionItem
        title="Registered full name"
        value={`${transaction?.user?.firstName} ${transaction?.user?.lastName}`}
      />
      {transaction.place === "external" && (
        <TransactionItem title="External Address" value={transaction.address} />
      )}
      <TransactionItem
        title="Transaction ID"
        value={transaction.transactionID}
      />
      {transaction.place === "external" && (
        <TransactionItem
          title="Fee"
          value={`${currency("ngn")} ${moneyFormat(transaction.fee)}`}
        />
      )}

      <TransactionItem
        title="Status"
        isStatus
        value={transaction.status}
        status={
          transaction.status === "pending"
            ? "text-orange-500 capitalize"
            : transaction.status === "successful"
            ? "text-green-500 capitalize"
            : "text-red-500 capitalize"
        }
      />
    </div>
  );
};

export default TransactionInfo;
