import { httpFetchAdminMarkets } from "api/admin/transaction";
import { httpGenerateAdminMarket, httpSaveAdminMarket } from "api/admin/user";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import { Select } from "components/misc";
import Pagination from "components/ui/Pagination";
import Spinner from "components/ui/Spinner";
import Table from "components/ui/Table";
import TransactionItem from "components/ui/TransactionItem";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import currency, { cryptoFormat, moneyFormat } from "utilities/currency";
import { showSuccess } from "utilities/misc";
import { cryptos } from "utilities/wallets";

export const AdminMarket: React.FC = () => {
  const [fromDate, setFromDate] = useState(DateTime.local().toISODate());
  const [toDate, setToDate] = useState(DateTime.local().toISODate());
  const [crypto, setCrypto] = useState<(typeof cryptos)[number]>(cryptos[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [adminMarket, setAdminMarket] = useState<any>(null);
  const isOpen = !!adminMarket;
  const limit = 50;

  const {
    data: { adminMarkets, total, page },
    refetch,
  } = useQuery(
    ["adminMarkets", currentPage],
    () => httpFetchAdminMarkets(limit, currentPage),
    {
      initialData: {
        adminMarkets: [],
      },
    }
  );

  const handleGenerate = () => {
    setLoading(true);
    httpGenerateAdminMarket(crypto, fromDate, toDate)
      .then(({ data }) => {
        setAdminMarket(data.adminMarket);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = () => {
    setLoading(true);
    httpSaveAdminMarket(
      adminMarket?.crypto,
      adminMarket?.dateRaw?.fromDate,
      adminMarket?.dateRaw?.toDate
    )
      .then(() => {
        showSuccess("Admin Market saved successfully");
        refetch();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">Admin Market</h3>
        </div>

        <div className="flex flex-col justify-between gap-y-7 mt-7">
          <div className="flex flex-col max-w-max">
            <h2 className="text-lg">Generate Order</h2>
            <div className="flex items-center mt-3 gap-x-5">
              <div>
                <label htmlFor="from">From:</label>
                <input
                  id="from"
                  type="date"
                  className="py-1 ml-2 border-gray-400 rounded"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <span>-</span>
              <div>
                <label htmlFor="to">To:</label>
                <input
                  id="to"
                  type="date"
                  className="py-1 ml-2 border-gray-400 rounded"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-5">
              <div className="w-1/2">
                <label className="inline-block mb-1">Select Crypto</label>
                <Select
                  name="crypto"
                  onChange={(e) => setCrypto(e.target.value)}
                  data={cryptos}
                  value={crypto}
                />
              </div>
              <button
                type="button"
                onClick={handleGenerate}
                className="self-center w-1/3 px-6 py-2 font-light text-white bg-black rounded-lg"
              >
                {loading ? <Spinner /> : <span>Generate</span>}
              </button>
            </div>
          </div>
          <div className="w-5/12">
            <h2 className="mb-3 text-lg">Order History</h2>
            <Table
              header={["Crypto", "Amount", "Date"]}
              data={[
                ...adminMarkets.map((adminMarket: any) => [
                  adminMarket.crypto?.toUpperCase(),
                  `${cryptoFormat(
                    Number(adminMarket.amount?.crypto)
                  )} ${adminMarket.crypto.toUpperCase()}`,
                  `${DateTime.fromISO(adminMarket?.fromDate).toFormat(
                    "DD"
                  )} - ${DateTime.fromISO(adminMarket?.toDate).toFormat("DD")}`,
                  () => setAdminMarket(adminMarket),
                ]),
              ]}
            />
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              limit={limit}
              page={page}
              total={total}
            />
          </div>
        </div>
        {isOpen && (
          <div
            className={`w-5/12 md:w-4/12 bg-orange-50 rounded-lg py-10 px-8 md:fixed md:top-28 md:max-h-[81vh] md:overflow-y-auto md:right-5`}
          >
            <h4 className="mb-5">Admin Market</h4>
            <TransactionItem
              title="Crypto Amount"
              value={`${cryptoFormat(
                Number(adminMarket.amount?.crypto)
              )} ${adminMarket?.crypto?.toUpperCase()}`}
              isAmount
            />
            <TransactionItem
              title="USD Amount"
              value={`${currency("usd")} ${moneyFormat(
                adminMarket.amount?.usd
              )}`}
            />
            <TransactionItem
              title="NGN Amount"
              value={`${currency("ngn")} ${moneyFormat(
                adminMarket.amount?.ngn
              )}`}
            />

            <TransactionItem
              title="From"
              value={`${DateTime.fromISO(adminMarket?.fromDate).toFormat(
                "DD"
              )} ${DateTime.fromISO(adminMarket?.fromDate).toFormat("t")}`}
            />
            <TransactionItem
              title="To"
              value={`${DateTime.fromISO(adminMarket?.toDate).toFormat(
                "DD"
              )} ${DateTime.fromISO(adminMarket?.toDate).toFormat("t")}`}
            />

            <TransactionItem
              title="Generated on"
              value={
                adminMarket?.createdAt
                  ? `${DateTime.fromISO(adminMarket?.createdAt).toFormat(
                      "DD"
                    )} ${DateTime.fromISO(adminMarket?.createdAt).toFormat(
                      "t"
                    )}`
                  : `${DateTime.now().toFormat("DD")}`
              }
            />

            {adminMarket?.dateRaw ? (
              <div className="mt-10 text-center">
                <button
                  type="button"
                  onClick={handleSave}
                  className="px-10 text-white bg-black py-2.5 rounded-lg"
                >
                  {loading ? <Spinner /> : <span>Save</span>}
                </button>
              </div>
            ) : null}
          </div>
        )}
      </Main>
    </Container>
  );
};
