import { httpUserWallet } from "api/support/user";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import SupportSidebar from "components/layout/SupportSidebar";
import Search from "components/ui/Search";
import TransactionItem from "components/ui/TransactionItem";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { supportRoutes } from "routes/routes";
import { explorerLinkForAddress } from "utilities/misc";
import { cryptos } from "utilities/wallets";

const AddressesExplorer: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userID = params.user as string;

  const {
    data: { wallets, user },
  } = useQuery(["userWallet", userID], () => httpUserWallet(userID), {
    initialData: {
      wallets: {},
      user: {},
    },
    onError: (err) => {
      navigate(-1);
    },
  });

  return (
    <Container>
      <SupportSidebar />
      <Main className="mt-5 mx-5 text-left">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">@{user?.userName} Addresses explorer</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="mt-14 flex gap-8">
          <div className="w-7/12">
            <div className="w-full flex gap-5 flex-wrap">
              {cryptos.map((crypto) => (
                <div
                  key={crypto}
                  className="border border-gray-500 rounded-lg w-5/12 py-5 px-3"
                >
                  <div className="flex justify-between text-sm">
                    <span className="font-light">{crypto?.toUpperCase()}</span>
                    <i className="fa fa-bitcoin bg-orange-100 p-1 rounded"></i>
                  </div>
                  <div className="flex mt-5 mb-2">
                    <p className="font-medium">Sell Address:</p>
                    {wallets[crypto]?.sellAddress && (
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        className="ml-2 text-orange-400"
                        href={explorerLinkForAddress(
                          wallets[crypto]?.sellAddress,
                          crypto
                        )}
                      >
                        View
                      </a>
                    )}
                  </div>
                  <div className="flex mt-5 mb-2">
                    <p className="font-medium my-1">Receive Address</p>
                    {wallets[crypto]?.receiveAddress && (
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        className="ml-2 text-orange-400"
                        href={explorerLinkForAddress(
                          wallets[crypto]?.receiveAddress,
                          crypto
                        )}
                      >
                        View
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-5/12 bg-orange-50 rounded-lg py-10 px-8">
            <h4 className="mb-5">@{user?.userName}</h4>

            <TransactionItem title="Username" value={`@${user?.userName}`} />
            <TransactionItem
              title="Registered full Name"
              value={`${user?.firstName} ${user?.lastName}`}
            />
            <TransactionItem title="e-mail" value={user?.email} />
            <TransactionItem title="Phone Number" value={user?.phoneNumber} />
            <TransactionItem
              title="Verification Level"
              value={`Level ${user?.level}`}
            />
            <TransactionItem
              title="Date registered"
              value={DateTime.fromISO(user?.regDate).toFormat("D")}
            />

            <div className="mt-10 flex gap-x-20 gap-y-10 flex-wrap">
              <Link to={`${supportRoutes.balances}/user/${user?.id}`}>
                <span
                  className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                >
                  Balances
                </span>
              </Link>
              <Link to={`${supportRoutes.transactions}/user/${user.id}`}>
                <span
                  className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                >
                  Transactions
                </span>
              </Link>
              <Link to={`${supportRoutes.analytics}/user/${user.id}`}>
                <span
                  className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                >
                  Analytics
                </span>
              </Link>
              <Link to={`${supportRoutes.explorer}/user/${user.id}`}>
                <span
                  className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                >
                  Addresses Explorer
                </span>
              </Link>
            </div>
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default AddressesExplorer;
