const currency = (currency: string) =>
  currency.toLowerCase() === "usd" ? "$" : "₦";

export const currencySymbolFull = (currency: string) =>
  currency.toLowerCase() === "usd" ? "USD" : "NGN";

export const moneyFormat = (
  value: number | string,
  showDecimal: boolean = true
) =>
  Number(value)
    .toFixed(showDecimal ? 2 : 0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// .replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const cryptoFormat = (value: number | string) => {
  let val =
    String(value)?.split(".")[1]?.length >= 8
      ? Number(value).toFixed(8)
      : String(value);

  return val.replace(/\.\d+|\d(?=(?:\d{3})+(?!\d))/g, function (a) {
    return a.length === 1 ? a + "," : a;
  });
};

export default currency;
