import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import ShadowContainer from "components/ui/ShadowContainer";
import ToggleItem from "components/ui/ToggleItem";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { publicRoutes } from "routes/routes";

const helps = [
  {
    url: "what-is-bitoshi-africa",
    title: "What is Bitoshi?",
    description: (
      <p className="px-5 md:px-0">
        Bitoshi is a crypto wallet exchange where you can{" "}
        <br className="hidden md:inline" /> buy, sell, swap and transfer
        cryptocurrencies. We are all <br className="hidden md:inline" /> you
        need to explore cryptocurrencies easily.
      </p>
    ),
    content: [
      {
        title: "How to get started on Bitoshi?",
        content:
          "All you simply need to do is create an account with your email and password, verify your email and phone number, give us some basic information about yourself and voilà you are all almost fully setup! Next step is verifying your identity.",
      },
      {
        title: "How to upgrade your Bitoshi account?",
        content: (
          <>
            <p>
              To upgrade your{" "}
              <a
                className="text-black underline text-opacity-70"
                href="https://Bitoshi"
              >
                Bitoshi
              </a>{" "}
              account and increase your transaction limits
            </p>

            <ol>
              <li className="mt-5 mb-5">
                1. Go to <b>Finish account setup</b> from your dashboard or go
                to Profile then click on **Limits and Upgrade**
              </li>
              <li className="mb-5">
                2. Click on <b>Upgrade to level 2</b>{" "}
              </li>
              <li className="mb-5">
                3. This will open to an identity verification page where you
                will be required to give us information about your identity and
                your home address. Once you have filled the form, click on{" "}
                <b>Next.</b>
              </li>
              <li className="mb-5">
                4. As part of the verification process, you will be asked to
                upload your government-issued ID. You can choose to use any of
                these:
                <ul>
                  <li>Driver’s License</li>
                  <li>National ID</li>
                  <li>Bank Verification Number</li>
                  <li>International Passport</li>
                </ul>
              </li>
              <li className="mb-5">
                5. Once you have uploaded your identity, you will also be
                required to make a real time selfie video (Place your face in
                the circle and the smile).
              </li>
              <li className="mb-5">
                6. Once you have filled the details required. click on{" "}
                <b>Complete.</b>
              </li>
            </ol>
          </>
        ),
      },
      {
        title: "How to share your referral code?",
        content: (
          <>
            <p>
              `You and your friends get N500 cash when they sign up, complete
              their identity verification and trade up to $100 with your
              referral code.
            </p>
            <p>
              To share your referral code, open the Bitoshi app, tap on your{" "}
              <b>Profile Picture</b> and go to <b>Refer and Earn</b>. There you
              will find your referral code, you can copy your referral code or
              share your referral link.
            </p>
            <p>
              To apply a referral code, while creating your account as a new
              user, enter the referral code and click on Next.
            </p>
          </>
        ),
      },
    ],
  },

  {
    url: "naira-deposit-and-withdrawal",
    title: "Naira Deposit and Withdrawal",
    content: [
      {
        title: "How to make naira deposit",
        content: (
          <>
            <p>
              You can deposit your naira easily following these short steps:
            </p>
            <ul className="list-disc list-inside">
              <li className="mt-5 mb-5">
                <b>Access the Deposit Option:</b> Log in to your Bitoshi account
                and locate the 'Add Money' button on the homepage.
              </li>
              <li className="mb-5">
                <b>Choose the Deposit Method:</b> Select the 'Buy Voucher'
                option from the pop-up menu.
              </li>
              <li className="mb-5">
                <b>Input Deposit Amount:</b> Specify the amount of Naira you
                wish to deposit into your wallet.
              </li>
              <li className="mb-5">
                <b>Transfer Funds:</b> Carefully transfer the specified amount
                to the provided account number, ensuring you include the correct
                reference or memo.
              </li>
              <li className="mb-5">
                <b>Confirm Payment:</b> Once the transfer is complete, click
                'I've made Payment' on the Bitoshi app to finalize the deposit.
              </li>
            </ul>
          </>
        ),
      },

      {
        title: "How to make a naira withdrawal",
        content: (
          <>
            <p>
              You can easily make a Naira withdrawal following these short
              steps:
            </p>
            <ul className="list-disc list-inside">
              <li className="mt-5 mb-5">
                <b>Log In:</b> Access your Bitoshi account by entering your
                login credentials.
              </li>
              <li className="mb-5">
                <b>Navigate to Pay:</b> On the homepage, tap the "Pay" icon.{" "}
              </li>
              <li className="mb-5">
                <b>Select Send Money:</b> Choose the "Send Money" option from
                the available choices.
              </li>
              <li className="mb-5">
                <b>Choose Wallet and Input Amount:</b> Select your Naira wallet,
                then enter the desired transfer amount.
              </li>
              <li className="mb-5">
                <b>Confirm and Send:</b> Double-check the details, and tap
                "Continue" to initiate the transfer.
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "How much are deposit and withdrawal fees",
        content: (
          <>
            <p>We believe in keeping things simple and affordable.</p>
            <ul className="list-disc list-inside">
              <li className="mt-5 mb-5">
                <b>Deposit Fee:</b> There's a 2% charge for adding money to your
                Bitoshi wallet, capped at NGN 1,000.
              </li>
              <li className="mb-5">
                <b>Withdrawal Fee:</b> You'll be charged a flat fee of NGN 100
                for every Naira withdrawal.
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "What are my naira limits?",
        content: (
          <>
            <p>
              There are currently two account levels on Bitoshi, and your limits
              depend on your account level.
            </p>

            <h4 className="mt-4 text-base font-bold">
              Level 1: Naira Deposit and Withdrawal Limit
            </h4>
            <p>Deposit Limit: Unavailable</p>
            <p>Receiving: Unlimited</p>
            <p>Withdrawal Limit: NGN 200,000.00 Daily</p>
            <p>Maximum Balance: Unlimited</p>

            <h4 className="mt-4 text-base font-bold">
              Level 2: Naira Deposit and Withdrawal Limit
            </h4>
            <p>Deposit Limit: NGN 500,000 Daily</p>
            <p>Receiving: Unlimited</p>
            <p>Withdrawal Limit: NGN 10,000,000 Daily</p>
            <p>Maximum Balance: Unlimited</p>
            <p>Sending per transaction: 5,000,000</p>

            <p className="mt-5">
              If you frequently reach your Level 2 limits and need to increase
              them, please contact our support team at contact@Bitoshi.Africa.
              We might ask for some documents to verify your identity before we
              can adjust your limits.
            </p>
          </>
        ),
      },
    ],
  },

  {
    url: "send-and-receive",
    title: "Cryptocurrency Send and Receive",
    content: [
      {
        title: "How to send cryptocurrency on Bitoshi",
        content: (
          <>
            <p>
              You can always send out cryptocurrency from your Bitoshi wallet to
              other external wallets while paying very little for network fees
              and still getting your transactions confirmed quickly on the
              blockchain.
            </p>
            <p>Here's what you need to do</p>
            <ol>
              <li className="mt-5 mb-5">
                1. Go to <b>My Wallet</b> by tapping on the wallets icon at the
                bottom-right of the app
              </li>
              <li className="mb-5">
                2. Click on the cryptocurrency you want to send
              </li>
              <li className="mb-5">
                3. On the next page, tap on{" "}
                <b>Send and choose Send to External Wallet</b>
              </li>
              <li className="mb-5">
                4. Input the amount and the external address you are sending to
              </li>
              <li className="mb-5">
                5. Click on <b>Continue</b> and confirm the transaction details
              </li>
              <li className="mb-5">
                6. Enter your PIN and click on <b>Continue</b> to send
                <br />
                <b>
                  NB: Cryptocurrency sent to external wallets are irreversible,
                  please ensure to confirm properly that the address inputted is
                  correct before advancing to the next step{" "}
                </b>
              </li>
            </ol>
          </>
        ),
      },

      {
        title: "How to receive cryptocurrency on Bitoshi",
        content: (
          <>
            <p>
              Receiving cryptocurrency on Bitoshi is very easy, all you need to
              do is forward your wallet address information to the sender
            </p>
            <p>Here's how to get your wallet address</p>
            <ol>
              <li className="mt-5 mb-5">
                1. Go to <b>My Wallet</b> by tapping on the wallets icon at the
                bottom-right of the app
              </li>
              <li className="mb-5">
                2. Click on the cryptocurrency you want to receive
              </li>
              <li className="mb-5">
                3. On the next page, tap on <b>Receive</b>
              </li>
              <li className="mb-5">
                4. On the next page, you will find your wallet address
                information, you can copy or scan the QR code{" "}
              </li>
            </ol>
          </>
        ),
      },
      {
        title: "What are my Cryptocurrency Limits?",
        content: (
          <>
            <p>There are two account levels on Bitoshi</p>
            <p className="mt-2 mb-1">
              <b>Level 1: Sending and Receiving Limit</b>{" "}
            </p>
            <p>Sending Limit: 0.005 BTC Daily</p>
            <p>Receiving Limit: Unlimited</p>
            <p>Maximum Balance: Unlimited</p>
            <p className="mt-2 mb-1">
              <b>Level 2: Sending and Receiving Limit</b>{" "}
            </p>
            <p>Sending Limit: 1 BTC Daily</p>
            <p>Receiving Limit: Unlimited</p>
            <p>Maximum Balance: Unlimited</p>
          </>
        ),
      },
      {
        title: "How are withdrawal fees calculated on Bitoshi?",
        content: (
          <>
            <p>
              First of all we charge withdrawal network fees to pay miners and
              ensure your transactions get completed on the blockchain as soon
              as possible.
            </p>
            <p>
              {" "}
              Now to calculate what withdrawal fees to charge, we look at the
              average miners fee required to complete a transaction on the
              blockchain in no time and place our fee slightly above it, so that
              your withdrawals are not delayed unnecessarily
            </p>
          </>
        ),
      },
      {
        title: "Cryptocurrency Send Fees",
        content: (
          <>
            <p className="mb-5">
              Bitoshi only charge network fees when you are sending to external
              wallets
            </p>
            <p>Bitcoin - 0.00015 BTC</p>
            <p>Tether - 1 USDT</p>
            <p>Tron - 1 TRX</p>
            <p>Binance Coin - Dynamic</p>
            <p>Ethereum - Dynamic</p>
            <p>Litecoin - Dynamic</p>
            <p>
              You can send cryptocurrency to a Bitoshi user for free by using
              only their username{" "}
            </p>
          </>
        ),
      },
    ],
  },
  {
    url: "buying-and-selling",
    title: "Buying and Selling Cryptocurrency",
    content: [
      {
        title: "How to buy cryptocurrency to my Bitoshi wallet?",
        content: (
          <>
            <p>To buy cryptocurrency follow these steps</p>

            <ol>
              <li className="mt-5 mb-5">
                1. Tap on <b>Buy</b> from the dashboard
              </li>
              <li className="mb-5">
                2. Choose the cryptocurrency you want to buy
              </li>
              <li className="mb-5">
                3. Choose <b>Buy to My Wallet</b>
              </li>
              <li className="mb-5">
                4. Input the amount you want to buy and click on <b>Continue</b>
              </li>
              <li className="mb-5">
                5. Confirm the transaction details and click on Confirm
              </li>
            </ol>
          </>
        ),
      },

      {
        title: "How to sell cryptocurrency from my Bitoshi wallet?",
        content: (
          <>
            <p>To sell cryptocurrency follow these steps</p>

            <ol>
              <li className="mt-5 mb-5">
                1. Tap on <b>Sell</b> from the dashboard
              </li>
              <li className="mb-5">
                2. Choose the cryptocurrency you want to sell
              </li>
              <li className="mb-5">
                3. Choose <b>Sell from My Wallet</b>
              </li>
              <li className="mb-5">
                4. Input the amount you want to sell and click on{" "}
                <b>Continue</b>
              </li>
              <li className="mb-5">
                5. Confirm the transaction details and click on <b>Confirm</b>{" "}
                to sell
              </li>
            </ol>
          </>
        ),
      },
      {
        title: "How to buy cryptocurrency to external wallets on Bitoshi?",
        content: (
          <>
            <p>
              {" "}
              You can buy cryptocurrency directly to external wallets with your
              Naira Wallet on Bitoshi
            </p>

            <ol>
              <li className="mt-5 mb-5">
                1. Tap on <b>Buy</b> from the dashboard
              </li>
              <li className="mb-5">
                2. Choose the cryptocurrency you want to buy
              </li>
              <li className="mb-5">
                3. Choose <b>Buy to External Wallet</b>
              </li>
              <li className="mb-5">
                4. Input the amount you want to buy and the address of the
                wallet you want to buy the cryptocurrency to
              </li>
              <li className="mb-5">
                5. Click on <b>Continue</b> and confirm the transaction details
              </li>
              <li className="mb-5">
                6. Click on <b>Confirm</b> to buy
              </li>
            </ol>
          </>
        ),
      },
      {
        title: "How to sell cryptocurrency from external wallets on Bitoshi?",
        content: (
          <>
            <p>
              You can sell cryptocurrency directly from external wallets to your
              Naira Wallet on Bitoshi
            </p>

            <ol>
              <li className="mt-5 mb-5">
                1. Tap on <b>Sell</b> from the dashboard
              </li>
              <li className="mb-5">
                2. Choose the cryptocurrency you want to sell
              </li>
              <li className="mb-5">
                3. Choose <b>Sell from External Wallet</b>
              </li>
              <li className="mb-5">
                4. On the next page you will find your Bitoshi wallet address
                details, all you need to do is send the cryptocurrency amount
                you want to sell from the external wallet to your Bitoshi
                wallet. The cryptocurrency will be sold automatically after it
                confirms on the blockchain and will be added to your Naira
                Wallet immediately
              </li>
            </ol>
          </>
        ),
      },
    ],
  },
  {
    url: "recurring-buy",
    title: "Recurring Buy",
    content: [
      {
        title: "What is Recurring Buy?",
        content: (
          <>
            <p>
              Recurring Buy uses dollar-cost averaging investment strategy to
              help you reduce the impact of volatility on the overall purchase
              of a cryptocurrency. With Recurring Buy you can start a plan and
              choose how you want to fund this plan or when you want it to end
              and the target amount
            </p>
          </>
        ),
      },

      {
        title: "How do I Start a Plan?",
        content: (
          <>
            <ol>
              <li className="mt-5 mb-5">
                1. Tap on <b>Buy/Sell</b> from the dashboard at the bottom
                navigation
              </li>
              <li className="mb-5">
                2. Choose <b>Recurring Buy</b>
              </li>
              <li className="mb-5">
                3. Click on <b>Start a Plan</b>
              </li>
              <li className="mb-5">
                4. Fill the required fields with the details of how you want to
                setup your plan and click on Continue to confirm your plan
                details and start plan
              </li>
            </ol>
          </>
        ),
      },
      {
        title: "Can I pause my plan at anytime?",
        content: (
          <>
            <p>
              Yes, you can pause your plan at anytime and the great thing is
              there's no fee or penalty for pausing a plan.
            </p>
            <p>
              You can continue the plan again whenever you like. To pause a
              plan, click on the plan you want to pause under My Plans and click
              on Pause Plan to the pause the plan
            </p>
          </>
        ),
      },
      {
        title: "Can I end my plan at anytime?",
        content: (
          <>
            <p>
              Yes, you can end your plan at anytime. However, if you end a plan
              before the end date you will incur a 1% fee i.e 1% of the total
              accrued amount so far. To end a plan, simply click on the plan you
              want to end under <b>My Plans</b> and click on <b>Stop Plan</b> to
              end the plan
            </p>
          </>
        ),
      },
    ],
  },
  {
    url: "security",
    title: "Security",
    content: [
      {
        title: "Security Tips",
        content: (
          <>
            <p>
              Now that you are a member of the clan! Here are some security tips
              that will help you keep your funds safe on Bitoshi
            </p>

            <ol>
              <li className="mt-5 mb-5">
                1. Don't use easy PIN numbers e.g 1111, 0000, 1234 etc
              </li>
              <li className="mb-5">
                2. Ignore spamming texts and emails, we will never request for
                your personal information via email or text. Our verified
                contact informations are available on the website
              </li>
              <li className="mb-5">
                3. Download and update the Bitoshi app from Playstore or
                Appstore only
              </li>
              <li className="mb-5">
                4. Secure and keep track of your mobile device always
              </li>
            </ol>
          </>
        ),
      },

      {
        title: "How to change your PIN/Forgot PIN",
        content: (
          <>
            <ol>
              <li className="mt-5 mb-5">
                1. Tap on your <b>Profile Picture</b> and go to Security
              </li>
              <li className="mb-5">
                2. Click on <b>Change PIN</b>
              </li>
              <li className="mb-5">
                3. Input your current PIN and new PIN then click{" "}
                <b>Change PIN</b>
              </li>
            </ol>
          </>
        ),
      },

      {
        title: "How to delete your account",
        content: (
          <>
            <p>You can follow these steps within your Bitoshi app:</p>
            <ol>
              <li className="mt-4 mb-3">1. Log in to your Bitoshi account.</li>
              <li className="mb-3">2. Go to your Profile.</li>
              <li className="mb-3">3. Click on Security.</li>
              <li className="mb-3">4. Click on Delete Account.</li>
              <li className="mb-3">
                5. To confirm deletion, ensure all funds are withdrawn, then
                enter your username and click&nbsp;
                <span className="font-semibold">
                  Yes, I have, please delete my account.
                </span>
              </li>
            </ol>

            <p className="mt-5">
              Alternatively, if you no longer have the app installed, you can
              click on this{" "}
              <a
                href={publicRoutes.requestAccountDeletion}
                className="font-semibold underline"
              >
                link
              </a>{" "}
              to proceed with the account deletion process.
            </p>
          </>
        ),
      },
    ],
  },
];

const Helpcenter: React.FC = () => {
  const { help } = useParams();

  const activeHelp: any = help ? helps.find((h) => h.url === help) || {} : null;

  return (
    <>
      <Header plain />
      <Helmet>
        <title>Bitoshi Africa | Fast and Reliable customer care</title>
        <meta
          name="description"
          content="Enjoy fast and reliable customer care support for every question or query."
        />
        <meta
          name="keywords"
          content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto | Bitoshi Help Center | Contact Bitoshi | Customer Service | Bitoshi Guide"
        />
      </Helmet>
      <div className="flex flex-col items-center mb-10">
        <div className="w-full py-20 pt-40 bg-black bg-center bg-pattern md:py-44">
          <h2 className="mb-2 text-4xl font-bold text-white">
            {activeHelp?.title || "Got Questions?"}
          </h2>
          <div className="font-thin text-white text-opacity-80">
            {help ? activeHelp?.description || "" : "We are here to help you"}
          </div>
        </div>

        <div className="w-full px-5 md:px-20">
          {!help ? (
            <div className="w-full mt-10 mb-10 md:grid md:grid-cols-2 md:gap-x-10">
              <Link to={`${publicRoutes.helpcenter}/what-is-bitoshi-africa`}>
                <button className="bg-[#FFFBF7] text-left md:text-center md:py-4 text-black border border-black border-opacity-80 py-2 rounded-lg px-3 mb-4 block w-full">
                  What is Bitoshi?
                </button>
              </Link>
              <Link
                to={`${publicRoutes.helpcenter}/naira-deposit-and-withdrawal`}
              >
                <button className="bg-[#FFFBF7] text-left md:text-center md:py-4 text-black border border-black border-opacity-80 py-2 rounded-lg px-3 mb-4 block w-full">
                  Naira Deposit and Withdrawal
                </button>
              </Link>

              <Link to={`${publicRoutes.helpcenter}/send-and-receive`}>
                <button className="bg-[#FFFBF7] text-left md:text-center md:py-4 text-black border border-black border-opacity-80 py-2 rounded-lg px-3 mb-4 block w-full">
                  Cryptocurrency Send and Receive
                </button>
              </Link>
              <Link to={`${publicRoutes.helpcenter}/buying-and-selling`}>
                <button className="bg-[#FFFBF7] text-left md:text-center md:py-4 text-black border border-black border-opacity-80 py-2 rounded-lg px-3 mb-4 block w-full">
                  Buying and Selling Cryptocurrency
                </button>
              </Link>
              <Link to={`${publicRoutes.helpcenter}/recurring-buy`}>
                <button className="bg-[#FFFBF7] text-left md:text-center md:py-4 text-black border border-black border-opacity-80 py-2 rounded-lg px-3 mb-4 block w-full">
                  Recurring Buy
                </button>
              </Link>
              <Link to={`${publicRoutes.helpcenter}/security`}>
                <button className="bg-[#FFFBF7] text-left md:text-center md:py-4 text-black border border-black border-opacity-80 py-2 rounded-lg px-3 mb-4 block w-full">
                  Security
                </button>
              </Link>
            </div>
          ) : null}
        </div>
      </div>

      <div className="md:px-20">
        {!help ? null : (
          <div className="px-5 mt-20 mb-28" key={activeHelp?.url}>
            <ShadowContainer noShadowBg>
              {activeHelp?.content?.map((c: any) => (
                <ToggleItem
                  key={c.title}
                  title={c.title}
                  content={c.content}
                  isHelp
                />
              ))}
            </ShadowContainer>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Helpcenter;
