import { httpGetAdmin } from "api/admin/auth";
import { currentAdmin } from "helpers/storage";
import { useState } from "react";
import { useQuery } from "react-query";

const useGetAdmin = () => {
  const [admin, setAdmin] = useState<any>(currentAdmin());

  const { refetch: refetchAdmin } = useQuery("admin", httpGetAdmin, {
    initialData: { user: admin },
    onSuccess: (data) => {
      setAdmin(data?.user);
    },
  });
  return { admin, refetchAdmin };
};

export default useGetAdmin;
