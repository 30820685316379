import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import PINModal from "components/ui/PINModal";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const ConfirmWithdraw: React.FC = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [pin, setPin] = useState("");
  const [isPINModalOpen, setIsPINModalOpen] = useState(false);

  useEffect(() => {
    if (!location?.state?.bank?.accountNumber || !location?.state?.amount) {
      return navigate(vendorRoutes.withdraw);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { bank, amount } = location?.state;

  const {
    execute: withdraw,
    status,
    value,
  } = useAsync(
    () =>
      post(`vendor/transactions/withdraw`, {
        accountNumber: bank.accountNumber,
        amount,
        pin,
      }),
    false
  );

  const handleSubmit = () => {
    if (pin.length !== 4) {
      return showDanger("PIN should be 4 characters long");
    }

    withdraw();
  };

  const handleOpenPINModal = () => {
    setIsPINModalOpen(true);
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "Withdrawal request successful");
      setIsPINModalOpen(false);
      navigate(vendorRoutes.dashboard);
    }
  }, [value, navigate]);

  return (
    <Container isVendor>
      <VendorSidebar />
      <PINModal
        pin={pin}
        setPin={setPin}
        proceed={handleSubmit}
        status={status}
        isOpen={isPINModalOpen}
        close={() => setIsPINModalOpen(false)}
      />
      <Main isVendor>
        <VendorNav
          title="Withdrawal Confirmation"
          subtitle="Expect to receive  payment in 10 minutes"
        />

        <div className="mt-10">
          <div className="py-5 rounded-lg bg-orange-50 text-left px-5">
            <p className="text-orange-300 text-sm">
              {`You are about to  withdraw ${currency("ngn")}${moneyFormat(
                amount
              )} to your ${bank.bankName} with  Account Number `}
              <b>{bank.accountNumber}</b>
            </p>
          </div>

          <p className="text-xs mt-4">
            We will only pay bank accounts with your registered full name
          </p>

          <VButton
            onClick={handleOpenPINModal}
            className="bg-orange-300 py-4 mt-10 text-white w-full"
          >
            Confirm Withdrawal
          </VButton>
        </div>
      </Main>
    </Container>
  );
};

export default ConfirmWithdraw;
