import {
  ADMIN_STORAGE_KEY,
  ADMIN_TOKEN_STORAGE_KEY,
  currentAdminAuthToken,
  currentSupportAuthToken,
  currentVendorAuthToken,
  SUPPORT_STORAGE_KEY,
  SUPPORT_TOKEN_STORAGE_KEY,
  VENDOR_STORAGE_KEY,
  VENDOR_TOKEN_STORAGE_KEY,
} from "helpers/storage";
import axios from "Lib/Axios/index";
import {
  adminRoutes,
  subdomainRoutes,
  supportRoutes,
  vendorRoutes,
} from "routes/routes";
import { parse } from "tldjs";
import { handleError, removeFalsyValues } from "./misc";

const baseURL = process.env.REACT_APP_BASE_URL;

const userToken = () => {
  const { protocol, host } = window.location;
  let data = parse(`${protocol}//${host}`);
  let subdomain = data?.subdomain?.replace("www.", "");

  switch (subdomain) {
    case subdomainRoutes.admin:
      return currentAdminAuthToken();
    case subdomainRoutes.support:
      return currentSupportAuthToken();
    default:
      return currentVendorAuthToken();
  }
};

export const logout = () => {
  localStorage.removeItem(VENDOR_STORAGE_KEY);
  localStorage.removeItem(VENDOR_TOKEN_STORAGE_KEY);
  window.location.replace(vendorRoutes.login);
};

export const logoutAdmin = () => {
  localStorage.removeItem(ADMIN_STORAGE_KEY);
  localStorage.removeItem(ADMIN_TOKEN_STORAGE_KEY);
  window.location.replace(adminRoutes.login);
};

export const logoutSupport = () => {
  localStorage.removeItem(SUPPORT_STORAGE_KEY);
  localStorage.removeItem(SUPPORT_TOKEN_STORAGE_KEY);
  window.location.replace(supportRoutes.login);
};

export const post = (url: string, data: any) => {
  return axios
    .post(`${baseURL}${url}`, data, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }

      handleError(response);
      throw response.data;
    });
};

export const get = (url: string) =>
  axios
    .get(`${baseURL}${url}`, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })

    .then(({ data }) => data?.data)

    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }
      handleError(response);
      throw response.data;
    });

export const put = (url: string, data: any) =>
  axios
    .put(`${baseURL}${url}`, data, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .then(({ data }) => data)
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }
      handleError(response);
      throw response.data;
    });

export const deleteRequest = (url: string) =>
  axios
    .delete(`${baseURL}${url}`, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .then(({ data }) => data)
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }
      handleError(response);
      throw response.data;
    });

export const patch = (url: string, data: any) =>
  axios
    .patch(`${baseURL}${url}`, data, {
      headers: {
        ...removeFalsyValues({
          Authorization: userToken()
            ? `Bearer ${userToken() || ""}`
            : undefined,
        }),
      },
    })
    .catch(({ response }) => {
      const { status } = response;
      if (status === 401) {
        logout();
      }
      handleError(response);
      throw response.data;
    });
