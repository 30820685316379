import { httpGrowthMetrics } from "api/admin/user";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import { Select } from "components/misc";
import Search from "components/ui/Search";
import { MetricsTable } from "components/ui/Table";
import { useState } from "react";
import { useQueries } from "react-query";
import { months } from "utilities/misc";

const data = {
  usersCount: {
    titles: [
      "New Users",
      "Fully Registered New Users",
      "New Users with Transactions",
    ],
    data: ["newUsers", "fullyRegisteredUsers", "usersWithTransaction"],
  },
  transactionCount: {
    titles: [
      "Add Money",
      "Send Money (Naira Wallet)",
      "Send Money (Crypto Wallet)",
      "Pay Bills (Naira Wallet)",
      "Pay Bills (Crypto Wallet)",
      "Send (External Wallet)",
      "Send (Internal Wallet)",
      "Receive (External Wallet)",
      "Receive (Internal Wallet)",
      "Sell (External Wallet)",
      "Sell (My Wallet)",
      "Buy (External Wallet)",
      "Buy (My Wallet)",
      "Recurring Buy",
    ],
    data: [
      "addMoney",
      "sendMoneyNaira",
      "sendMoneyCrypto",
      "payBillsNaira",
      "payBillsCrypto",
      "sendToExternal",
      "sendToInternal",
      "receiveFromExternal",
      "receiveFromInternal",
      "sellFromExternal",
      "sellToInternal",
      "buyFromExternal",
      "buyFromInternal",
      "recurringTransactions",
    ],
  },
  nairaVolume: {
    titles: [
      "Add Money",
      "Send Money (Naira Wallet)",
      "Pay Bills (Naira Wallet)",
      "Sell (External Wallet)",
      "Sell (My Wallet)",
      "Buy (External Wallet)",
      "Buy (My Wallet)",
      "Recurring Buy",
    ],
    data: [
      "addMoneyNairaVolume",
      "sendMoneyNairaVolume",
      "payBillsNairaVolume",
      "sellFromExternalVolume",
      "sellFromInternalVolume",
      "buyFromExternalVolume",
      "buyFromInternalVolume",
      "recurringTransactionsVolume",
    ],
  },
  dollarVolume: {
    titles: [
      "Send Money (Crypto Wallet)",
      "Pay Bills (Crypto Wallet)",
      "Send (External Wallet)",
      "Send (Internal Wallet)",
      "Receive (External Wallet)",
      "Receive (Internal Wallet)",
    ],
    data: [
      "sendMoneyDollarVolume",
      "payBillsDollarVolume",
      "sendToExternalVolume",
      "sendToInternalVolume",
      "receiveFromExternalVolume",
      "receiveFromInternalVolume",
    ],
  },
};

const GrowthMetrics: React.FC = () => {
  const [month, setMonth] = useState(months[0]);

  const results = useQueries([
    {
      queryKey: ["usersCount", month.value],
      queryFn: () => httpGrowthMetrics(month.value, "usersCount"),
      initialData: { usersCount: [] },
    },
    {
      queryKey: ["transactionCount", month.value],
      queryFn: () => httpGrowthMetrics(month.value, "transactionsCount"),
      initialData: { transactionCount: [] },
    },
    {
      queryKey: ["nairaVolume", month.value],
      queryFn: () => httpGrowthMetrics(month.value, "nairaVolume"),
      initialData: { nairaVolume: [] },
    },
    {
      queryKey: ["dollarVolume", month.value],
      queryFn: () => httpGrowthMetrics(month.value, "dollarVolume"),
      initialData: { dollarVolume: [] },
    },
  ]);

  const usersCount = results[0].data?.usersCount;
  const transactionCount = results[1].data?.transactionCount;
  const nairaVolume = results[2].data?.nairaVolume;
  const dollarVolume = results[3].data?.dollarVolume;

  const handleChangeMonth = (e: any) => {
    const selectedMonth = months.find((m) => m.label === e.target.value);
    if (selectedMonth) setMonth(selectedMonth);
  };

  const monthsTitle = () => [
    months.find((a) => a.value === month.value)?.label || "",
    (month.value === "1"
      ? "Dec"
      : months.find((a) => a.value === String(Number(month.value) - 1))
          ?.label) || "",
    (month.value === "1"
      ? "Nov"
      : month.value === "2"
      ? "Dec"
      : months.find((a) => a.value === String(Number(month.value) - 2))
          ?.label) || "",
  ];

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">Growth Metrics</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="mt-5">
          <span className="mb-2 text-sm">Month:</span>
          <Select
            className="w-5/12 md:w-3/12"
            data={months.map((month) => month.label)}
            value={month.label}
            placeholder="Select month"
            onChange={handleChangeMonth}
          />
        </div>

        <div className="flex flex-col gap-8 mt-10 md:flex-row">
          <div className="w-6/12">
            <h3 className="mb-1 ml-4 text-lg font-medium">Users Count</h3>
            <MetricsTable
              header={monthsTitle()}
              title="Metric"
              sidebar={data?.usersCount?.titles}
              data={[...data?.usersCount?.data?.map((d) => usersCount[d])]}
              isLoading={results[0].isLoading}
            />
          </div>
          <div className="w-6/12">
            <h3 className="mb-1 ml-4 text-lg font-medium">Transaction Count</h3>
            <MetricsTable
              header={monthsTitle()}
              title="Feature"
              sidebar={data?.transactionCount?.titles}
              data={[
                ...data?.transactionCount?.data?.map(
                  (d) => transactionCount[d]
                ),
              ]}
              isLoading={results[1].isLoading}
            />
          </div>
        </div>

        <div className="flex flex-col gap-8 mt-10 md:flex-row">
          <div className="w-6/12">
            <h3 className="mb-1 ml-4 text-lg font-medium">
              Volume ( Naira Features)
            </h3>
            <MetricsTable
              header={monthsTitle()}
              title="Feature"
              sidebar={data?.nairaVolume?.titles}
              data={[...data?.nairaVolume?.data?.map((d) => nairaVolume[d])]}
              isLoading={results[2].isLoading}
            />
          </div>
          <div className="w-6/12">
            <h3 className="mb-1 ml-4 text-lg font-medium">
              Volume (Dollar Features)
            </h3>
            <MetricsTable
              header={monthsTitle()}
              title="Feature"
              sidebar={data?.dollarVolume?.titles}
              data={[...data?.dollarVolume?.data?.map((d) => dollarVolume[d])]}
              isLoading={results[3].isLoading}
            />
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default GrowthMetrics;
