import {
  httpUserBalances,
  httpUserRevenue,
  httpUserTransactionAnalytics,
} from "api/admin/user";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Filter from "components/ui/Filter";
import Search from "components/ui/Search";
import Toggle from "components/ui/Toggle";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import currency, { cryptoFormat, moneyFormat } from "utilities/currency";
import { cryptos } from "utilities/wallets";

const filters: any = {
  Revenue: [
    {
      label: "General Analytics",
      value: "general",
    },
    {
      label: "Buy & Sell profits",
      value: "buy-sell",
    },
  ],
  Transactions: [
    {
      label: "Naira",
      value: "naira",
    },
    {
      label: "Buy & Sell",
      value: "buy-sell",
    },
    {
      label: "Send & Receive",
      value: "send-receive",
    },
    {
      label: "Swap",
      value: "swap",
    },
    {
      label: "Recurring Buy",
      value: "recurring",
    },
  ],
  "User Activities": [],
};

const UserAnalytics: React.FC = () => {
  const params = useParams();
  const userID = params.user as string;
  const navigate = useNavigate();
  const [value, setValue] = useState("User Activities");
  const [filter, setFilter] = useState(
    filters[value]?.[0]?.value || "buy-sell"
  );
  const [date, setDate] = useState(DateTime.local().toISODate());

  const {
    data: { user },
  } = useQuery(["userBalances", userID], () => httpUserBalances(userID), {
    initialData: {
      user: {},
      rate: {
        buy: {
          usd: 0,
        },
        sell: {
          usd: 0,
        },
      },
      balances: { naira: 0 },
    },
    enabled: value === "User Activities",
    onError: (err) => {
      navigate(-1);
    },
  });

  const { data: revenueData } = useQuery(
    ["userRevenue", userID, filter, date],
    () => httpUserRevenue(userID, filter, date),
    {
      initialData: {},
      enabled: value === "Revenue",
      onError: (err) => {
        navigate(-1);
      },
    }
  );

  const { data: transactionData } = useQuery(
    ["userTransactionAnalytics", userID, filter, date],
    () => httpUserTransactionAnalytics(userID, filter, date),
    {
      initialData: {},
      enabled: value === "Transactions",
      onError: (err) => {
        navigate(-1);
      },
    }
  );

  useEffect(() => {
    setFilter(filters[value]?.[0]?.value || "buy-sell");
  }, [value]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left mb-10">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">@{user?.userName} analytics</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="my-10">
          <Toggle
            data={["User Activities", "Transactions", "Revenue"]}
            value={value}
            setValue={setValue}
          />
        </div>

        {value === "Transactions" || value === "Revenue" ? (
          <div>
            <span className="text-sm">Filter by:</span>
            <Filter data={filters[value]} setValue={setFilter} value={filter} />
            <span className="ml-5 text-sm">Filter by date:</span>
            <input
              type="date"
              className="rounded border-gray-400 py-1 ml-2"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        ) : null}

        <div className="mt-20 flex flex-wrap  gap-y-14">
          {value === "User Activities" && (
            <div className="bg-black rounded-lg text-white p-8 px-14">
              <h4 className="text-lg font-normal mb-2">Status: Active</h4>
              <p className="text-sm font-light">
                Last seen:{" "}
                {DateTime.fromISO(user?.lastLogin || user?.regDate).toFormat(
                  "DD"
                )}{" "}
              </p>
            </div>
          )}

          {value === "Transactions" ? (
            <>
              {filter === "naira" && (
                <>
                  <div className="rounded-lg w-4/12">
                    <h4 className="text-sm font-light mb-2">
                      Total Naira deposit
                    </h4>
                    <p className="text-2xl font-medium">
                      <span className="text-2xl">{currency("ngn")}</span>{" "}
                      {`${moneyFormat(
                        transactionData?.["totalDeposits"] || 0
                      )}`}
                    </p>
                  </div>
                  <div className="rounded-lg w-4/12">
                    <h4 className="text-sm font-light mb-2">
                      Total Naira withdrawal
                    </h4>
                    <p className="text-2xl font-medium">
                      <span className="text-2xl">{currency("ngn")}</span>{" "}
                      {`${moneyFormat(
                        transactionData?.["totalWithdrawals"] || 0
                      )}`}
                    </p>
                  </div>
                  <div className="rounded-lg w-4/12">
                    <h4 className="text-sm font-light mb-2">
                      Total Naira earned
                    </h4>
                    <p className="text-2xl font-medium">
                      <span className="text-2xl">{currency("ngn")}</span>{" "}
                      {`${moneyFormat(
                        transactionData?.["totalEarnings"] || 0
                      )}`}
                    </p>
                  </div>
                  <div className="rounded-lg  w-4/12">
                    <h4 className="text-sm font-light mb-2">No of deposits</h4>
                    <p className="text-2xl font-medium">
                      {`${transactionData?.["deposit"] || 0}`}
                    </p>
                  </div>
                  <div className="rounded-lg  w-4/12">
                    <h4 className="text-sm font-light mb-2">
                      No of withdrawals
                    </h4>
                    <p className="text-2xl font-medium">
                      {`${transactionData?.["withdrawal"] || 0}`}
                    </p>
                  </div>
                  <div className="rounded-lg  w-4/12">
                    <h4 className="text-sm font-light mb-2">No of earnings</h4>
                    <p className="text-2xl font-medium">
                      {`${transactionData?.["earning"] || 0}`}
                    </p>
                  </div>
                </>
              )}
              {filter === "buy-sell" && (
                <div>
                  <div className="flex gap-5 -mt-10">
                    <div className="bg-orange-100 text-left rounded-lg py-5 px-10 pr-20">
                      <h4 className="text-sm font-light mb-1">Total Bought</h4>
                      <p className="text-lg font-medium">
                        {currency("usd")}{" "}
                        {`${moneyFormat(transactionData?.["totalBuy"] || 0)}`}
                      </p>
                    </div>
                    <div className="bg-orange-100 text-left rounded-lg py-5 px-10 pr-20">
                      <h4 className="text-sm font-light mb-1">Total Sold</h4>
                      <p className="text-lg font-medium">
                        {currency("usd")}{" "}
                        {`${moneyFormat(transactionData?.["totalSell"] || 0)}`}
                      </p>
                    </div>
                  </div>

                  <div className="mt-10 w-full items-center flex flex-wrap">
                    {cryptos.map((crypto) => (
                      <div
                        key={crypto}
                        className="odd:border-r odd:border-r-orange-100 px-10 border-b-2 border-b-orange-50 py-8"
                      >
                        <div className="flex justify-between w-full items-center">
                          <h4 className="font-bold">{crypto.toUpperCase()}</h4>
                          <i className="fa fa-bitcoin bg-orange-50 p-1 rounded"></i>
                        </div>
                        <div className="flex flex-wrap gap-y-5">
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              Total Buy
                            </h4>
                            <p className="text-xl font-medium">
                              {`${cryptoFormat(
                                transactionData?.[crypto]?.["totalBuy"] || 0
                              )}`}{" "}
                              {crypto.toUpperCase()}
                            </p>
                          </div>
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              Total Sell
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${cryptoFormat(
                                transactionData?.[crypto]?.["totalSell"] || 0
                              )}`}{" "}
                              {crypto.toUpperCase()}
                            </p>
                          </div>
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              No of Buy
                            </h4>
                            <p className="text-xl font-medium">
                              {`${transactionData?.[crypto]?.["buy"] || 0}`}{" "}
                            </p>
                          </div>
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              No of Sell
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${
                                transactionData?.[crypto]?.["sell"] || 0
                              }`}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {filter === "send-receive" && (
                <div>
                  <div className="flex gap-5 -mt-10">
                    <div className="bg-orange-100 text-left rounded-lg py-5 px-10 pr-20">
                      <h4 className="text-sm font-light mb-1">Total Sent</h4>
                      <p className="text-lg font-medium">
                        {currency("usd")}{" "}
                        {`${moneyFormat(transactionData?.["totalSend"] || 0)}`}
                      </p>
                    </div>
                    <div className="bg-orange-100 text-left rounded-lg py-5 px-10 pr-20">
                      <h4 className="text-sm font-light mb-1">
                        Total Received
                      </h4>
                      <p className="text-lg font-medium">
                        {currency("usd")}{" "}
                        {`${moneyFormat(
                          transactionData?.["totalReceive"] || 0
                        )}`}
                      </p>
                    </div>
                  </div>

                  <div className="mt-10 w-full items-center flex flex-wrap">
                    {cryptos.map((crypto) => (
                      <div className="odd:border-r odd:border-r-orange-100 px-10 border-b-2 border-b-orange-50 py-8">
                        <div className="flex justify-between w-full items-center">
                          <h4 className="font-bold">{crypto.toUpperCase()}</h4>
                          <i className="fa fa-bitcoin bg-orange-50 p-1 rounded"></i>
                        </div>
                        <div className="flex flex-wrap gap-y-5">
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              Total Sent
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${cryptoFormat(
                                transactionData?.[crypto]?.["totalSend"] || 0
                              )}`}{" "}
                              {crypto.toUpperCase()}
                            </p>
                          </div>
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              Total Receive
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${cryptoFormat(
                                transactionData?.[crypto]?.["totalReceive"] || 0
                              )}`}{" "}
                              {crypto.toUpperCase()}
                            </p>
                          </div>
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              No of Send
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${
                                transactionData?.[crypto]?.["send"] || 0
                              }`}{" "}
                            </p>
                          </div>
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              No of Receive
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${
                                transactionData?.[crypto]?.["receive"] || 0
                              }`}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {filter === "swap" && (
                <div>
                  <div className="flex gap-5 -mt-10">
                    <div className="bg-orange-100 text-left rounded-lg py-5 px-10 pr-20">
                      <h4 className="text-sm font-light mb-1">Total Swapped</h4>
                      <p className="text-lg font-medium">
                        {currency("usd")}{" "}
                        {`${moneyFormat(transactionData?.["totalSwap"] || 0)}`}
                      </p>
                    </div>
                  </div>

                  <div className="mt-10 w-full items-center flex flex-wrap">
                    {cryptos.map((crypto) => (
                      <div
                        key={crypto}
                        className="odd:border-r odd:border-r-orange-100 px-10 border-b-2 border-b-orange-50 py-8"
                      >
                        <div className="flex justify-between w-full items-center">
                          <h4 className="font-bold">{crypto.toUpperCase()}</h4>
                          <i className="fa fa-bitcoin bg-orange-50 p-1 rounded"></i>
                        </div>
                        <div className="flex flex-wrap gap-y-5">
                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              Total Swapped
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${cryptoFormat(
                                transactionData?.[crypto]?.["totalSwap"] || 0
                              )}`}{" "}
                              {crypto.toUpperCase()}
                            </p>
                          </div>

                          <div className="rounded-lg w-6/12">
                            <h4 className="text-sm font-light mb-2">
                              No of Swap
                            </h4>
                            <p className="text-xl font-medium">
                              {" "}
                              {`${
                                transactionData?.[crypto]?.["swap"] || 0
                              }`}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {filter === "recurring" && (
                <div>
                  <div className="mt-2 w-full items-center flex flex-wrap">
                    {cryptos.map((crypto) => (
                      <div className="odd:border-r odd:border-r-orange-100 px-10 border-b-2 border-b-orange-50 py-8">
                        <div className="flex justify-between w-full items-center">
                          <h4 className="font-bold">{crypto.toUpperCase()}</h4>
                          <i className="fa fa-bitcoin bg-orange-50 p-1 rounded"></i>
                        </div>
                        <div className="flex flex-wrap gap-y-5">
                          <div className="rounded-lg w-7/12">
                            <h4 className="text-sm font-light mb-2">
                              Total Buy
                            </h4>
                            <p className="text-xl font-medium">
                              {currency("ngn")}{" "}
                              {`${moneyFormat(
                                transactionData?.[crypto]?.["totalBuy"] || 0
                              )}`}
                            </p>
                          </div>

                          <div className="rounded-lg w-7/12">
                            <h4 className="text-sm font-light mb-2">
                              No of Buy
                            </h4>
                            <p className="text-xl font-medium">
                              {`${transactionData?.[crypto]?.["buy"] || 0}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : null}

          {value === "Revenue" &&
            (filter === "general" ? (
              <>
                <div className="rounded-lg w-4/12">
                  <h4 className="text-sm font-light mb-2">Total deposit fee</h4>
                  <p className="text-2xl font-medium">
                    <span className="text-2xl">{currency("ngn")}</span>{" "}
                    {`${moneyFormat(revenueData?.deposit || 0)}`}
                  </p>
                </div>
                <div className="rounded-lg w-4/12">
                  <h4 className="text-sm font-light mb-2">
                    Total withdrawal fee
                  </h4>
                  <p className="text-2xl font-medium">
                    <span className="text-2xl">{currency("ngn")}</span>{" "}
                    {`${moneyFormat(revenueData?.withdrawal || 0)}`}
                  </p>
                </div>
                <div className="rounded-lg w-4/12">
                  <h4 className="text-sm font-light mb-2">Total swap fee</h4>
                  <p className="text-2xl font-medium">
                    <span className="text-2xl">{currency("ngn")}</span>{" "}
                    {`${moneyFormat(revenueData?.swap || 0)}`}
                  </p>
                </div>
              </>
            ) : (
              <div>
                <div className="mt-2 w-full items-center flex flex-wrap">
                  {cryptos?.map((crypto, index) => (
                    <div
                      key={crypto}
                      className="odd:border-r odd:border-r-orange-100 px-8 border-b-2 border-b-orange-50 py-8"
                    >
                      <div className="flex justify-between w-full items-center">
                        <h4 className="font-bold">{crypto.toUpperCase()}</h4>
                        <i className="fa fa-bitcoin bg-orange-50 p-1 rounded"></i>
                      </div>
                      <div className="flex flex-wrap gap-y-5">
                        <div className="rounded-lg w-6/12">
                          <h4 className="text-sm font-light mb-2">
                            Average Buy Price
                          </h4>
                          <p className="text-xl font-medium">
                            {currency("ngn")}{" "}
                            {`${moneyFormat(
                              revenueData?.[crypto]?.["averageBuy"] || 0
                            )}`}
                          </p>
                        </div>
                        <div className="rounded-lg w-6/12">
                          <h4 className="text-sm font-light mb-2">
                            Average Sell Price
                          </h4>
                          <p className="text-xl font-medium">
                            {currency("ngn")}{" "}
                            {`${moneyFormat(
                              revenueData?.[crypto]?.["averageSell"] || 0
                            )}`}
                          </p>
                        </div>
                        <div className="rounded-lg w-6/12">
                          <h4 className="text-sm font-light mb-2">Profit</h4>
                          <p className="text-xl font-medium">
                            {currency("ngn")}{" "}
                            {`${moneyFormat(
                              revenueData?.[crypto]?.["profit"] || 0
                            )}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </Main>
    </Container>
  );
};
export default UserAnalytics;
