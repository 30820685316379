import { httpUserBalances } from "api/admin/user";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import currency, { cryptoFormat, moneyFormat } from "utilities/currency";
import { cryptos } from "utilities/wallets";
import UserInfo from "./UserInfo";

const UserBalances: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userID = params.user as string;

  const {
    data: { user, balances, rate },
  } = useQuery(["userBalances", userID], () => httpUserBalances(userID), {
    initialData: {
      user: {},
      rate: {
        buy: {
          usd: 0,
        },
        sell: {
          usd: 0,
        },
      },
      balances: { naira: 0, totalRecurring: 0 },
    },
    onError: (err) => {
      navigate(-1);
    },
  });

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">@{user?.userName} balances</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="flex gap-8 mt-14">
          <div className="w-7/12">
            <div className="flex flex-wrap w-full gap-5">
              <div className="w-5/12 px-3 py-5 border border-gray-500 rounded-lg">
                <div className="flex justify-between text-sm">
                  <span className="font-light">Naira</span>
                  <i className="p-1 bg-orange-100 rounded fa fa-bitcoin"></i>
                </div>
                <p className="my-1 text-xl font-medium">
                  {currency("ngn")} {moneyFormat(balances.naira)}
                </p>
                <p className="text-sm font-light">
                  {currency("usd")}{" "}
                  {moneyFormat(balances.naira / rate.sell.usd)}
                </p>
              </div>
              {cryptos.map((crypto) => (
                <div
                  key={crypto}
                  className="w-5/12 px-3 py-5 border border-gray-500 rounded-lg"
                >
                  <div className="flex justify-between text-sm">
                    <span className="font-light">{crypto?.toUpperCase()}</span>
                    <i className="p-1 bg-orange-100 rounded fa fa-bitcoin"></i>
                  </div>
                  <p className="my-1 text-xl font-medium">
                    {cryptoFormat(balances[crypto]?.amount)}{" "}
                    {crypto?.toUpperCase()}
                  </p>
                  <p className="text-sm font-light">
                    {currency("usd")}{" "}
                    {moneyFormat(balances[crypto]?.naira / rate.sell.usd)}{" "}
                    <span className="ml-5">
                      {currency("ngn")} {moneyFormat(balances[crypto]?.naira)}
                    </span>
                  </p>
                </div>
              ))}
              <div className="w-5/12 px-3 py-5 border border-gray-500 rounded-lg">
                <div className="flex justify-between text-sm">
                  <span className="font-light">Recurring</span>
                  <i className="p-1 bg-orange-100 rounded fa fa-bitcoin"></i>
                </div>
                <p className="my-1 text-xl font-medium">
                  {currency("ngn")} {moneyFormat(balances.totalRecurring)}
                </p>
                <p className="text-sm font-light">
                  {currency("usd")}{" "}
                  {moneyFormat(balances.totalRecurring / rate.sell.usd)}
                </p>
              </div>
            </div>
          </div>

          <UserInfo user={user} />
        </div>
      </Main>
    </Container>
  );
};
export default UserBalances;
