import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import useGetVendor from "hooks/useGetVendor";
import { useNavigate, useParams } from "react-router-dom";
import { vendorRoutes } from "routes/routes";

const Withdraw: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { vendor } = useGetVendor();

  const TYPE = "withdrawals";
  const processedBanks =
    vendor.banks && vendor.banks[TYPE] ? vendor.banks[TYPE] : [];

  const onSelect = (bank: any) => {
    navigate(`${vendorRoutes.withdraw}/amount`, { state: { bank } });
  };

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav
          title="Choose Bank Account"
          subtitle={"Where will you like to receive your funds"}
        />

        <div className="mt-10">
          {processedBanks.map((bank: any, index: number) => (
            <Bank
              onClick={() => onSelect(bank)}
              bank={bank}
              key={String(index)}
            />
          ))}
          <div
            onClick={() =>
              navigate(`${vendorRoutes.paymentMethods}/add`, {
                state: { type: params.type },
              })
            }
            className="flex cursor-pointer mt-10"
          >
            <span>
              <i className="fa fa-trash text-orange-300"></i>
            </span>
            <div className="font-light ml-4">Add a new bank</div>
          </div>
        </div>
      </Main>
    </Container>
  );
};

const Bank: React.FC<{
  bank: {
    accountNumber: string;
    bankName: string;
    accountName: string;
    default?: boolean;
  };
  onClick?: () => void;
  onDelete?: (bank: any) => void;
}> = ({ bank, onClick, onDelete }) => (
  <div
    onClick={onClick}
    className={`bg-white shadow-lg rounded-lg flex py-5 px-3 gap-x-4 items-center justify-between mb-5 cursor-pointer`}
  >
    <div className="w-2/12">
      <i className="fa fa-building-columns text-orange-300"></i>
    </div>
    <div className="text-left w-8/12">
      <h4>{bank.accountNumber}</h4>
      <p className="text-black text-opacity-50 text-sm">{bank.bankName}</p>
      <p className="text-black text-opacity-50 text-xs">{bank.accountName}</p>
    </div>
    <div className="w-2/12"></div>
  </div>
);

export default Withdraw;
