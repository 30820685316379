import { currentVendorAuthToken } from "helpers/storage";
import React from "react";
import { io } from "socket.io-client";

export const socket = io(process.env.REACT_APP_SOCKET_URL || "", {
  //   transports: ["websocket", "polling", "flashsocket"],
  // @ts-ignore
  query: `vendorToken=${currentVendorAuthToken()}&platform=${"vendor"}`,
  autoConnect: false,
});
export const SocketContext = React.createContext<any>(null);
