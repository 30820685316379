import TransactionItem from "components/ui/TransactionItem";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

interface UserInfoProps {
  user: any;
  toggle?: (user: any) => void;
  refetch?: () => void;
}

const UserInfo: React.FC<UserInfoProps> = ({ user, toggle, refetch }) => {
  const verify = async () => {
    post(`admin/users/verify/${user?.id}`, {})
      .then(async () => {
        showSuccess("user verified successfully");
        refetch?.();
      })
      .catch(() => {
        showDanger("something went wrong");
      });
  };

  const modifyUser = async (action: string) => {
    post(`admin/users/modifyUser/${user?.id}`, { action })
      .then(async () => {
        showSuccess("user modified successfully");
        toggle?.(user);
        refetch?.();
      })
      .catch(() => {
        showDanger("something went wrong");
      });
  };

  return (
    <div className="w-5/12 px-8 py-10 rounded-lg bg-orange-50">
      <h4 className="mb-5">@{user?.userName}</h4>

      <TransactionItem title="Username" value={`@${user?.userName}`} />
      <TransactionItem
        title="Registered full Name"
        value={`${user?.firstName} ${user?.lastName}`}
      />
      <TransactionItem title="e-mail" value={user?.email} />
      <TransactionItem title="Phone Number" value={user?.phoneNumber} />
      {user?.collectionAccount && (
        <TransactionItem
          title="Collection Account ID"
          value={user?.collectionAccount?.accountID}
        />
      )}
      {user?.collectionAccount && (
        <TransactionItem
          title="Collection Account Number"
          value={user?.collectionAccount?.accountNumber}
        />
      )}
      <TransactionItem
        title="Verification Level"
        value={`Level ${user?.level}`}
        sub={
          user?.level < 2 ? (
            <button
              onClick={verify}
              className={`px-2 mt-2 bg-black text-white rounded-full cursor-pointer`}
            >
              Verify User
            </button>
          ) : null
        }
      />
      <TransactionItem
        title="Date registered"
        value={DateTime.fromISO(user?.regDate).toFormat("D")}
      />

      <TransactionItem
        title="Withdrawal Status"
        value={``}
        sub={
          <button
            onClick={() => modifyUser("withdraw")}
            className={`px-4 py-0.5 mt-2 bg-black text-white rounded-full cursor-pointer`}
          >
            {`${user?.canWithdraw ? "Disable" : "Enable"} Withdrawal`}
          </button>
        }
      />

      <TransactionItem
        title="Deposit Status"
        value={``}
        sub={
          <button
            onClick={() => modifyUser("deposit")}
            className={`px-4 py-0.5 mt-2 bg-black text-white rounded-full cursor-pointer`}
          >
            {`${user?.canDeposit ? "Disable" : "Enable"} Deposit`}
          </button>
        }
      />

      <TransactionItem
        title="User Status"
        value={``}
        sub={
          <button
            onClick={() => modifyUser("ban")}
            className={`px-4 py-0.5 mt-2 bg-black text-white rounded-full cursor-pointer`}
          >
            {`${user?.level < 1 ? "Unban" : "Ban"} User`}
          </button>
        }
      />

      <div className="flex flex-wrap mt-10 gap-x-20 gap-y-10">
        <Link to={`${adminRoutes.balances}/user/${user.id}`}>
          <span
            className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
          >
            Balances
          </span>
        </Link>
        <Link to={`${adminRoutes.transactions}/user/${user.id}`}>
          <span
            className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
          >
            Transactions
          </span>
        </Link>
        <Link to={`${adminRoutes.analytics}/user/${user.id}`}>
          <span
            className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
          >
            Analytics
          </span>
        </Link>
        <Link to={`${adminRoutes.explorer}/user/${user.id}`}>
          <span
            className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
          >
            Addresses Explorer
          </span>
        </Link>
      </div>
    </div>
  );
};

export default UserInfo;
