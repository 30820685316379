import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import { VendorLinkItem } from "components/misc";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";

const PaymentMethods: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav title="Payment Method" />

        <div className="mt-10">
          <VendorLinkItem
            onClick={() =>
              navigate(`${vendorRoutes.paymentMethods}/withdrawals`)
            }
            title="Payment method for withdrawals"
          />
          <VendorLinkItem
            onClick={() => navigate(`${vendorRoutes.paymentMethods}/deposits`)}
            title="Payment method for user deposits"
          />
        </div>
      </Main>
    </Container>
  );
};

export default PaymentMethods;
