export const publicRoutes = {
  home: "/",
  login: "/login",
  register: "/register",
  features: "/features",
  pricing: "/pricing",
  privacyPolicy: "/privacy-policy",
  terms: "/terms",
  externalTrade: "/external-trade",
  recurringTrade: "/recurring-trade",
  helpcenter: "/helpcenter",
  refer: "/ref",
  about: "/about",
  verify: "/verifyAccount",
  mobileVerify: "/mobile-verify",
  blog: "/blog",
  resetPassword: "/reset-password",
  requestAccountDeletion: "/request-account-deletion",
  confirmAccountDeletion: "/confirm-account-deletion",
};

export const adminRoutes = {
  dashboard: "/",
  logout: "/logout",
  login: "/login",
  register: "/register",
  users: "/users",
  user: "/users/:id",
  merchants: "/merchants",
  transactions: "/transactions",
  balances: "/balances",
  rates: "/rates",
  creditUser: "/credit-user",
  analytics: "/analytics",
  deposits: "/deposits",
  explorer: "/explorer",
  testSms: "/test-sms",
  notifyAll: "/notify-all",
  paymentMerchant: "/payment-merchant",
  btcBalance: "/btc-balance",
  frozenTronAccounts: "/frozen-tron-accounts",
};

export const vendorRoutes = {
  dashboard: "/",
  logout: "/logout",
  login: "/login",
  register: "/register",
  transactions: "/transactions",
  withdrawals: "/withdrawals",
  deposits: "/deposits",
  paymentMethods: "/payment-methods",
  profile: "/profile",
  withdraw: "/withdraw",
};

export const supportRoutes = {
  dashboard: "/",
  logout: "/logout",
  login: "/login",
  register: "/register",
  users: "/users",
  user: "/users/:id",
  transactions: "/transactions",
  balances: "/balances",
  rates: "/rates",
  analytics: "/analytics",
  deposits: "/deposits",
  explorer: "/explorer",
};

export const paymentRoutes = {
  dashboard: "/",
};

export const subdomainRoutes = {
  default: "",
  www: "www",
  vendor: "merchant",
  admin: "admin",
  support: "support",
};
