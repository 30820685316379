import { httpAcquisitions } from "api/admin/user";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import Toggle from "components/ui/Toggle";
import TransactionItem from "components/ui/TransactionItem";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const filters = [
  {
    label: "Users",
    value: "all",
  },
  {
    label: "New Users",
    value: "new",
  },
  {
    label: "Active Users",
    value: "active",
  },
  {
    label: "Logged-in Users",
    value: "logged",
  },
];

const Acquisitions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState<any>(null);

  const [filter, setFilter] = useState(filters[0].label);
  const [date, setDate] = useState(DateTime.local().toISODate());

  const filterValue = filters.find((f) => f.label === filter)?.value || "all";

  const {
    data: { users },
  } = useQuery(
    ["acquisitions", filterValue, date],
    () => httpAcquisitions(filterValue, date),
    {
      initialData: {
        users: [],
      },
    }
  );

  const toggleOpen = (u: any) => {
    if (isOpen && u.email === user?.email) return setIsOpen(false);

    setUser(u);
    return setIsOpen(true);
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">Acquisition Analytics</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="my-10">
          <Toggle
            data={filters.map((filter) => filter.label)}
            value={filter}
            setValue={setFilter}
          />
        </div>

        <div>
          <span className="ml-5 text-sm">Filter by date:</span>
          <input
            type="date"
            className="rounded border-gray-400 py-1 ml-2"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="mt-14 flex gap-8">
          <div className="w-7/12">
            <Table
              header={["Username", "e-mail", "Phone number"]}
              data={[
                ...users?.map((user: any) => [
                  user.userName,
                  user.email,
                  user.phoneNumber,
                  () => toggleOpen(user),
                ]),
              ]}
            />
          </div>
          {isOpen && (
            <div className="w-5/12 bg-orange-50 rounded-lg py-10 px-8">
              <h4 className="mb-5">@{user?.userName}</h4>

              <TransactionItem title="Username" value={`@${user?.userName}`} />
              <TransactionItem
                title="Registered full Name"
                value={`${user?.firstName} ${user?.lastName}`}
              />
              <TransactionItem title="e-mail" value={user?.email} />
              <TransactionItem title="Phone Number" value={user?.phoneNumber} />
              <TransactionItem
                title="Verification Level"
                value={`Level ${user?.level}`}
              />
              <TransactionItem
                title="Date registered"
                value={DateTime.fromISO(user?.regDate).toFormat("D")}
              />

              <div className="mt-10 flex gap-x-20 gap-y-10 flex-wrap">
                <Link to={`${adminRoutes.balances}/user/${user.id}`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Balances
                  </span>
                </Link>
                <Link to={`${adminRoutes.transactions}/user/${user.id}`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Transactions
                  </span>
                </Link>
                <Link to={`${adminRoutes.analytics}/user/${user.id}`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Analytics
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </Main>
    </Container>
  );
};
export default Acquisitions;
