interface ToggleItemProps {
  data: string[];
  value: string;
  setValue: (value: string) => void;
}

const sizes: any = {
  2: "w-[50%]",
  3: "w-[33.33%]",
};
const VendorToggle: React.FC<ToggleItemProps> = ({ data, value, setValue }) => {
  return (
    <div
      className={`bg-[#F1EEEA] flex justify-between gap-x-5 rounded-lg p-1.5`}
    >
      {data.map((item, index) => (
        <span
          onClick={() => setValue(item)}
          key={item}
          className={`flex items-center justify-center h-12 ${
            sizes[data.length]
          } ${
            item === value ? "bg-white text-black" : ""
          } rounded-lg cursor-pointer`}
        >
          {item}
        </span>
      ))}
    </div>
  );
};

export default VendorToggle;
