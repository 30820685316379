import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import useGetVendor from "hooks/useGetVendor";
import { useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import { showSuccess } from "utilities/misc";
import { patch } from "utilities/network";
import { paymentMethodTypes } from "utilities/payment";

const SelectBank: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { vendor, refetchVendor } = useGetVendor();

  const selectedType = paymentMethodTypes.find((t) => t.name === params.type);

  useLayoutEffect(() => {
    if (!params.type || !selectedType) {
      return navigate(vendorRoutes.paymentMethods);
    }
  }, [params?.type, navigate, selectedType]);

  const processedBanks =
    vendor.banks && selectedType && vendor.banks[selectedType.name]
      ? vendor.banks[selectedType.name]
      : [];

  const onDelete = async (bank: any) => {
    let res = await patch(`vendor/profile/removeBank`, {
      accountNumber: bank.accountNumber,
      paymentMethodType: params?.type,
    });

    if (res?.data?.data?.success) {
      showSuccess(res?.data?.message || "Bank removed successfully");
      refetchVendor();
    }
  };

  const onSelectForDefault = async (bank: any) => {
    if (bank.default) return;
    let res = await patch(`vendor/profile/selectBank`, {
      accountNumber: bank.accountNumber,
      paymentMethodType: params?.type,
    });

    if (res?.data?.data?.success) {
      showSuccess(res?.data?.message || "Default bank selected successfully");
      refetchVendor();
    }
  };

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav title="Payment Method" subtitle={selectedType?.title} />

        <div className="mt-10">
          {processedBanks.map((bank: any, index: number) => (
            <Bank
              onDelete={onDelete}
              onClick={() => onSelectForDefault(bank)}
              bank={bank}
              key={String(index)}
            />
          ))}
          <div
            onClick={() =>
              navigate(`${vendorRoutes.paymentMethods}/add`, {
                state: { type: params.type },
              })
            }
            className="flex cursor-pointer mt-10"
          >
            <span>
              <i className="fa fa-trash text-orange-300"></i>
            </span>
            <div className="font-light ml-4">Add a new bank</div>
          </div>
        </div>
      </Main>
    </Container>
  );
};

const Bank: React.FC<{
  bank: {
    accountNumber: string;
    bankName: string;
    accountName: string;
    default?: boolean;
  };
  onClick?: () => void;
  onDelete?: (bank: any) => void;
}> = ({ bank, onClick, onDelete }) => (
  <div
    onClick={onClick}
    className={`${
      bank.default ? "bg-orange-100 bg-opacity-30" : "bg-white"
    } shadow-lg rounded-lg flex py-5 px-3 gap-x-4 items-center justify-between mb-5 cursor-pointer`}
  >
    <div className="w-2/12">
      <i className="fa fa-building-columns text-orange-300"></i>
    </div>
    <div className="text-left w-8/12">
      <h4>{bank.accountNumber}</h4>
      <p className="text-black text-opacity-50 text-sm">{bank.bankName}</p>
      <p className="text-black text-opacity-50 text-xs">{bank.accountName}</p>
    </div>
    <div className="w-2/12">
      <button onClick={() => (onDelete ? onDelete(bank) : {})}>
        <i className="fa fa-trash text-orange-300"></i>
      </button>
    </div>
  </div>
);

export default SelectBank;
