import { httpFetchUsers } from "api/admin/user";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import { Select } from "components/misc";
import Pagination from "components/ui/Pagination";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import { useState } from "react";
import { useQuery } from "react-query";
import UserInfo from "./UserInfo";

const Users: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("A-Z");
  const limit = 50;

  const {
    data: { users, total, page },
    refetch,
  } = useQuery(
    ["users", currentPage, order],
    () => httpFetchUsers(limit, currentPage, order),
    {
      initialData: {
        users: [],
        total: 0,
        page: 1,
      },
    }
  );

  const toggleOpen = (u: any) => {
    if (isOpen && u.email === user?.email) return setIsOpen(false);

    setUser(u);
    return setIsOpen(true);
  };

  const changeSort = (e: any) => {
    setOrder(e?.target?.value);
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-2xl">Users</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <p className="mt-5 mb-1 text-lg font-bold">Sort</p>
        <Select
          onChange={changeSort}
          value={order}
          data={["A-Z", "Z-A", "Newest to Oldest", "Oldest to Newest"]}
          className="w-full md:w-7/12"
        />

        <div className="flex gap-8 mt-14">
          <div className="w-7/12">
            <Table
              header={["Username", "e-mail", "Phone number"]}
              data={[
                ...users?.map((user: any) => [
                  user.userName,
                  user.email,
                  user.phoneNumber,
                  () => toggleOpen(user),
                ]),
              ]}
            />

            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              limit={limit}
              page={page}
              total={total}
            />
          </div>
          {isOpen && (
            <UserInfo user={user} toggle={toggleOpen} refetch={refetch} />
          )}
        </div>
      </Main>
    </Container>
  );
};
export default Users;
