import axios from "axios";
import Footer, { FooterCTA } from "components/layout/Footer";
import Header from "components/layout/Header";
import Spinner from "components/ui/Spinner";
import { useReducer } from "react";
import { Helmet } from "react-helmet-async";
import { handleError, showDanger } from "utilities/misc";

interface State {
  email?: string;
  userName?: string;
  fullName?: string;
  loading?: boolean;
  successful?: boolean;
}

const RequestDeleteAccount: React.FC = () => {
  const [data, dispatch] = useReducer(
    (state: State, newState: State) => ({ ...state, ...newState }),
    {
      email: "",
      userName: "",
      fullName: "",
      loading: false,
      successful: false,
    }
  );
  const { email, userName, fullName, loading, successful } = data;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    dispatch({ [name]: value });
  };

  const handleLoading = (loading: boolean) => {
    dispatch({ loading });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { email, userName, fullName } = data;
    if (email === "" || userName === "" || fullName === "") {
      return showDanger("Please fill all fields");
    }

    handleLoading(true);
    try {
      const resp = await axios.post(
        `https://bitoshi.africa/requestAccountDeletion`,
        {
          email,
          userName,
        }
      );
      handleLoading(false);

      if (resp?.data?.data?.success) {
        dispatch({ successful: true });
      } else {
        showDanger("Invalid provided details");
      }
    } catch (error: any) {
      handleLoading(false);
      handleError(error);
    }
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>Bitoshi Africa | Request Delete Account</title>
        <meta
          name="description"
          content="Bitoshi.africa is here to simplify access to cryptocurrencies; learn more about us."
        />
        <meta
          name="keywords"
          content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto"
        />
      </Helmet>

      <div className="flex flex-col items-center justify-center w-full pt-40 pb-12 bg-black bg-center bg-no-repeat bg-cover md:pb-14 bg-pattern">
        <h2 className="mb-4 text-4xl font-bold text-white">Delete Account</h2>
      </div>

      <div className="flex flex-col mt-10 item-center">
        <div className="self-center w-full px-3 py-8 bg-white md:max-w-lg">
          {successful ? (
            <div>
              <h2 className="text-lg font-semibold">Please check your email</h2>
              <p className="mt-2 text-left">
                We've just sent a confirmation link to your email. Please click
                on the link to confirm the deletion of your account.
              </p>
            </div>
          ) : (
            <>
              <div className="mb-5">
                <label className="block mb-2 text-sm text-left text-black">
                  Registered Full Name
                </label>
                <input
                  name="fullName"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg"
                  value={fullName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-5">
                <label className="block mb-2 text-sm text-left text-black">
                  Registered Email Address
                </label>
                <input
                  name="email"
                  type={"email"}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg"
                  value={email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-5">
                <label className="block mb-2 text-sm text-left text-black">
                  Username
                </label>
                <input
                  name="userName"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg"
                  value={userName}
                  onChange={handleChange}
                />
              </div>

              <p className="text-sm font-light text-left">
                <span className="font-medium">NOTE:</span> This action is
                irreversible. All your data will be cleared. Before you proceed,
                make sure you withdraw all your funds.
              </p>

              <button
                disabled={loading}
                onClick={handleSubmit}
                className="w-full py-2.5 mt-5 text-white bg-black rounded-lg"
              >
                {loading ? <Spinner /> : "Continue"}
              </button>
            </>
          )}
        </div>
      </div>
      <FooterCTA />
      <Footer />
    </>
  );
};

export default RequestDeleteAccount;
