import Footer, { FooterCTA } from "components/layout/Footer";
import Header from "components/layout/Header";
import { Helmet } from "react-helmet-async";

const External: React.FC = () => (
  <>
    <Header />
    <Helmet>
      <title>Bitoshi Africa | Buy & Sell crypto the easy way</title>
      <meta
        name="description"
        content="Trade cryptocurrencies with other crypto businesses and wallets using Bitoshi Africa"
      />
      <meta
        name="keywords"
        content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto"
      />
    </Helmet>

    <div className="flex flex-col items-center px-5 py-24 pt-40 bg-no-repeat bg-cover bg-pattern md:pt-44">
      <h2 className="text-3xl font-bold text-white md:text-4xl">
        External Buy/Sell
      </h2>
      <p className="my-5 font-thin text-white text-opacity-80">
        Perform external trades with other crypto business{" "}
        <br className="hidden md:inline" /> partners and crypto wallets
        seamlessly.
      </p>
      <a
        href="/#landing"
        className="flex items-center justify-center w-full px-4 py-3 mt-5 mb-5 text-black bg-white rounded-lg md:py-2 md:w-auto md:px-14 md:mt-2"
      >
        Trade Now
      </a>
    </div>

    <div className="relative flex flex-col items-center justify-center px-5 mt-28 md:px-20 lg:gap-x-40 gap-y-20 md:flex-row">
      <div className="bg-[#FFF8F0] md:max-w-[500px] px-5 md:px-14 pb-5 text-left">
        <img
          className="-mt-10"
          src="/images/fast-delivery.png"
          alt="Fast Delivery"
        />
        <h3 className="mt-10 mb-5 text-xl font-bold">Fast Delivery</h3>
        <p className="font-light">
          Enjoy fast and swift delivery from and to any external crypto wallet.
        </p>
      </div>

      <div className="bg-[#FFF8F0] md:max-w-[500px] px-5 md:px-14 pb-5 text-left">
        <img
          className="-mt-10"
          src="/images/fixed-rates.png"
          alt="Fixed Rates"
        />
        <h3 className="mt-10 mb-5 text-xl font-bold">Fixed Rates</h3>
        <p className="font-light">
          Worry less about the fluctuations of crypto exchange rate once your
          transactions have been initiated.
        </p>
      </div>
    </div>

    <div className="px-5 mt-14 md:mt-28 md:px-40">
      <h3 className="mb-10 text-3xl font-bold text-center">
        External Buy/Sell <br className="md:hidden" /> Use Case
      </h3>

      <div className="relative mt-12 lg:px-40">
        <img
          className="absolute left-[50%] translate-x-1/2 top-14 h-[80%] hidden md:block"
          src="/images/horizontal-line.svg"
          alt="Horizontal line"
        />
        <div className="flex flex-col items-center justify-center mb-14 md:flex-row md:gap-x-32">
          <div className="flex justify-start md:w-1/2">
            <img
              className="bg-[#E0DCFF] md:w-[300px]"
              src="/images/a-crypto-seller.png"
              alt="A crypto seller"
            />
          </div>
          <div className="relative mt-8 ml-8 text-left md:ml-0 md:w-1/2">
            <img
              className="absolute top-1 -left-8 md:hidden"
              src="/images/hr-small.svg"
              alt="Horizontal line"
            />
            <h3 className="text-xl font-bold md:text-2xl">A Crypto Seller?</h3>
            <p className="mt-2 font-thin">
              Are you looking to sell high volatile cryptocurrencies like
              Bitcoin and make profit from the spreads, but you have fear of the
              market? This product is for you. As a crypto merchant you don’t
              have to own Bitcoin before you sell to your Business partners. On
              the Bitoshi app, you can buy Bitcoin and other cryptocurrencies
              directly to your partner’s wallet with your Naira wallet. This way
              you don’t have to hodl until you get a buyer.
            </p>
          </div>
        </div>

        <div className="flex flex-col-reverse items-center justify-center mb-14 md:flex-row md:gap-x-32">
          <div className="relative mt-8 ml-8 text-left md:ml-0 md:w-1/2">
            <img
              className="absolute top-1 -left-8 md:hidden"
              src="/images/hr-small.svg"
              alt="Horizontal line"
            />
            <h3 className="text-xl font-bold md:text-2xl">A Crypto Buyer?</h3>
            <p className="mt-2 font-thin">
              Buy directly from your business partners using external crypto
              wallets and don’t worry about being active to sell immediately.
            </p>
            <p className="mt-2 font-thin">
              With the Bitoshi External Sell feature, incoming transactions are
              sold off instantly and settlements are made to your Naira wallet
              immediately for withdrawals.
            </p>
          </div>
          <div className="flex justify-end md:w-1/2">
            <img
              className="bg-[#BFD8FF] md:w-[300px]"
              src="/images/a-crypto-buyer.png"
              alt="A crypto Buyer"
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mb-10 md:flex-row md:gap-x-32">
          <div className="flex justify-start md:w-1/2">
            <img
              className="bg-[#B2EFE4] md:w-[300px]"
              src="/images/make-online-payments.png"
              alt="Make Online Payments with Crypto"
            />
          </div>
          <div className="relative mt-8 ml-8 text-left md:ml-0 md:w-1/2">
            <img
              className="absolute top-1 -left-8 md:hidden"
              src="/images/hr-small.svg"
              alt="Horizontal line"
            />
            <h3 className="text-xl font-bold md:text-2xl">
              Make Online Payments with Crypto
            </h3>
            <p className="mt-2 font-thin">
               Do you need to pay for services online or make a quick purchase
              with Crypto but you don’t own any yet? No worries, you can
              transfer immediately from your Naira wallet to the Crypto payment
              wallet. Save yourself some time and stay protected from Crypto
              crashes.
            </p>
          </div>
        </div>
      </div>
    </div>

    <FooterCTA />
    <Footer />
  </>
);

export default External;
