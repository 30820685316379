interface IPagination {
  setCurrentPage: (p: any) => void;
  currentPage: number;
  page: number;
  limit: number;
  total: number;
}
const Pagination: React.FC<IPagination> = ({
  setCurrentPage,
  currentPage,
  limit,
  page,
  total,
}) => {
  const hasMore = Math.ceil(total / limit) > page;

  const next = () => {
    if (hasMore) setCurrentPage((p: number) => p + 1);
  };

  const previous = () => {
    if (currentPage > 1) setCurrentPage((p: number) => p - 1);
  };

  return (
    <div className="mt-5 mb-5">
      Pages:{" "}
      <span
        onClick={previous}
        className={`ml-3 font-bold mr-3  ${
          currentPage > 1 ? "text-orange-400 cursor-pointer" : ""
        }`}
      >
        Previous
      </span>{" "}
      <span
        onClick={next}
        className={`font-bold ${
          hasMore ? "text-orange-400 cursor-pointer" : ""
        }`}
      >
        Next
      </span>
    </div>
  );
};

export default Pagination;
