import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import { Input } from "components/misc";
import VButton from "components/ui/VButton";

const VerifyAdd: React.FC = () => (
  <Container isVendor>
    <VendorSidebar />

    <Main isVendor>
      <VendorNav
        title="Add a new bank"
        subtitle={
          <>
            <p>Enter the OTP sent to your mail.</p>
            <p>Can't find email? Check your spam folder</p>
          </>
        }
      />

      <div className="mt-10">
        <Input value="" placeholder="" label="" />

        <VButton className="bg-orange-300 py-4 mt-10 text-white w-full">
          Proceed
        </VButton>
        <VButton className="border border-orange-200 py-4 text-orange-300 mt-4 bg-transparent w-full">
          Resend
        </VButton>
      </div>
    </Main>
  </Container>
);

export default VerifyAdd;
