import { get } from "utilities/network";

export const httpGetTransaction = (id: any) => {
  return get(`vendor/transactions/get/${id}`);
};
export const httpFetchTransactions = (
  channel: "user" | "admin",
  type: "withdrawal" | "deposit" | "all",
  status: "pending" | "successful" | "cancelled" | "all"
) => {
  return get(
    `vendor/transactions/fetch?channel=${channel}&type=${type}&status=${status}`
  );
};
