import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import useAsync from "hooks/useAsync";
import useGetVendor from "hooks/useGetVendor";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import { showDanger, showSuccess } from "utilities/misc";
import { logout, patch } from "utilities/network";

const Profile: React.FC = () => {
  const { vendor } = useGetVendor();
  const [telegramID, setTelegramID] = useState(vendor?.telegramID || "");

  useEffect(() => {
    vendor?.telegramID && setTelegramID(vendor?.telegramID);
  }, [vendor?.telegramID]);

  const navigate = useNavigate();

  const { execute: saveT, value } = useAsync(
    () =>
      patch(`vendor/profile/saveTelegramID`, {
        telegramID,
      }),
    false
  );

  const handleSubmit = () => {
    if (telegramID.length < 4) {
      return showDanger("Telegram id should not be empty");
    }
    saveT();
  };

  useEffect(() => {
    if (value?.data?.data?.success) {
      showSuccess(value?.data?.message || "Telegram ID updated successfully");
      navigate(vendorRoutes.profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav title="Profile Settings" />

        <div className="mt-10">
          <ProfileItem
            title="Full name"
            value={`${vendor.firstName || ""} ${vendor.lastName || ""}`}
          />
          <ProfileItem title="Username" value={`@${vendor.userName}`} />
          <ProfileItem title="Phone number" value={vendor.phoneNumber} />
          <ProfileItem title="Email address" value={vendor.email} />
          <div className="relative flex items-start justify-between pb-2 mb-5 border-b cursor-pointer border-b-gray-200">
            <div className="w-full text-left">
              <h4 className="mb-1 text-sm font-light text-black text-opacity-50">
                Telegram ID
              </h4>
              <input
                value={telegramID}
                onChange={(e) => setTelegramID(e.target.value)}
                className="w-[90%] py-2 px-2 bg-gray-200 rounded-lg"
              />
            </div>
            <button
              onClick={handleSubmit}
              className="absolute right-0 px-5 py-2 text-white bg-black rounded bottom-2"
            >
              Save
            </button>
          </div>
          <ProfileItem
            title=""
            onClick={() => navigate(vendorRoutes.paymentMethods)}
            value="Payment Methods"
            isLink
          />
          <ProfileItem
            title=""
            onClick={() => navigate(`${vendorRoutes.profile}/change-pin`)}
            value="Change Pin"
            isLink
          />
          <ProfileItem
            title=""
            onClick={() => navigate(`${vendorRoutes.profile}/reset-password`)}
            value="Reset Password"
            isLink
          />

          <button
            className="block w-full px-5 py-3 mt-10 text-sm font-normal text-left text-red-500 border border-red-500 rounded-lg"
            onClick={logout}
          >
            <i className={`fa fa-arrow-right-from-bracket mr-4`}></i>
            Logout
          </button>
        </div>
      </Main>
    </Container>
  );
};

const ProfileItem: React.FC<{
  title: string;
  value: string;
  onClick?: () => void;
  isLink?: boolean;
}> = ({ title, value, onClick, isLink }) => (
  <div
    onClick={onClick}
    className="relative flex items-start justify-between pb-2 mb-5 border-b cursor-pointer border-b-gray-200"
  >
    <div className="text-left">
      <h4 className="mb-1 text-sm font-light text-black text-opacity-50">
        {title}
      </h4>
      <p className="font-light">{value}</p>
    </div>
    {isLink && (
      <i className="absolute right-0 text-sm text-orange-300 fa fa-chevron-right bottom-2"></i>
    )}
  </div>
);

export default Profile;
