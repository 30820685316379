import { httpMerchantTransactionAnalytics } from "api/admin/merchant";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import Toggle from "components/ui/Toggle";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import currency, { moneyFormat } from "utilities/currency";

const MerchantAnalytics: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userID = params.user as string;

  const [date, setDate] = useState(DateTime.local().toISODate());

  const [value, setValue] = useState("User Activities");

  const { data: transactionData } = useQuery(
    ["vendorTransactionAnalytics", userID, date],
    () => httpMerchantTransactionAnalytics(userID, date),
    {
      initialData: {},

      onError: (err) => {
        navigate(-1);
      },
    }
  );

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left mb-10">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">
            @{transactionData?.vendor?.userName} analytics
          </h3>
          <div className="w-3/6">
            <Search type="merchant" />
          </div>
        </div>

        <div className="my-10">
          <Toggle
            data={["User Activities", "Transactions"]}
            value={value}
            setValue={setValue}
          />
        </div>

        {value === "Transactions" && (
          <div>
            <span className="ml-5 text-sm">Filter by date:</span>
            <input
              type="date"
              className="rounded border-gray-400 py-1 ml-2"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        )}

        <div className="mt-20 flex flex-wrap  gap-y-14">
          {value === "User Activities" && (
            <div className="bg-black rounded-lg text-white p-8 px-14">
              <h4 className="text-lg font-normal mb-2">Status: Active</h4>
              <p className="text-sm font-light">
                Last seen:{" "}
                {DateTime.fromISO(
                  transactionData?.vendor?.lastLogin ||
                    transactionData?.vendor?.regDate
                ).toFormat("DD")}{" "}
              </p>
            </div>
          )}

          {value === "Transactions" && (
            <>
              <div className="rounded-lg w-4/12">
                <h4 className="text-sm font-light mb-2">Total Naira deposit</h4>
                <p className="text-2xl font-medium">
                  <span className="text-2xl">{currency("ngn")}</span>{" "}
                  {`${moneyFormat(transactionData?.["totalDeposits"] || 0)}`}
                </p>
              </div>
              <div className="rounded-lg w-4/12">
                <h4 className="text-sm font-light mb-2">
                  Total Naira withdrawal
                </h4>
                <p className="text-2xl font-medium">
                  <span className="text-2xl">{currency("ngn")}</span>{" "}
                  {`${moneyFormat(transactionData?.["totalWithdrawals"] || 0)}`}
                </p>
              </div>
              <div className="rounded-lg w-4/12">
                <h4 className="text-sm font-light mb-2">Total Naira earned</h4>
                <p className="text-2xl font-medium">
                  <span className="text-2xl">{currency("ngn")}</span>{" "}
                  {`${moneyFormat(transactionData?.["totalEarnings"] || 0)}`}
                </p>
              </div>
              <div className="rounded-lg  w-4/12">
                <h4 className="text-sm font-light mb-2">No of deposits</h4>
                <p className="text-2xl font-medium">
                  {`${transactionData?.["deposit"] || 0}`}
                </p>
              </div>
              <div className="rounded-lg  w-4/12">
                <h4 className="text-sm font-light mb-2">No of withdrawals</h4>
                <p className="text-2xl font-medium">
                  {`${transactionData?.["withdrawal"] || 0}`}
                </p>
              </div>
              <div className="rounded-lg  w-4/12">
                <h4 className="text-sm font-light mb-2">No of earnings</h4>
                <p className="text-2xl font-medium">
                  {`${transactionData?.["earning"] || 0}`}
                </p>
              </div>
            </>
          )}
        </div>
      </Main>
    </Container>
  );
};
export default MerchantAnalytics;
