import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const NotifyAll: React.FC = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const {
    execute: notifyNow,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/users/notifyAll`, {
        title,
        message,
      }),
    false
  );

  const handleSubmit = () => {
    if (!title || title.length < 3) return showDanger("Enter valid title");
    if (!message || message.length < 3)
      return showDanger("Enter valid message");

    notifyNow();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message || "sent successfully");
    }
  }, [value]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <h2 className="text-xl">Test Push Notification</h2>
        <div className="flex gap-8 mt-14">
          <div className="flex">
            <div className="gap-5 ">
              <div className="relative">
                <label>Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                />
              </div>

              <div className="relative mt-5">
                <label>Message</label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                />
              </div>

              <VButton
                status={status}
                onClick={handleSubmit}
                className="w-5/12 px-20 py-3 mt-5 font-light text-white bg-black rounded-lg"
              >
                Send
              </VButton>
            </div>
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default NotifyAll;
