import { httpGetTransaction } from "api/vendor/transactions";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const WithdrawalItem: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<any>({});

  const {
    execute: confirmTransaction,
    status,
    value,
  } = useAsync(
    () => post(`vendor/transactions/confirm/${params?.item}`, {}),
    false
  );

  const handleSubmit = () => {
    confirmTransaction();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess("Transaction completed");
      navigate(vendorRoutes.dashboard);
    }
  }, [value, navigate]);

  useEffect(() => {
    if (!params.item) {
      return navigate(vendorRoutes.withdrawals);
    }
  }, [params?.item, navigate]);

  const getTransaction = async () => {
    let tr = await httpGetTransaction(params?.item);
    if (!tr.transaction) {
      return navigate(vendorRoutes.withdrawals);
    }
    setTransaction(tr.transaction);
  };

  useEffect(() => {
    getTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const {
  //   data: { transaction },
  // } = useQuery(
  //   ["withdrawalTransaction", params?.item],
  //   () => httpGetTransaction(params?.item),
  //   {
  //     initialData: { transaction: {} },
  //     onSuccess: (data) => {
  //       if (!data.transaction) {
  //         return navigate(vendorRoutes.withdrawals);
  //       }
  //     },
  //   }
  // );

  const bank =
    transaction?.address && transaction?.address !== "sell.bitoshi.africa"
      ? JSON.parse(transaction.address)
      : {};

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav
          title={`${currency("ngn")}${moneyFormat(transaction.amount)}`}
        />

        <div className="mt-10">
          <div className="px-5 py-5 mt-5 border border-black rounded-lg">
            <h4 className="mb-5 text-left">Account Details</h4>

            <div className="flex justify-between mb-5">
              <h5 className="text-sm font-light text-black text-opacity-60">
                Bank Name
              </h5>
              <p className="text-sm">{bank.bankName}</p>
            </div>
            <div className="flex justify-between mb-5">
              <h5 className="text-sm font-light text-black text-opacity-60">
                Account Number
              </h5>
              <p className="text-sm">{bank.accountNumber}</p>
            </div>
            <div className="flex justify-between mb-5">
              <h5 className="text-sm font-light text-black text-opacity-60">
                Account Name
              </h5>
              <p className="">{bank.accountName}</p>
            </div>
            <div className="flex justify-between mb-5">
              <h5 className="text-sm font-light text-black text-opacity-60">
                Full Name
              </h5>
              <p className="">{`${transaction.user?.firstName} ${transaction.user?.lastName}`}</p>
            </div>
            <div className="flex justify-between mb-5">
              <h5 className="text-sm font-light text-black text-opacity-60">
                Username
              </h5>
              <p className="">{transaction.user?.userName}</p>
            </div>
          </div>
          <div className="mt-10">
            <VButton
              onClick={handleSubmit}
              status={status}
              className="w-full py-4 text-white bg-orange-300"
            >
              Payment Received
            </VButton>

            {/* <VButton className="w-full py-4 mt-4 text-orange-300 bg-transparent border border-orange-200">
              Cancel
            </VButton> */}
          </div>
        </div>
      </Main>
    </Container>
  );
};

export default WithdrawalItem;
