import Footer, { FooterCTA } from "components/layout/Footer";
import Header from "components/layout/Header";
import { Whys } from "components/misc";
import { Helmet } from "react-helmet-async";

const Recurring: React.FC = () => (
  <>
    <Header />
    <Helmet>
      <title>Bitoshi Africa | Automatically trade crypto in 3 easy steps</title>
      <meta
        name="description"
        content="Automate your cryptocurrency purchases all in 3 easy steps."
      />
      <meta
        name="keywords"
        content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto | Save with Bitoshi"
      />
    </Helmet>
    <div className="flex flex-col items-center bg-no-repeat bg-cover px-5 pt-40 py-28 md:py-44 bg-pattern">
      <h2 className="text-3xl font-bold text-white md:text-4xl">
        Recurring Buy
      </h2>
      <p className="my-5 font-thin text-white text-opacity-90">
        Effectively lower the amount you pay for crypto investments and minimize
        price risks.
      </p>
      <a
        href="/#landing"
        className="flex items-center justify-center w-full px-4 py-3 mt-5 mb-5 text-black bg-white rounded-lg md:py-2 md:w-auto md:px-14 md:mt-2"
      >
        Start Investing
      </a>
    </div>

    <div className="relative flex flex-col items-center justify-center px-5 mt-20 md:px-20 lg:gap-x-40 gap-y-20 md:flex-row">
      <div className="bg-[#FFFBF7] md:w-[450px] px-5 md:px-10 pb-5 text-left">
        <img
          className="-mt-10"
          src="/images/flexible-plan.png"
          alt="Flexible plan"
        />
        <h3 className="mt-10 mb-5 text-xl font-bold">Flexible Plan</h3>
        <p className="font-light">
          You have complete control to choose only what works for you and your
          financial goals. You can also make changes to your plan anytime.
        </p>
      </div>

      <div className="bg-[#FFFBF7] md:w-[450px] px-5 md:px-10 pb-5 text-left">
        <img
          className="-mt-10 bg-[#E0DCFF]"
          src="/images/automated-plan.png"
          alt="Automated Plan"
        />
        <h3 className="mt-10 mb-5 text-xl font-bold">Automated Plan</h3>
        <p className="font-light">
          Once you have finished setting up your plan. All your purchases are
          made automatically from start to finish.
        </p>
      </div>
    </div>

    <div className="px-5 mt-14 md:mt-32 md:px-40">
      <h3 className="mb-5 text-3xl font-bold text-center">Start a Plan</h3>

      <div className="flex flex-col-reverse mt-10 md:grid md:grid-cols-2 md:gap-x-40">
        <Whys
          title="Set a Funding Amount"
          subtitle={
            <>
              <p>
                Type the amount you want to use in funding this plan
                periodically, you can start with as low as N500.
              </p>
            </>
          }
          start
          top={
            <>
              <span className="w-6 h-6 font-medium text-green-800 bg-green-100 rounded-full">
                01
              </span>
            </>
          }
        />
        <img
          className=""
          src="/images/start-plan-1.png"
          alt="Set funding amount"
        />
      </div>

      <div className="mt-10 md:grid md:grid-cols-2 md:gap-x-40">
        <img
          className=""
          src="/images/start-plan-2.png"
          alt="Select Frequency"
        />
        <Whys
          title="Select Frequency and end date"
          subtitle={
            <>
              <p>
                Choose between daily, weekly and monthly. Pick a date to end
                your plan.
              </p>
            </>
          }
          start
          top={
            <>
              <span className="w-6 h-6 font-medium text-pink-800 bg-pink-100 rounded-full">
                02
              </span>
            </>
          }
        />
      </div>
      <div className="flex flex-col-reverse mt-10 md:grid md:grid-cols-2 md:gap-x-40">
        <Whys
          title="Name your plan and choose a payment method"
          subtitle={
            <>
              <p>
                Give your plan a cool name and choose how you want to fund your
                plan.
              </p>
            </>
          }
          start
          top={
            <>
              <span className="w-6 h-6 font-medium text-blue-500 bg-blue-100 rounded-full">
                03
              </span>
            </>
          }
        />
        <img className="" src="/images/start-plan-3.png" alt="Name your plan" />
      </div>
      <div className="mt-10 md:grid md:grid-cols-2 md:gap-x-40">
        <img
          className=""
          src="/images/start-plan-4.png"
          alt="Set a target amount"
        />
        <Whys
          title="Set a target amount"
          subtitle={
            <>
              <p>Set what goal you want to achieve for this plan.</p>
            </>
          }
          start
          top={
            <>
              <span className="w-6 h-6 font-medium text-orange-600 bg-orange-100 rounded-full">
                04
              </span>
            </>
          }
        />
      </div>
    </div>

    <FooterCTA />
    <Footer />
  </>
);

export default Recurring;
