import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import { Input } from "components/misc";
import { useState } from "react";

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <Header />
      <div className="flex flex-col items-center px-5 pt-40 bg-no-repeat bg-cover py-28 md:py-44 bg-pattern">
        <h2 className="text-3xl font-bold text-white md:text-4xl">
          Reset Password
        </h2>
      </div>

      <div className="flex flex-col items-center justify-center px-5 py-10">
        <div className="md:w-[500px] w-full">
          <div className="mt-10">
            <div className="mb-7">
              <Input
                type={"email"}
                className="w-full py-2"
                placeholder="Enter your email"
                label=" Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly
              />
            </div>

            <Input
              value={password}
              className="w-full py-2"
              placeholder=""
              label="New Password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            {!password && (
              <p className="-mt-2 text-sm font-light text-left text-black text-opacity-60">
                Should contain at least 8 characters, at least 1 uppercase
                letter, 1 lowercase letter, 1 number.
              </p>
            )}

            <button className="flex items-center justify-center w-full py-3 mt-10 mb-5 text-white bg-black rounded-lg">
              Reset Password
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ResetPassword;
