interface ToggleItemProps {
  data: string[];
  value: string;
  setValue: (value: string) => void;
}
const Toggle: React.FC<ToggleItemProps> = ({ data, value, setValue }) => {
  return (
    <div className={``}>
      {data.map((item, index) => (
        <span
          onClick={() => setValue(item)}
          key={item}
          className={`px-5 py-1.5 ${
            item === value ? "bg-black text-white" : "bg-gray-200"
          } rounded-full mr-3 cursor-pointer`}
        >
          {item}
        </span>
      ))}
    </div>
  );
};

export default Toggle;
