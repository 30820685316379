import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const TestSms: React.FC = () => {
  const [phone, setPhone] = useState("");
  const [reference, setReference] = useState("");
  const [code, setCode] = useState("");

  const {
    execute: testNow,
    status,
    value,
  } = useAsync(
    () =>
      post(`auth/testSms`, {
        phoneNumber: phone,
      }),
    false
  );

  const {
    execute: verifyNow,
    status: status2,
    value: value2,
  } = useAsync(
    () =>
      post(`auth/verifyTestSms`, {
        code,
        reference,
      }),
    false
  );

  const handleSubmit = () => {
    if (!phone || phone.length < 10)
      return showDanger("Enter valid phone number");
    testNow();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message);
      setReference(value?.data?.reference);
    }
  }, [value]);

  const handleSubmit2 = () => {
    if (!code || code.length < 6) return showDanger("Enter valid code");
    verifyNow();
  };

  useEffect(() => {
    if (value2?.data?.success) {
      showSuccess(value2?.message);
      setPhone("");
      setCode("");
      setReference("");
    }
  }, [value2]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left">
        <h2 className="text-xl">Test SMS Verification</h2>
        <div className="mt-14 flex gap-8">
          <div className="flex">
            <div className="flex w-1/2 flex-col gap-5">
              <div className="relative">
                <label>Phone Number</label>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="rounded border-gray-400 w-full py-3 mt-1 pl-8"
                />
              </div>

              <VButton
                status={status}
                onClick={handleSubmit}
                className="bg-black text-white rounded-lg px-20 py-3 font-light mt-5 w-5/12"
              >
                Test
              </VButton>
            </div>

            {reference && (
              <div className="ml-10 flex w-1/2 gap-5 flex-col">
                <div className="relative">
                  <label>Enter Code</label>
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="rounded border-gray-400 w-full py-3 mt-1 pl-8"
                  />
                </div>

                <VButton
                  status={status2}
                  onClick={handleSubmit2}
                  className="bg-black text-white rounded-lg px-20 py-3 font-light mt-5 w-5/12"
                >
                  Verify
                </VButton>
              </div>
            )}
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default TestSms;
