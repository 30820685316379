import { httpFetchTransactions } from "api/vendor/transactions";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import Transaction, { Itransaction } from "components/ui/Transaction";
import VendorToggle from "components/ui/VendorToggle";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { vendorRoutes } from "routes/routes";

const lists = [
  {
    name: "user",
    title: "User",
  },
  {
    name: "admin",
    title: " Admin",
  },
];

const WithdrawalList: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState("Pending");

  const selectedType = lists.find((t) => t.name === params.list);

  useEffect(() => {
    if (!params.list || !selectedType) {
      return navigate(vendorRoutes.withdrawals);
    }
  }, [params?.list, navigate, selectedType]);

  const TRANSACTION_TYPE = "deposit";
  const STATUS = status === "Pending" ? "pending" : "successful";
  const CHANNEL = selectedType?.name as any;

  const {
    data: { transactions },
  } = useQuery(
    ["depositsTransactions", CHANNEL, TRANSACTION_TYPE, STATUS],
    () => httpFetchTransactions(CHANNEL, TRANSACTION_TYPE, STATUS),
    {
      initialData: { transactions: [] },
    }
  );

  return (
    <Container isVendor>
      <VendorSidebar />
      <Main isVendor>
        <VendorNav title="Withdrawals" subtitle={selectedType?.title} />

        <div className="mt-10">
          <VendorToggle
            value={status}
            setValue={setStatus}
            data={["Pending", "Completed"]}
          />
        </div>
        <div className="text-left mt-8">
          {transactions.map((trn: Itransaction) => (
            <Transaction transaction={trn} key={trn.id} />
          ))}
        </div>
      </Main>
    </Container>
  );
};

export default WithdrawalList;
