import { httpFetchMerchants } from "api/admin/merchant";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Table from "components/ui/Table";
import { useQuery } from "react-query";
import { showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const AssignVendors: React.FC = () => {
  const {
    data: { merchants },
    refetch,
  } = useQuery("merchants", httpFetchMerchants, {
    initialData: {
      merchants: [],
    },
  });

  const handleAssign = (status: boolean, id: number) => {
    post(`admin/merchants/assign/${id}`, {
      status,
    })
      .then((data: any) => {
        if (data?.data?.success) {
          showSuccess(data?.data?.message);
          refetch();
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">Assign Merchants</h3>
          <div className="w-3/6"></div>
        </div>

        <div className="mt-14 flex gap-8">
          <div className="w-full">
            <Table
              header={["Assigned", "Username", "e-mail"]}
              data={[
                ...merchants?.map((user: any) => [
                  <input
                    className="cursor-pointer"
                    type={"checkbox"}
                    checked={user?.online}
                    onChange={(e) => handleAssign(e.target.checked, user?.id)}
                  />,
                  user.userName,
                  user.email,
                  null,
                ]),
              ]}
            />
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default AssignVendors;
