import { get, post } from "utilities/network";

export const httpFetchUsers = (
  limit: number,
  page: number,
  order: string = "A-Z"
) => {
  return get(`admin/users?limit=${limit}&page=${page}&order=${order}`);
};

export const httpUserBalances = (userID: string) => {
  return get(`admin/balances/user/${userID}`);
};

export const httpUserWallet = (userID: string) => {
  return get(`admin/users/userWallet/${userID}`);
};

export const httpUserRevenue = (
  id: string,
  type: string,
  date: Date | string
) => {
  return get(`admin/users/revenue/${id}?date=${date}&type=${type}`);
};

export const httpUserTransactionAnalytics = (
  id: string,
  type: string,
  date: Date | string
) => {
  return get(
    `admin/users/transactionAnalytics/${id}?date=${date}&type=${type}`
  );
};

export const httpAcquisitions = (type: string, date: Date | string) => {
  return get(`admin/analytics/acquisitions?date=${date}&type=${type}`);
};

export const httpUserTransactions = (
  id: string,
  currencyType: string,
  transactionType: string,
  place: string,
  limit?: number,
  page?: number
) => {
  return get(
    `admin/users/transactions/${id}?transactionType=${transactionType}&place=${place}&currencyType=${currencyType}&limit=${limit}&page=${page}`
  );
};

export const httpUSearchUser = (userName: string) => {
  return get(`admin/users/search?search=${userName}`);
};

export const httpGrowthMetrics = (month: string, type: string) => {
  return get(`admin/analytics/growth-metrics?month=${month}&type=${type}`);
};

export const httpGenerateAdminMarket = (
  crypto: string,
  fromDate: string,
  toDate: string
) => {
  return post(`admin/transactions/generateAdminMarket`, {
    crypto,
    fromDate,
    toDate,
  });
};

export const httpSaveAdminMarket = (
  crypto: string,
  fromDate: string,
  toDate: string
) => {
  return post(`admin/transactions/saveAdminMarket`, {
    crypto,
    fromDate,
    toDate,
  });
};
