import { httpAnalytics } from "api/admin/stats";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import Toggle from "components/ui/Toggle";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import currency, { moneyFormat } from "utilities/currency";
import { cryptos } from "utilities/wallets";

const RevenueAnalytics: React.FC = () => {
  const type = "revenue";

  const [value, setValue] = useState("General Analytics");

  const [date, setDate] = useState(DateTime.local().toISODate());

  const manageWhich = value
    .replace("General Analytics", "general")
    .replace("Buy & sell profits", "market");

  const {
    data: { deposit, withdrawal, swap, ...other },
  } = useQuery(
    ["deposits", type, value, date],
    () => httpAnalytics(type, manageWhich, date),
    {
      initialData: {
        deposit: 0,
        withdrawal: 0,
        swap: 0,
      },
    }
  );

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-5 mx-5 text-left mb-10">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">Revenue analytics</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="my-10">
          <Toggle
            data={["General Analytics", "Buy & sell profits"]}
            value={value}
            setValue={setValue}
          />
        </div>

        <div>
          <span className="ml-5 text-sm">Filter by date:</span>
          <input
            type="date"
            className="rounded border-gray-400 py-1 ml-2"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="mt-20 flex flex-wrap  gap-y-14">
          {value === "General Analytics" && (
            <>
              <div className="rounded-lg w-4/12">
                <h4 className="text-sm font-light mb-2">Total deposit fee</h4>
                <p className="text-2xl font-medium">
                  <span className="text-2xl">{currency("ngn")}</span>{" "}
                  {`${moneyFormat(deposit)}`}
                </p>
              </div>
              <div className="rounded-lg w-4/12">
                <h4 className="text-sm font-light mb-2">
                  Total withdrawal fee
                </h4>
                <p className="text-2xl font-medium">
                  <span className="text-2xl">{currency("ngn")}</span>{" "}
                  {`${moneyFormat(withdrawal)}`}
                </p>
              </div>
              <div className="rounded-lg w-4/12">
                <h4 className="text-sm font-light mb-2">Total swap fee</h4>
                <p className="text-2xl font-medium">
                  <span className="text-2xl">{currency("ngn")}</span>{" "}
                  {`${moneyFormat(swap)}`}
                </p>
              </div>
            </>
          )}

          {value === "Buy & sell profits" && (
            <div>
              <div className="mt-2 w-full items-center flex flex-wrap">
                {cryptos?.map((crypto, index) => (
                  <div
                    key={crypto}
                    className="odd:border-r odd:border-r-orange-100 px-8 border-b-2 border-b-orange-50 py-8"
                  >
                    <div className="flex justify-between w-full items-center">
                      <h4 className="font-bold">{crypto.toUpperCase()}</h4>
                      <i className="fa fa-bitcoin bg-orange-50 p-1 rounded"></i>
                    </div>
                    <div className="flex flex-wrap gap-y-5">
                      <div className="rounded-lg w-6/12">
                        <h4 className="text-sm font-light mb-2">
                          Average Buy Price
                        </h4>
                        <p className="text-xl font-medium">
                          {currency("ngn")}{" "}
                          {`${moneyFormat(
                            other?.[crypto]?.["averageBuy"] || 0
                          )}`}
                        </p>
                      </div>
                      <div className="rounded-lg w-6/12">
                        <h4 className="text-sm font-light mb-2">
                          Average Sell Price
                        </h4>
                        <p className="text-xl font-medium">
                          {currency("ngn")}{" "}
                          {`${moneyFormat(
                            other?.[crypto]?.["averageSell"] || 0
                          )}`}
                        </p>
                      </div>
                      <div className="rounded-lg w-6/12">
                        <h4 className="text-sm font-light mb-2">Profit</h4>
                        <p className="text-xl font-medium">
                          {currency("ngn")}{" "}
                          {`${moneyFormat(other?.[crypto]?.["profit"] || 0)}`}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Main>
    </Container>
  );
};
export default RevenueAnalytics;
