import { useState } from "react";

interface ToggleItemProps {
  title: string;
  content: string | React.ReactNode;
  isHelp?: boolean;
}
const ToggleItem: React.FC<ToggleItemProps> = ({ title, content, isHelp }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`pb-2 mb-4 w-full`}>
      <h3
        onClick={() => setIsOpen((p) => !p)}
        className={`border-b text-left cursor-pointer ${
          isHelp ? "" : "font-light"
        } flex justify-between items-center ${
          isHelp ? "border-b-gray-400" : "border-b-gray-400"
        } pb-2`}
      >
        <span> {title}</span>
        {isHelp && (
          <span
            className={`text-xs fa fa-chevron-${isOpen ? "up" : "down"}`}
          ></span>
        )}
      </h3>
      <div
        className={`text-left font-light pt-3 mb-4 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        {content}
      </div>
    </div>
  );
};

export default ToggleItem;
