import axios from "axios";
import Footer, { FooterCTA } from "components/layout/Footer";
import Header from "components/layout/Header";
import Spinner from "components/ui/Spinner";
import { useEffect, useReducer } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { handleError, showDanger } from "utilities/misc";

interface State {
  loading?: boolean;
  successful?: boolean;
}

const ConfirmDeleteAccount: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const email = params.get("email");
  const [data, dispatch] = useReducer(
    (state: State, newState: State) => ({ ...state, ...newState }),
    {
      loading: false,
      successful: false,
    }
  );
  const { loading, successful } = data;

  const handleLoading = (loading: boolean) => {
    dispatch({ loading });
  };

  const handleSubmit = async () => {
    handleLoading(true);
    try {
      const resp = await axios.get(
        `https://bitoshi.africa/confirm-account-deletion/${token}?email=${email}`
      );
      handleLoading(false);

      if (resp?.data?.data?.success) {
        dispatch({ successful: true });
      } else {
        showDanger("Invalid provided details");
      }
    } catch (error: any) {
      handleLoading(false);
      handleError(error);
    }
  };

  useEffect(
    () => {
      if (token && email) {
        handleSubmit();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Header />
      <Helmet>
        <title>Bitoshi Africa | Confirm Delete Account</title>
        <meta
          name="description"
          content="Bitoshi.africa is here to simplify access to cryptocurrencies; learn more about us."
        />
        <meta
          name="keywords"
          content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto"
        />
      </Helmet>

      <div className="flex flex-col items-center justify-center w-full pt-40 pb-12 bg-black bg-center bg-no-repeat bg-cover md:pb-14 bg-pattern">
        <h2 className="mb-4 text-4xl font-bold text-white">Delete Account</h2>
      </div>

      <div className="flex flex-col mt-10 item-center">
        <div className="self-center w-full px-3 py-8 bg-white md:max-w-lg">
          {successful ? (
            <div>
              <h2 className="text-lg font-semibold">
                Your account has been deleted
              </h2>
              <p className="mt-2 text-left">
                Your account has been deleted. You won'b be able to login again.
              </p>
            </div>
          ) : (
            <div>
              {loading ? (
                <Spinner className="text-xl" />
              ) : (
                <p className="mt-2 font-medium">
                  This link is either invalid or has expired.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <FooterCTA />
      <Footer />
    </>
  );
};

export default ConfirmDeleteAccount;
