import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import { Helmet } from "react-helmet-async";

const Terms: React.FC = () => {
  return (
    <>
      <Header plain />
      <Helmet>
        <title>Bitoshi Africa | All legal details you need to know</title>
        <meta
          name="description"
          content="Know all the legal details of agreeing to trade with the Bitoshi Africa website or app."
        />
        <meta
          name="keywords"
          content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto | Terms and Conditions"
        />
      </Helmet>
      <div className="flex flex-col items-center mb-10">
        <div className="bg-[#FFFBF7] py-10 md:py-20 md:pt-44 pt-40 w-full">
          <h2 className="text-2xl font-bold md:text-3xl">Terms of Use</h2>
          <p className="mt-2 text-sm text-black text-opacity-60">
            Last Updated: September 10, 2022
          </p>
        </div>

        <div className="w-full px-5 mt-10 font-light text-left md:px-40">
          <h3 className="py-5 font-medium">Introduction</h3>
          <div>
            Thank you for choosing Bitoshi.africa any reference to “we” “us”
            “our” “Bitoshi.africa” and/or any similar term shall be construed as
            reference to Bitoshi Digital Services Limited. These Terms of Use
            (these <span className="font-normal">"Terms"</span>,
            <span className="font-normal">“Terms of Service”</span>) and Privacy
            Policy apply to any person that registers on the Bitoshi.africa App
            or on any associated websites, retail products and/or services (the
            "Bitoshi.africa App and Services").
            <p className="mt-4"></p>
            By registering for and using the Bitoshi.africa App and services,
            you unconditionally accept these Terms and agree to be bound by and
            act in accordance with them, as well as our Privacy Policy. You also
            accept and agree that you are solely responsible for understanding
            and complying with all laws, rules, regulations and requirements of
            the jurisdiction in which you live that may be applicable to your
            use of the application.
            <p className="mt-4"></p>
            The Terms constitute a legally binding agreement between you and
            Bitoshi Digital Services (“Bitoshi.africa”), a company incorporated
            under the laws of the Federal Republic of Nigeria and govern your
            access to and use of the Bitoshi.africa Websites and App (together
            or individually “Service”) operated by us..
            <p className="mt-4"></p>
            Please read these Terms carefully before using the Platform because
            they affect your legal rights and obligations.
          </div>
          <h3 className="py-5 font-medium">Amendment Of Terms</h3>
          <div>
            We may amend the Terms from time to time. You should visit the terms
            of use page on the website regularly to check when the Terms were
            last updated (as displayed at the top of this document) and to
            review the current Terms. We will do our best to notify you of any
            amendments to the Terms that we consider likely to materially affect
            your rights and obligations. Any such notice will be posted on the
            Platform, or sent to the email address associated with your
            Bitoshi.africa Account.
            <p className="mt-4"></p>
            The continued use of your Bitoshi.africa Account, after any
            amendment to these Terms, constitutes your acceptance of the Terms,
            as modified by such amendment. If you do not accept the Terms, or
            any amendment to them, you must immediately stop using the Platform
            and your Bitoshi.africa Account.
          </div>
          <h3 className="py-5 font-medium">Eligibility</h3>
          <div>
            To use our application and services you must be at least 18 years of
            age or have attained the relevant age of majority as contained in
            applicable laws, with full legal capacity to accept this Agreement
            and enter into any transaction on the app. If as a parent or
            guardian, you become aware that your child or ward child has
            provided us with any information without your consent, please
            contact us through details on the Privacy Policy.
            <p className="mt-4"></p>
            Your eligibility to access certain Bitoshi.africa App and services
            also depends on the country in which you reside, and the countries
            that Bitoshi.africa operates in (“Operating Country”).
          </div>
          <h3 className="py-5 font-medium">Risks</h3>
          <div>
            Before using the Bitoshi.africa App or Services, you should ensure
            that you understand the risks involved in buying, selling or trading
            Digital Currencies or Digital Assets as such markets are known to be
            volatile and prices can fluctuate significantly, which could result
            in sudden and significant increases or decreases in the value of
            your assets. You understand that while "Digital Currencies" refer to
            encrypted or digital tokens or cryptocurrencies with a certain value
            that are based on blockchain and cryptography technologies and are
            issued and managed in a decentralized form, "Digital Assets" refer
            to Digital Currencies, their derivatives, or other types of
            digitalized assets with a certain value", which may or not be
            regulated in your jurisdiction.
            <p className="mt-4"></p>
            You should carefully assess whether your financial situation and
            risk tolerance is suitable for buying, selling or trading Digital
            Assets or Digital Currencies. You accept and agree that you are
            solely responsible for any decision to buy, sell, trade or otherwise
            hold or deal with cryptocurrency.
            <p className="mt-4"></p>
            Under no circumstances does any information contained on the
            Bitoshi.africa App, or provided to you through your Bitoshi.africa
            Account or by any employee, agent or affiliate of Bitoshi.africa,
            constitute financial, investment or other professional advice. You
            are solely responsible for any decision to store, buy or sell
            cryptocurrency, and such decisions should take into account your
            risk tolerance and financial circumstances.
          </div>
          <h3 className="py-5 font-medium">Identity Verification </h3>
          <div>
            Bitoshi.africa implements and maintains the highest standards of
            Know Your Customer (“KYC”) processes and controls as part of our
            commitment to combating fraud and assisting in the prevention of
            money laundering and terrorist financing. While our industry is
            largely unregulated, Bitoshi.africa voluntarily adheres to local and
            international compliance standards in relation to customer due
            diligence.
            <p className="mt-4"></p>
            In order to use certain features of the Bitoshi.africa Services, you
            may be required to provide bitoshi.africa with personal information,
            including, but not limited to, your name, address, telephone number,
            e-mail address, date of birth, Bank Verification Number (BVN), and
            information regarding your bank account (e.g., financial institution
            and account number). By submitting these or any other personal
            information as may be required, you confirm that the information
            provided is accurate and authentic, and you agree to update
            Bitoshi.africa if any information changes.
            <p className="mt-4"></p>
            To ensure we meet these standards, our customers are required to
            provide certain personal details and documents when opening a
            Bitoshi.africa Account (“Identity Verification”). The nature and
            extent of the Identity Verification required will depend upon which
            of our supported countries you are resident in, and the deposit and
            withdrawal limits that you wish to apply to your Bitoshi.africa
            Account. In certain circumstances, Bitoshi.africa may also perform
            enhanced due diligence (“EDD”) procedures in relation to your
            bitoshi.africa Account. You accept and agree that you will remain
            subject to such procedures at all times.
            <p className="mt-4"></p>
            Bitoshi.africa reserves the right to, at any time:
            <p className="mt-4"></p>
            a. restrict or suspend your Bitoshi.africa Account when we, in our
            sole discretion, consider it necessary to carry out further Identity
            Verification and/or EDD; or <br /> b. terminate your Bitoshi.africa
            Account if you provide, or we suspect you have provided, false
            information or refuse to provide information we require for Identity
            Verification and/or EDD.
            <p className="mt-4"></p>
            You accept and agree that there may be delays in accessing your
            Bitoshi.africa Account, or in carrying out transactions through your
            Bitoshi.africa Account, while we undertake any Identity Verification
            and/or EDD procedures.
            <p className="mt-4"></p>
            You also authorise Bitoshi.africa to, directly or through third
            parties, make any inquiries we consider necessary to verify your
            identity and/or protect against fraud, including to query identity
            information contained in public reports (e.g., your name, address,
            past addresses, or date of birth), to query account information
            associated with your linked bank account (e.g., name or account
            balance), and to take action we reasonably deem necessary based on
            the results of such inquiries and reports. You further authorise any
            and all third parties to which such inquiries or requests may be
            directed to fully respond to such inquiries or requests.
          </div>
          <h3 className="py-5 font-medium">Retention Of Information </h3>
          <div>
            Bitoshi.africa is required to retain certain information and
            documentation obtained as part of the Identity Verification and EDD
            procedures. These requirements apply even when you have terminated
            your relationship with Bitoshi.africa. We reserve the right to keep
            such information and documentation for the required period and you
            accept and agree that information and documentation you provide to
            Bitoshi.africa may be retained by us, including following the
            closure of your Bitoshi.africa Account.
          </div>
          <h3 className="py-5 font-medium">General Provisions</h3>
          <h4 className="mb-5 text-sm font-medium">Intellectual Property</h4>
          <div>
            Unless otherwise indicated by us, all intellectual property rights
            and any content provided in connection with the Site or the
            Services, are the property of Bitoshi.africa or our licensors or
            suppliers and are protected by applicable intellectual property
            laws. We do not give any implied license for the use of the contents
            of the Site or the Services.
            <p className="mt-4"></p>
            You accept and acknowledge that the material and content contained
            in or delivered by the Site or the Services is made available for
            your personal, lawful, non-commercial use only and that you may only
            use such material and content for the purpose of using the Site or
            the Services as set forth in this Agreement.
            <p className="mt-4"></p>
            You further acknowledge that any other use of content from the Site
            or the Services is strictly prohibited and you agree not to infringe
            or enable others to infringe our intellectual property rights. You
            agree to retain all copyrighted and other proprietary notices
            contained in the material provided via the Site or the Services on
            any copy you make of the material but failing to do so shall not
            prejudice Bitoshi.africa’s intellectual property rights therein.
            <p className="mt-4"></p>
            You may not sell or modify materials derived or created from the
            Site or the Services or reproduce, display, publicly perform,
            distribute or otherwise use the materials in any way for any public
            or commercial purpose. Your use of such materials on any other
            website or on a file-sharing or similar service for any purpose is
            strictly prohibited. You may not copy any material or content
            derived or created from the Site or the Services without our
            express, written permission.
            <p className="mt-4"></p>
            Any rights not expressly granted herein to use the materials
            contained on or through the Site or the Services are reserved by
            Bitoshi.africa in full.
          </div>
          <h4 className="py-5 text-sm font-medium">Accuracy Of Information</h4>
          <div>
            Bitoshi.africa endeavours to verify the accuracy of any information
            displayed, supplied, passing through or originating from the
            Services, but such information may not always be accurate or
            current. Accordingly, you should independently verify all
            information before relying on it, and any decisions or actions taken
            based upon such information are your sole responsibility. We make no
            representation or warranty of any kind, express or implied,
            statutory or otherwise, regarding the contents of the Services,
            information and functions made accessible through the Services, any
            hyperlinks to third party websites, or the security associated with
            the transmission of information through the Services, or any website
            linked to the Services.
          </div>
          <h4 className="py-5 text-sm font-medium">
            Third Party Services and Content
          </h4>
          <div>
            In using the Services, you may view content or services provided by
            third parties, including links to web pages and services of such
            parties (“Third Party Content”). We do not control, endorse or adopt
            any Third Party Content and have no responsibility for Third Party
            Content, including, without limitation, material that may be
            misleading, incomplete, erroneous, offensive, indecent or otherwise
            objectionable in your jurisdiction. In addition, your dealings or
            correspondence with such third parties are solely between you and
            the third party. We are not responsible or liable for any loss or
            damage of any sort incurred as a result of any such dealings and
            your use of Third Party Content is at your own risk.
          </div>
          <h3 className="py-5 font-medium">My Wallet</h3>
          <div>
            The Digital Currency Wallet services allow you send supported
            Digital Currency to, and request, receive, and store supported
            Digital Currency from, third parties pursuant to instructions you
            provide through the Bitoshi.africa App (each such transaction is a
            "Digital Currency Transaction").
            <p className="mt-4"></p>
            Bitoshi.africa reserves the right to refuse to process or to cancel
            any pending Digital Currency Transaction as required by law or in
            response to a subpoena, court order, or other binding government
            order or to enforce transaction limits. Bitoshi.africa cannot
            reverse a Digital Currency Transaction which has been broadcast to a
            Digital Currency network. The Digital Currency Wallet services are
            available only in connection with those Digital Currencies that
            Bitoshi.africa, in its sole discretion, decides to support. Under no
            circumstances should you attempt to use your Digital Currency Wallet
            services to store, send, request, or receive digital currencies in
            any form that is not supported by Bitoshi.africa. Bitoshi.africa
            assumes no responsibility or liability in connection with any
            attempt to use Bitoshi.africa Services for digital currencies that
            Bitoshi.africa does not support.
          </div>
          <h4 className="py-5 text-sm font-medium">
            Digital Currency Transactions
          </h4>
          <div>
            Bitoshi.africa processes supported Digital Currency according to the
            instructions received from its users and we do not guarantee the
            identity of any user, receiver, requester, or another party. You
            should verify all transaction information prior to submitting
            instructions to Bitoshi.africa. In the event you initiate a Digital
            Currency Transaction by entering the recipient's Digital Currency
            address on the network, a network fee will be incurred. Once
            submitted to a Digital Currency network, a Digital Currency
            Transaction will be unconfirmed for a period of time pending
            sufficient confirmation of the transaction by the Digital Currency
            network. A transaction is not complete while it is in a pending
            state. Funds associated with transactions that are in a pending
            state will be designated accordingly, and will not be included in
            your Bitoshi.africa Account’s available balance or be available to
            conduct transactions. Bitoshi.africa may charge network fees (miner
            fees) to process a Digital Currency transaction on your behalf.
            Bitoshi.africa will calculate the network fee, although
            Bitoshi.africa will always notify you of the network fee at or
            before the time you authorize the transaction.
          </div>
          <h4 className="py-5 text-sm font-medium">
            Digital Currency Storage & Transmission Delays
          </h4>
          <div>
            Bitoshi.africa securely stores all Digital Currency private keys in
            our control in a combination of online and offline storage. As a
            result, it may be necessary for Bitoshi.africa to retrieve certain
            information from offline storage in order to facilitate a Digital
            Currency Transaction in accordance with your instructions, which may
            delay the initiation or crediting of such Digital Currency
            Transaction for 48 hours or more. You acknowledge and agree that a
            Digital Currency Transaction facilitated by Bitoshi.africa may be
            delayed.
          </div>
          <h4 className="py-5 text-sm font-medium">Third-Party Payments</h4>
          <div>
            Bitoshi.africa has no control over, or liability for, the delivery,
            quality, safety, legality, or any other aspect of any goods or
            services (excluding Digital Currency bought or sold via the
            Bitoshi.africa App) that you may purchase or sell to or from a third
            party (including other users of Bitoshi.africa Services).
          </div>
          <h4 className="py-5 text-sm font-medium">Advanced Protocols</h4>
          <div>
            Unless specifically announced on our website or through some other
            official public statement of Bitoshi.africa, we do not support
            metacoins, coloured coins, side chains, or other derivative,
            enhanced, or forked protocols, tokens, or coins that supplement or
            interact with a Digital Currency supported by Bitoshi.africa
            (collectively, “Advanced Protocols”). Do not use your Bitoshi.africa
            account to attempt to receive, request, send, store, or engage in
            any other type of transaction involving an Advanced Protocol. The
            Bitoshi.africa platform is not configured to detect and/or secure
            Advanced Protocol transactions and Bitoshi.africa assumes absolutely
            no responsibility whatsoever in respect to Advanced Protocols.
          </div>
          <h4 className="py-5 text-sm font-medium">
            Operations of Digital Currency Protocols
          </h4>
          <div>
            Bitoshi.africa does not own or control the underlying software
            protocols which govern the operation of Digital Currencies supported
            on our platform. In general, the underlying protocols are open
            source and anyone can use, copy, modify, and distribute them. By
            using the Bitoshi.africa App, you acknowledge and agree that
            Bitoshi.africa is not responsible for operation of the underlying
            protocols and that Bitoshi.africa makes no guarantee of their
            functionality, security, or availability. The underlying protocols
            are subject to sudden changes in operating rules (a.k.a “forks”),
            and that such forks may materially affect the value, function,
            and/or even the name of the Digital Currency you store on the
            Bitoshi.africa platform. In the event of a fork, you agree that
            Bitoshi.africa may temporarily suspend Bitoshi.africa operations
            (with or without advance notice to you) and that Bitoshi.africa may,
            in its sole discretion, decide whether or not to support (or cease
            supporting) either branch of the forked protocol entirely. You
            acknowledge and agree that Bitoshi.africa assumes absolutely no
            responsibility whatsoever in respect of an unsupported branch of a
            forked protocol.
          </div>
          <h4 className="py-5 text-sm font-medium">Fees</h4>
          <div>
            The fees applicable to transactions undertaken on the Bitoshi.africa
            App must be confirmed before transactions are undertaken.
            Bitoshi.africa reserves the right to change our fees at any time and
            in certain circumstances, and at our sole discretion, Bitoshi.africa
            may notify selected customers of a specific fee change, where we
            consider a change to be of particular relevance to such customers.
            The following fees may be charged by Bitoshi.africa for Services
            carried out on the App or Website:
            <p className="mt-2"></p>
            a. Send and Receive fees. <br /> b. Deposit and Withdrawal fees.{" "}
            <br /> c. Buy to External Wallets and Sell from External Wallets
            fees
          </div>
          <h4 className="py-5 text-sm font-medium">Account Security</h4>
          <div>
            Bitoshi.africa takes security very seriously and remains committed
            to putting measures in place and providing adequate data and
            information security to ensure that the detection and prevention of
            fraudulent and unauthorised access to Bitoshi.africa Accounts.
            However, you are solely responsible for:
            <p className="mt-4"></p>
            a. maintaining adequate security and control over your
            Bitoshi.africa Account sign-in details, including but not limited to
            any passwords, personal identification numbers (PINs), API keys, or
            any other codes associated with your Bitoshi.africa Account; <br />{" "}
            b. enabling any additional security features available to you,
            including the activation of two factor authentication on your
            Bitoshi.africa account; <br /> c. keeping your contact details up to
            date so that you can receive any notices or alerts we may send to
            you in relation to security; <br /> d. maintaining security and
            control over the email mailbox, phone number and two-factor
            authentication applications or devices associated with your
            Bitoshi.africa Account.
            <p className="mt-4"></p>
            You acknowledge that Bitoshi.africa shall not in any way be liable
            for any loss, damage or costs caused by authorized or unauthorized
            use of your Bitoshi.africa Account credentials.
            <p className="mt-4"></p>
            Failure to take the above measures, and any other security measures
            available to you, may result in unauthorized access to your
            Bitoshi.africa Account and the loss or theft of any cryptocurrency
            and/or Local Currency balances held in your Bitoshi.africa Wallet or
            any linked bank account(s) and/or saved credit or debit card(s).
            Bitoshi.africa shall have no liability to you for or in connection
            with any unauthorized access to your Bitoshi.africa Account, where
            such unauthorized access was due to no fault of Bitoshi.africa,
            and/or any failure by you to act upon any notice or alert that we
            send to you.
            <p className="mt-4"></p>
            The security of your Bitoshi.africa Account may be compromised, or
            interruption caused to it, by phishing, spoofing or other attack,
            computer viruses, spyware, scareware, Trojan horses, worms or other
            malware that may affect your computer or other equipment.
            Bitoshi.africa strongly recommends that you regularly use reputable
            virus screening and prevention software and remain alert to the fact
            that SMS, email services, and search engines are vulnerable to
            spoofing and phishing attacks.
            <p className="mt-4"></p>
            Care should be taken in reviewing messages purporting to originate
            from Bitoshi.africa and, should you have any uncertainty regarding
            the authenticity of any communication, you should log in to your
            Bitoshi.africa Account through the Bitoshi.africa App or Website and
            not any other domain name or website purporting to be, or to be
            related to, Bitoshi.africa) to review any transactions or required
            actions.
            <p className="mt-4"></p>
            To the maximum extent permitted by applicable law, you accept and
            agree that you have full responsibility for all activity that occurs
            in or through your Bitoshi.africa Account and accept all risks of
            any unauthorized or authorized access to your Bitoshi.africa
            Account.
            <p className="mt-4"></p>
            Bitoshi.africa will never request for your credentials via email,
            SMS, phone call, or any other means of communications other than the
            Bitoshi.africa Sites. If you receive such communication, please
            disregard and contact us **contact@bitoshi.africa*.*** You should
            immediately notify Bitoshi.africa if you believe there has been an
            unauthorized transaction or unauthorized access to your
            Bitoshi.africa Account or your password or PIN has been compromised.
          </div>
          <h4 className="py-5 text-sm font-medium">Service availability</h4>
          <div>
            While we will do everything we can to provide continuous operations
            and that our software is bug-free, Bitoshi.africa does not provide
            any warranty in relation to the availability of the Bitoshi.africa
            Site or your Bitoshi.africa Account. Without limiting the generality
            of the foregoing, we do not guarantee continuous access to the
            Bitoshi.africa Site or your Bitoshi.africa Account and make no
            representation that the Bitoshi.africa Site, Bitoshi.africa API,
            your Bitoshi.africa Account and/or any products or services offered
            therein will be available without interruption; or that there will
            be no delays, failures, errors, omissions or loss of transmitted
            information.
          </div>
          <h4 className="py-5 text-sm font-medium">Closure of your account</h4>
          <div>
            You may close your Bitoshi.africa Account by submitting a
            request via our customer help center at{" "}
            <span className="font-normal">contact@bitoshi.africa</span>.
            <p className="mt-4"></p>
            You accept and acknowledge that, once your Bitoshi.africa Account is
            closed:
            <p className="mt-4"></p>
            a. you will have no further access to it; <br />
            b. Bitoshi.africa will be under no obligation to notify you of, or
            provide to you, any cryptocurrency sent to any receive address
            associated with your Bitoshi.africa Account; and <br /> c.
            Bitoshi.africa reserves the right (but has no obligation) to delete
            all of your information and account data stored on our servers, and
            also reserves the right to retain any information that is required
            for legal or operational reasons.
            <p className="mt-4"></p>
            If at the date of closure of your account any Local Currency or
            cryptocurrency remains in your Bitoshi.africa Wallet(s) (that is,
            funds amounting to less than the Minimum Closure Amount), you may
            notify Bitoshi.africa to claim the remaining amount at any time. You
            accept and agree that Bitoshi.africa shall not be liable to you or
            any third party in relation to the closure of your Bitoshi.africa
            Account, the termination of access to your Bitoshi.africa Account,
            or for the deletion of your information or Bitoshi.africa Account
            data.
          </div>
          <h4 className="py-5 text-sm font-medium">
            Restriction, suspension and termination
          </h4>
          <div>
            Bitoshi.africa reserves the right to restrict, suspend or terminate
            your Bitoshi.africa Account where:
            <p className="mt-4"></p>
            a. we reasonably suspect your Bitoshi.africa Account to be the
            subject of an operational or other error, in which case we may be
            required to suspend access to your account until such time as the
            error is rectified; <br /> b. we reasonably suspect your
            Bitoshi.africa Account has been or is being used in relation to any
            unlawful, fraudulent or prohibited activity, or in breach of these
            Terms; <br /> c. we reasonably suspect you or your Bitoshi.africa
            Account is or has been associated with, or poses a high risk of,
            money laundering, financing of terrorism, fraud, or any other
            financial crime; <br /> d. we reasonably suspect you of taking any
            action that Bitoshi.africa considers to be a circumvention of
            Bitoshi.africa’s controls, including but not limited to opening
            multiple Bitoshi.africa Accounts; <br /> e. we reasonably suspect
            your involvement in any attempt to gain unauthorised access to any
            Bitoshi.africa Account; <br /> f. your Bitoshi.africa Account is or
            appears to be the subject of any legal, regulatory or government
            process and/or we, in our sole discretion, consider there to be a
            heightened risk of legal or regulatory non-compliance associated
            with your Bitoshi.africa Account; <br /> g. we are compelled to do
            so by a prima facie valid subpoena, court order, or other binding
            order of a government or regulatory authority; or <br /> h. your
            name appears on a government or international body sanctions list.
            <p className="mt-4"></p>
            Bitoshi.africa will make all reasonable efforts to provide you with
            notice of any decision to restrict, suspend or terminate your
            Bitoshi.africa Account, unless we are prevented from doing so by any
            legal or regulatory process or requirement, or where doing so may
            compromise Bitoshi.africa’s security and/or risk management
            procedures. You accept and agree that Bitoshi.africa is under no
            obligation to disclose to you the details of its risk management or
            its security procedures to you or the fact of or reason for any
            decision to restrict, suspend or terminate your Bitoshi.africa
            Account, and shall have no liability to you in connection with the
            restriction, suspension or termination of your Bitoshi.africa
            Account.
          </div>
          <h4 className="py-5 text-sm font-medium">Financial Advice</h4>
          <div>
            All contents published on the Bitoshi.africa Sites or communicated
            through Bitoshi.africa social media pages and blogs or by any
            employee, agent, or affiliate of Bitoshi.africa are for
            informational or marketing purposes only. No contents or
            communication may be deemed to constitute professional and/or
            financial advice. Our website is for informational purposes only and
            no information contained therein may be deemed to constitute legal
            advice or an exhaustive statement of the law. You are solely
            responsible for any decision to store, buy or sell cryptocurrency,
            and such a decision should take into account your risk tolerance and
            financial circumstances. You should consult your legal, financial,
            or tax professional in relation to your specific situation.
          </div>
          <h4 className="py-5 text-sm font-medium">Taxes</h4>
          <div>
            You are solely responsible for determining whether, and to what
            extent, any taxes apply to any transactions you carry out through
            your Bitoshi.africa Account, and for withholding, collecting,
            reporting and remitting the correct amounts of tax to the
            appropriate tax authorities.
          </div>
          <h4 className="py-5 text-sm font-medium">Prohibited activities</h4>
          <div>
            You must not use your Bitoshi.africa Account to undertake any of the
            activities or categories of activity set out in this section (each a
            “Prohibited Activity”):
            <p className="mt-4"></p>
            a. violation of any laws, statutes, ordinance or regulations; <br />{" "}
            b. undertaking, facilitating or supporting criminal activity of any
            kind, including but not limited to, money laundering, terrorist
            financing, illegal gambling operations or malicious hacking; <br />
            c. abusive activity, including but not limited to: <br />
            - imposing an unreasonable or disproportionately large load on
            Bitoshi.africa’s infrastructure, or otherwise taking any action that
            may negatively affect the performance of the Bitoshi.africa Site or
            Bitoshi.africa’s reputation; <br />
            - attempting to gain unauthorised access to the Bitoshi.africa Site
            or any Bitoshi.africa Account; <br />
            - transmitting or uploading any material to the Bitoshi.africa Site
            that contains viruses, Trojan horses, worms, or any other harmful
            programmes; or <br />
            - transferring your Bitoshi.africa Account access or rights to your
            Bitoshi.africa Account to a third party, unless as required by law
            or with Bitoshi.africa’s prior consent. <br />
            d. paying in to or otherwise supporting pyramid schemes, Ponzi
            schemes, matrix programmes, “get rich quick” schemes, multi-level
            marketing programmes or high-yield investment programmes; <br />
            e. fraudulent activity, including but not limited to taking any
            actions that defraud Bitoshi.africa or a Bitoshi.africa customer, or
            the provision of any false, inaccurate, or misleading information to
            Bitoshi.africa; <br />
            f. transactions involving items that may help facilitate or enable
            illegal activity; promote or facilitate hate, violence or racial
            intolerance; are considered obscene; or may be stolen goods or the
            proceeds of crime; <br /> g. transactions involving online gambling
            sites or mixers; <br /> h. sale or purchase of narcotics or
            controlled substances; <br /> i. intellectual property infringement.
            <p className="mt-4"></p>
            By opening a Bitoshi.africa Account, you confirm that you will not
            use your account to undertake any of the above-listed Prohibited
            Activities or any similar or related activity.
            <p className="mt-4"></p>
            We reserve the right to restrict, suspend or terminate your
            Bitoshi.africa Account if we suspect, in our sole discretion, that
            you are using, or have used, your Bitoshi.africa Account in
            association with any of the activities listed above, or any similar
            or related activity, without having obtained the prior written
            approval of Bitoshi.africa.
          </div>
          <h4 className="py-5 text-sm font-medium">Disclaimer of warranties</h4>
          <div>
            The Bitoshi.africa Site, your Bitoshi.africa Account and any related
            products or services are offered on a strictly “as-is” and
            “where-available” basis and Bitoshi.africa expressly disclaims, and
            you waive, all warranties of any kind, whether express or implied.
            Without limiting the generality of the foregoing, the Bitoshi.africa
            Site, your Bitoshi.africa Account, and any related products or
            services are offered without any warranty as to merchantability or
            fitness for any particular purpose.
            <p className="mt-4"></p> Some jurisdictions do not allow the
            disclaimer of implied terms in consumer contracts, so some or all of
            the disclaimers in this section may not apply to you.
          </div>
          <h4 className="py-5 text-sm font-medium">Limitation of liability</h4>
          <div>
            In no event shall Bitoshi.africa, its operating entities or any
            other affiliates (including their respective directors, members,
            employees or agents) be liable to you for any direct, indirect,
            special, consequential, exemplary or punitive damages or any other
            damages of any kind, including but not limited to loss of profit,
            loss of revenue, loss of business, loss of opportunity, loss of
            data, whether in contract, tort or otherwise, arising out of or in
            any way connected with your use of, inability to use, or
            unavailability of the Bitoshi.africa Site and/or your Bitoshi.africa
            Account, including without limitation any damages caused by or
            resulting from any reliance upon any information received from
            Bitoshi.africa, or that result from mistakes, omissions,
            interruptions, deletion of files or email, errors, defects, viruses,
            delays in operation or transmission or any failure of performance,
            whether or not resulting from a force majeure event, communications
            failure, theft, destruction or unauthorised access to
            Bitoshi.africa’s records, programmes or services.
            <p className="mt-4"></p>
            In no event will any liability of Bitoshi.africa, its operating
            entities or any other affiliates (including their respective
            directors, members, employees or agents) arising in relation to your
            use of the Bitoshi.africa Site or your Bitoshi.africa Account,
            exceed (in aggregate) the fees earned by Bitoshi.africa in
            connection with your use of your Bitoshi.africa Account in the six
            month period immediately preceding the event giving rise to the
            claim for liability.
            <p className="mt-4"></p>
            The above limitations of liability shall apply to the fullest extent
            permitted by law in the applicable jurisdiction. Because some
            jurisdictions do not allow the exclusion of certain warranties or
            the limitation or exclusion of liability for incidental or
            consequential damages, some of the limitations in this section may
            not apply to you.
          </div>
          <h4 className="py-5 text-sm font-medium">Indemnity</h4>
          <div>
            To the maximum extent permitted by law, you agree to indemnify
            Bitoshi.africa, its operating entities, or any other affiliates
            (including their respective directors, members, employees, and/or
            agents) against any action, liability, cost, claim, loss, damage,
            proceeding or expense suffered or incurred directly or indirectly
            arising from your use of or conduct in relation to the
            Bitoshi.africa Site and/or your Bitoshi.africa Account, or from your
            violation of these Terms.
          </div>
          <h4 className="py-5 text-sm font-medium">Disputes</h4>
          <div>
            You and we agree to notify the other party in writing of any claim
            or dispute that arises in relation to the Bitoshi.africa Site, your
            Bitoshi.africa Account or these Terms, within 30 days of such claim
            or dispute arising. You and we further agree to attempt informal
            resolution of any Dispute prior to bringing a claim in any court or
            other body.
          </div>
          <h4 className="py-5 text-sm font-medium">
            Intellectual Property and Limited License
          </h4>
          <div>
            We grant you a limited, nonexclusive, nontransferable license,
            subject to the Terms, to access and use the App and Bitoshi.africa
            Sites and related content, materials, information ("Content") solely
            for approved purposes as permitted by Bitoshi.africa from time to
            time. Any other use of the Bitoshi.africa Site or Content is
            expressly prohibited and all other right, title, and interest in the
            Bitoshi.africa Site or Content is exclusively the property of
            Bitoshi.africa and its licensors. You agree you will not copy,
            transmit, distribute, sell, license, reverse engineer, modify,
            publish, or participate in the transfer or sale of, create
            derivative works from, or in any other way exploit any of the
            Content, in whole or in part. All logos related to Bitoshi.africa
            Services or displayed on the App or Bitoshi.africa Sites are either
            trademarks or registered marks of Bitoshi.africa or its licensors.
            You may not copy, imitate, or use them without Bitoshi.africa prior
            written consent.
            <p className="mt-4"></p>
            a. Governing law and jurisdiction. This Agreement and any dispute
            arising therefrom shall be governed by English law and the
            non-exclusive jurisdiction of the English courts. <br /> b. Class or
            representative action waiver. To the maximum extent permissible by
            law, you and Bitoshi.africa each agree that each may bring any
            Dispute against the other only in your or its individual capacity,
            and you and it waive any right to commence or participate in any
            class action or other representative action or proceeding against
            the other. Further, where permissible by law, unless both you and
            Bitoshi.africa agree otherwise, no court may consolidate any other
            person’s claim(s) with your Dispute, and may not otherwise preside
            over any form of representative or class proceeding.
            <p className="mt-4"></p>
            For the avoidance of doubt, if this Class or representative action
            waiver is found by any court of competent jurisdiction to be
            invalid, void or unenforceable, the remainder of this Disputes
            clause shall remain valid and enforceable.
          </div>
          <h4 className="py-5 text-sm font-medium">
            Export controls and sanctions
          </h4>
          <div>
            Your use of the Bitoshi.africa Site is subject to international
            export controls and economic sanctions requirements. By undertaking
            any activity on the Bitoshi.africa Site or through your
            Bitoshi.africa Account, including but not limited to sending,
            receiving, buying, selling, storing, or trading any cryptocurrency,
            you agree that you will at all times comply with those requirements.
            In particular, and without any limitation to the generality of the
            foregoing, you may not open, use, or have access to any
            Bitoshi.africa Account if:
            <p className="mt-4"></p> a. you are in or under the control of, or
            resident of, any country subject to United States embargo, UN
            sanctions, the HM Treasury financial sanctions regime, or if you are
            on the U.S. Treasury Department's Specially Designated Nationals
            List or the U.S. Commerce Department's Denied Persons List,
            Unverified List, Entity List, or HM Treasury's financial sanctions
            regime; or <br /> b. you intend to supply any acquired or stored
            cryptocurrency, or transact with people or businesses operating in
            any country subject to United States embargo or HM Treasury's
            financial sanctions regime, or to a person on the Specially
            Designated Nationals List, Denied Persons List, Unverified List,
            Entity List, or HM Treasury's financial sanctions regime.
            <p className="mt-4"></p>
            <span className="font-normal">Entire agreement.</span> These Terms
            constitute the entire agreement and understanding between you and
            Bitoshi.africa with respect to their subject matter and supersede
            any and all prior discussions, agreements and understandings of any
            kind between you and Bitoshi.africa (including but not limited to
            any prior versions of these Terms).
            <p className="mt-4"></p>
            <span className="font-normal">Severability.</span> If any provision
            of these Terms, as amended from time to time, is determined to be
            illegal, invalid or unenforceable, in whole or in part, under any
            law, such provision or part thereof shall to that extent be deemed
            not to form part of these Terms but the legality, validity and
            enforceability of the other provisions in these Terms shall not be
            affected and everything else in these Terms will continue in full
            force and effect.
            <p className="mt-4"></p>
            In the event any provision or part thereof of these Terms is
            determined to be illegal, invalid or unenforceable, that provision
            or part thereof shall be replaced by the parties with a legal, valid
            and enforceable provision or part thereof that has, to the greatest
            extent possible, a similar effect as the illegal, invalid or
            unenforceable provision, given the content and purpose of these
            Terms.
            <p className="mt-4"></p>
            <span className="font-normal">Assignment.</span> You may not assign
            or transfer any of your rights or obligations under these Terms
            without Bitoshi.africa’s prior written approval. You give
            Bitoshi.africa your approval to assign or transfer these Terms in
            whole or in part, including but not limited to: (i) a subsidiary or
            affiliate; (ii) an acquirer of Bitoshi.africa’s equity, business or
            assets; or (iii) a successor by merger.
            <p className="mt-4"></p>
            <span className="font-normal">Change of control.</span> In the event
            that Bitoshi.africa is acquired by or merged with a third party, we
            reserve the right to transfer or assign the information we have
            collected from you as part of such merger, acquisition, sale, or
            other change of control.
            <p className="mt-4"></p>
            <span className="font-normal">Force Majeure.</span> Bitoshi.africas
            shall not be liable for any delays, failure in performance or
            interruption of service which result directly or indirectly from any
            cause or condition beyond our reasonable control, including but not
            limited to, any delay or failure due to any act of God, act of civil
            or military authorities, act of terrorists, civil disturbance, war,
            strike or other labour dispute, fire, interruption in
            telecommunications or Internet services or network provider
            services, failure of equipment and/or software, other catastrophe or
            any other occurrence which is beyond our reasonable control.
            <p className="mt-4"></p>
            <span className="font-normal">Survival.</span> All provisions of
            these Terms that by their nature extend beyond the expiry or
            termination of these Terms, including but not limited to, sections
            relating to the suspension or termination of your
            [Bitoshi.africa](http://Bitoshi.africa) Account, use of the
            Bitoshi.africa Site, disputes with Bitoshi.africa and general
            provisions, shall survive the termination of these Terms.
            <p className="mt-4"></p>
            <span className="font-normal">Headings.</span> Section headings in
            these Terms are for convenience only, and shall not govern the
            meaning or interpretation of any provision of these Terms.
            <p className="mt-4"></p>
            <span className="font-normal">English Language Controls.</span>
            Notwithstanding any other provision of this Agreement, any
            translation of this Agreement is provided for your convenience. The
            meanings of terms, conditions and representations herein are subject
            to definitions and interpretations in the English language. Any
            translation provided may not accurately represent the information in
            the original English.
            <p className="mt-4"></p>
            <span className="font-normal">Acknowledgement.</span> By using
            service or other services provided by us, you acknowledge that you
            have read these terms of service and agree to be bound by them.
            <p className="mt-4"></p>
            We grant you a limited, nonexclusive, nontransferable license,
            subject to the Terms, to access and use the App and Bitoshi.africa
            Sites and related content, materials, information ("Content") solely
            for approved purposes as permitted by Bitoshi.africa from time to
            time. Any other use of the Bitoshi.africa Site or Content is
            expressly prohibited and all other right, title, and interest in the
            Bitoshi.africa Site or Content is exclusively the property of
            Bitoshi.africa and its licensors. You agree you will not copy,
            transmit, distribute, sell, license, reverse engineer, modify,
            publish, or participate in the transfer or sale of, create
            derivative works from, or in any other way exploit any of the
            Content, in whole or in part. All logos related to Bitoshi.africa
            Services or displayed on the App or Bitoshi.africa Sites are either
            trademarks or registered marks of Bitoshi.africa or its licensors.
            You may not copy, imitate or use them without Bitoshi.africa prior
            written consent.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
