import { ReactNode } from "react";

const Main: React.FC<{
  children: ReactNode;
  className?: string;
  isVendor?: boolean;
}> = ({ children, className, isVendor }) => (
  <div
    className={`px-5 scroll-smooth ${
      isVendor ? "w-full pt-5" : "md:w-5/6 pt-10"
    } relative ${className}`}
  >
    {children}
  </div>
);

export default Main;
