import { httpFetchMerchants } from "api/admin/merchant";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Table from "components/ui/Table";
import TransactionItem from "components/ui/TransactionItem";
import VButton from "components/ui/VButton";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { adminRoutes } from "routes/routes";

const FrozenTronAccounts: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading] = useState(false);
  const [user, setUser] = useState<any>(null);

  const {
    data: { merchants },
  } = useQuery("merchants", httpFetchMerchants, {
    initialData: {
      merchants: [],
    },
  });

  const toggleOpen = (u: any) => {
    if (isOpen && u.email === user?.email) return setIsOpen(false);

    setUser(u);
    return setIsOpen(true);
  };

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-x-4">
            <h3 className="text-2xl">Frozen Tron Accounts</h3>
          </div>
        </div>

        <div className="flex gap-8 mt-14">
          <div className="w-7/12">
            <Table
              header={["Address", "Prev. Balance", "Resources", "Action"]}
              data={[
                ...merchants?.map((user: any) => [
                  user.userName,
                  user.email,
                  user.phoneNumber,
                  <VButton
                    status={isLoading ? "pending" : ""}
                    // onClick={}
                    className="px-6 py-2.5 font-light text-white bg-red-400 rounded-lg"
                  >
                    Unfreeze
                  </VButton>,
                  () => toggleOpen(user),
                ]),
              ]}
            />
          </div>
          {isOpen && (
            <div className="w-5/12 px-8 py-10 rounded-lg bg-orange-50">
              <h4 className="mb-5">@{user?.userName}</h4>

              <TransactionItem title="Username" value={`@${user?.userName}`} />
              <TransactionItem
                title="Registered Full Name"
                value={`${user?.firstName || ""} ${user?.lastName || ""}`}
              />
              <TransactionItem title="e-mail" value={user?.email} />
              <TransactionItem title="Phone Number" value="" />
              <TransactionItem
                title="Date registered"
                value={DateTime.fromISO(user?.regDate).toFormat("D")}
              />

              <div className="flex flex-wrap mt-10 gap-x-20 gap-y-10">
                <Link to={`${adminRoutes.merchants}/${user.id}/limit`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Limit & Balances
                  </span>
                </Link>
                <Link to={`${adminRoutes.merchants}/${user.id}/transactions`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Transactions
                  </span>
                </Link>
                <Link to={`${adminRoutes.merchants}/${user.id}/analytics`}>
                  <span
                    className={`px-5 py-1.5 bg-black text-white rounded-full mr-3 cursor-pointer`}
                  >
                    Analytics
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </Main>
    </Container>
  );
};
export default FrozenTronAccounts;
