import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface VendorNavProps {
  goBack?: () => void;
  showBack?: boolean;
  title?: string;
  subtitle?: string | ReactNode;
}

const VendorNav: React.FC<VendorNavProps> = ({
  goBack,
  showBack = true,
  title,
  subtitle,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between">
      <div>
        {showBack && (
          <button onClick={() => (goBack ? goBack() : navigate(-1))}>
            <i className="fa fa-long-arrow-left text-orange-300 mr-2"></i>
          </button>
        )}
      </div>
      <div>
        <h4 className="text-xl font-medium">{title}</h4>
        <p className="text-sm text-black mt-2 text-opacity-50">{subtitle}</p>
      </div>
      <div></div>
    </div>
  );
};

export default VendorNav;
