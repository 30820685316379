import { httpVendorDeposits } from "api/admin/transaction";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import Search from "components/ui/Search";
import Table from "components/ui/Table";
import { Itransaction } from "components/ui/Transaction";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { adminRoutes } from "routes/routes";
import currency, { moneyFormat } from "utilities/currency";
import { capitalize } from "utilities/misc";

const types = ["pending", "completed", "cancelled"];

const Deposits: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const type = params.type as string;

  useEffect(() => {
    if (!types.includes(type)) {
      navigate(adminRoutes.dashboard);
    }
  }, [type, navigate]);

  const {
    data: { transactions },
  } = useQuery(["deposits", type], () => httpVendorDeposits(type), {
    initialData: {
      transactions: [],
    },
  });

  return (
    <Container>
      <AdminSidebar />
      <Main className="mt-8 mx-10 text-left">
        <div className="flex justify-between w-full items-center">
          <h3 className="text-2xl">{capitalize(type)} deposits</h3>
          <div className="w-3/6">
            <Search type="user" />
          </div>
        </div>

        <div className="mt-14">
          <Table
            header={["", "Merchant", "Deposit Amount", "Date & time"]}
            data={[
              ...transactions?.map((transaction: Itransaction) => [
                `${
                  transaction.status === "pending" ? "Deposit" : "Deposited"
                } NGN`,
                `@${transaction.merchant}`,
                `${currency("ngn")} ${moneyFormat(transaction.amount)}`,
                `${DateTime.fromISO(transaction.timestamp).toFormat(
                  "DD"
                )} ${DateTime.fromISO(transaction.timestamp).toFormat("t")}`,
                () =>
                  navigate(`${adminRoutes.deposits}/info/${transaction?.id}`),
              ]),
            ]}
          />
        </div>
      </Main>
    </Container>
  );
};
export default Deposits;
