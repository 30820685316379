import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import { Helmet } from "react-helmet-async";

const Privacy: React.FC = () => {
  return (
    <>
      <Header plain />
      <Helmet>
        <title>Bitoshi Africa | All your legal rights you need to know</title>
        <meta
          name="description"
          content="Know all the legal rights you have while trading with the Bitoshi Africa website or app."
        />
        <meta
          name="keywords"
          content="Bitoshi Africa | Cryptocurrency | Bitcoin | Digital Assets | Crypto Trade | Buy & Sell Crypto | Privacy Terms | Privacy Policy"
        />
      </Helmet>
      <div className="flex flex-col items-center mb-10">
        <div className="bg-[#FFFBF7] py-10 md:py-20 md:pt-44 pt-40 w-full">
          <h2 className="text-2xl font-bold md:text-3xl">Privacy Policy</h2>
          <p className="mt-2 text-sm text-black text-opacity-60">
            Last Updated: September 10, 2022
          </p>
        </div>

        <div className="w-full px-5 mt-10 font-light text-left md:px-40">
          <p>
            This Privacy Policy describes the “Personal Information” that we
            collect about you, how we collect and use it, how we share it, your
            rights and choices, and how you can contact us about our privacy
            practices. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>

          <h3 className="py-5 font-medium">
            The Personal Information We Collect
          </h3>

          <div>
            We collect the following types of information:
            <ul className="pl-2 list-disc">
              <li>
                <span className="font-normal">
                  Information About your Identity
                </span>{" "}
                may include, but is not limited: to your first name and last
                name, username, date of birth, bank account details, phone
                number and email.
              </li>
              <li>
                <span className="font-normal">
                  Information About your Transactions
                </span>{" "}
                with us or with others.
              </li>
              <li>
                <span className="font-normal">
                  Information About your Usage
                </span>{" "}
                this may include information about how you use the App, website
                and products, IP address, browser information, and sometimes
                information about your mobile device.
              </li>
            </ul>
          </div>
          <h3 className="py-5 font-medium">
            How We Collect your Personal Information
          </h3>

          <div>
            We collect your personal information directly from you, as well as
            automatically through your use of our app or website and sometimes,
            using *Cookies* and other similar technologies.
            <ul className="pl-2 mt-5 list-disc">
              <li>
                <span className="font-normal">
                  Information That You Give Us
                </span>{" "}
                this information may include, but is not limited: to your
                identity, contact, financial information that you consent to
                giving us when you register an account through our mobile app,
                participate in our survey or competitions, as well as from other
                third parties in respect of which you have given your consent to
                disclose information relating to you and/or where not otherwise
                restricted.
              </li>
              <li>
                <span className="font-normal">
                  Information We Collect Automatically
                </span>{" "}
                Each time you interact with our site or services, or use our
                App, we receive and store information generated by your usage.
                We collect this data using *Cookies* and other similar
                technologies. This information may include but is not limited
                to:
              </li>
              <li>IP address</li>
              <li>Browser information</li>
              <li>Operating system</li>
              <li>Pages you visit and time spent on those pages</li>
            </ul>
          </div>

          <h3 className="py-5 font-medium">Use of your Personal Information</h3>

          <div>
            We may use your information collected above for the following
            purposes:
            <ul className="pl-2 list-disc">
              <li>
                <span className="font-normal">Account Verification</span> in
                order to fulfil Know Your Customer (KYC) and Anti-Money
                Laundering (AML) regulations.
              </li>
              <li>
                <span className="font-normal">Provide our Services</span> to
                provide the services you signed up for and give you the required
                support when needed.
              </li>
              <li>
                <span className="font-normal">Contact</span> to contact you and
                keep a healthy business relationship
              </li>
              <li>
                <span className="font-normal">Prevent Breach of Contract</span>{" "}
                to prevent and investigate potentially prohibited or illegal
                activities, or violations of our terms of use.
              </li>

              <li>
                <span className="font-normal">For other Purposes</span> that is
                required or permitted by any law, regulations, guidelines or
                relevant regulatory authorities.
              </li>
            </ul>
          </div>
          <h3 className="py-5 font-medium">How We Share your Information</h3>
          <p>
            We protect your information and treat it as private and
            confidential. We do not release this information to any outside
            party, except in suspected fraud cases, or when it is required or
            permitted to do so by law (whether in or outside your country).
          </p>
          <h3 className="py-5 font-medium">Company Transfer</h3>
          <p>
            In the future, we may buy, sell or merge or partner with other
            companies or businesses. In such cases, we may include your
            information among the transferred assets.
          </p>
          <h3 className="py-5 font-medium">
            Rights to your Personal Information With Us
          </h3>
          <p>
            You have rights to access and make changes to your personal
            informations, you can also withdraw consent at any time by
            contacting us.
          </p>
          <h3 className="py-5 font-medium">Children Privacy</h3>
          <p>
            Our App services were not designed or intended for persons below the
            age of 18 and we do not knowingly collect Personal Information from
            children under the age of 18.
          </p>
          <h3 className="py-5 font-medium">Changes to our Privacy Policy</h3>
          <p>
            We may make changes to the privacy policy from time to time. When we
            revise this privacy policy, it will be updated on this page and you
            may be required to read and accept the new privacy policy before you
            can continue to use our services.
          </p>
          <h3 className="py-5 font-medium">How can I Contact Bitoshi.africa</h3>
          <p>
            If you wish to make any enquiries in relation to this privacy
            policy, please contact us at{" "}
            <span className="font-normal">contact@bitoshi.africa</span>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
