import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { supportRoutes } from "routes/routes";
import { logoutSupport } from "utilities/network";

const SupportSidebar: React.FC = () => {
  return (
    <div className="relative px-5 py-10 bg-black md:w-1/6">
      <h1>
        <Link to={supportRoutes.dashboard}>
          <img
            className="object-contain w-[130px] pl-4"
            alt="Bitoshi.africa logo"
            src="/images/logo-white.png"
          />
        </Link>
      </h1>

      <nav className="mt-14">
        <ul className="text-left">
          <NavLink to={supportRoutes.dashboard} title="Users" icon="fa-home" />
          <NavLink
            to="#"
            title="Transactions"
            isDrop
            icon="fa-file-lines"
            sub={[
              {
                to: `${supportRoutes.transactions}/naira`,
                title: "Naira",
              },
              {
                to: `${supportRoutes.transactions}/market`,
                title: "Buy & Sell",
              },
              {
                to: `${supportRoutes.transactions}/send-receive`,
                title: "Send & Receive",
              },
              {
                to: `${supportRoutes.transactions}/swap`,
                title: "Swap",
              },
              {
                to: `${supportRoutes.transactions}/recurring`,
                title: "Recurring Buy",
              },
              {
                to: `${supportRoutes.transactions}/vouchers`,
                title: "Vouchers",
              },
            ]}
          />
        </ul>

        <div
          className={`pl-4 text-left rounded-lg py-2 bg-gray-900 text-white mt-10
          w-44`}
        >
          <p className="block w-full cursor-pointer" onClick={logoutSupport}>
            <i className={`fa fa-arrow-right-from-bracket`}></i>
            <span className="ml-4">Logout</span>
          </p>
        </div>
      </nav>
    </div>
  );
};

const NavLink: React.FC<{
  to: string;
  title: string;
  icon: string;
  isDrop?: boolean;
  sub?: Array<{ to: string; title: string }>;
}> = ({ to, title, icon, isDrop, sub }) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const isActive =
    to === "/"
      ? location.pathname === "/"
      : location.pathname.includes(to === "#" ? to : to.slice(1));

  return (
    <li
      className={`pl-4 rounded-lg py-2 mb-5 ${
        isActive ? "bg-gray-700 text-white" : "text-gray-400"
      }`}
    >
      {isDrop ? (
        <a
          href="#side"
          className="block w-full cursor-pointer"
          onClick={() => setIsOpen((p) => !p)}
        >
          <i className={`fa ${icon}`}></i>
          <span className="ml-4">{title}</span>
          {isDrop && (
            <i className={`fa fa-chevron-${isOpen ? "up" : "down"} ml-3`}></i>
          )}
        </a>
      ) : (
        <Link className="block w-full" to={to}>
          <i className={`fa ${icon}`}></i>
          <span className="ml-4">{title}</span>
          {isDrop && <i className="ml-3 fa fa-chevron-down"></i>}
        </Link>
      )}
      {isDrop && isOpen ? (
        <ul className="ml-1 text-left border-l border-l-gray-600">
          {sub?.map(({ title, to }, index) => (
            <li
              key={String(index)}
              className={`ml-4 pl-2 rounded-lg py-2 mt-3 ${
                isActive ? "bg-gray-700 text-white" : "text-gray-400"
              }`}
            >
              <Link className="block w-full" to={to}>
                <span className="ml-4">{title}</span>
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default SupportSidebar;
