import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import { Input, Select } from "components/misc";
import PINModal from "components/ui/PINModal";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import banks from "utilities/banks";
import { showDanger, showSuccess } from "utilities/misc";
import { patch } from "utilities/network";
import { paymentMethodTypes } from "utilities/payment";

const AddBank: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accountNumber, setAccountNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState<any>(banks[0]);
  const bank = selectedBank.name;
  const [pin, setPin] = useState("");
  const [isPINModalOpen, setIsPINModalOpen] = useState(false);

  const { type } = (location.state as { type: string }) || { type: "" };

  const selectedType = paymentMethodTypes.find((t) => t.name === type);

  useLayoutEffect(() => {
    if (!type || !selectedType) {
      return navigate(vendorRoutes.paymentMethods);
    }
  }, [type, navigate, selectedType]);

  const {
    execute: addBank,
    status,
    value,
  } = useAsync(
    () =>
      patch(`vendor/profile/addBank`, {
        bankCode: selectedBank.code,
        accountNumber,
        paymentMethodType: type,
        pin,
      }),
    false
  );

  useEffect(() => {
    if (value?.data?.data?.success) {
      showSuccess(value?.data?.message || "Bank added successfully");
      setIsPINModalOpen(false);
      navigate(-1);
    }
  }, [value, navigate]);

  const handleSubmit = () => {
    if (pin.length !== 4) {
      return showDanger("PIN should be 4 characters long");
    }

    addBank();
  };

  const handleOpenPINModal = () => {
    if (accountNumber.length !== 10) {
      return showDanger("Account number should be 10 characters long");
    }
    if (selectedBank.code === "") {
      return showDanger("Please select a bank");
    }

    setIsPINModalOpen(true);
  };

  const handleSelectBank = (name: string) => {
    setSelectedBank(banks.find((b) => b.name === name));
  };

  return (
    <Container isVendor>
      <VendorSidebar />

      <PINModal
        pin={pin}
        setPin={setPin}
        proceed={handleSubmit}
        status={status}
        isOpen={isPINModalOpen}
        close={() => setIsPINModalOpen(false)}
      />
      <Main isVendor>
        <VendorNav title="Add a new bank" />

        <div className="mt-10">
          <Select
            value={bank}
            data={[...banks.map((bank) => bank.name)]}
            placeholder="Select Bank"
            label="Bank"
            onChange={(e) => handleSelectBank(e.target.value)}
          />

          <Input
            value={accountNumber}
            placeholder="Bank account Number"
            label="Account Number"
            type="number"
            onChange={(e) => setAccountNumber(e.target.value)}
          />

          <VButton
            status={status}
            onClick={handleOpenPINModal}
            className="bg-orange-300 py-4 mt-10 text-white w-full"
          >
            Continue
          </VButton>
        </div>
      </Main>
    </Container>
  );
};

export default AddBank;
