import { httpGetRate } from "api/admin/stats";
import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import currency from "utilities/currency";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const Rates: React.FC = () => {
  const [sell, setSell] = useState("");
  const [buy, setBuy] = useState("");
  const [btcPercent, setBtcPercent] = useState("");
  const [btcFee, setBtcFee] = useState({
    maxFeePerByte: 0,
    minInputAmount: 0,
    minTotalSweep: 0,
    minDefaultAmount: 0,
    maxDefaultAmount: 0,
  });

  const handleSetBtcFee = (e: any) => {
    setBtcFee({
      ...btcFee,
      [e.target.name]: e.target.value,
    });
  };

  const { refetch } = useQuery("rate", httpGetRate, {
    initialData: {
      rate: {
        buy: {
          usd: 0,
        },
        sell: {
          usd: 0,
        },
        btcPercent: 3,
      },
      btcFee: {
        maxFeePerByte: 40,
        minInputAmount: 0.001,
        minTotalSweep: 0,
        minDefaultAmount: 0.001,
        maxDefaultAmount: 0.1,
      },
    },
    onSuccess: (data) => {
      setSell(data.rate.sell.usd);
      setBuy(data.rate.buy.usd);
      setBtcPercent(data.rate.btcPercent);
      data.btcFee
        ? setBtcFee(data.btcFee)
        : setBtcFee({
            maxFeePerByte: 40,
            minInputAmount: 0.001,
            minTotalSweep: 0,
            minDefaultAmount: 0.001,
            maxDefaultAmount: 0.1,
          });
    },
  });

  const {
    execute: save,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/transactions/setRate`, {
        buy,
        sell,
        btcPercent,
      }),
    false
  );

  const handleSubmit = () => {
    if (!buy || Number(buy) < 1)
      return showDanger("Buy rate cannot be less than 0");
    if (!sell || Number(sell) < 1)
      return showDanger("Sell rate cannot be less than 0");
    if (Number(btcPercent) < 0)
      return showDanger("BTC sell percent cannot be less than 0");
    save();
  };

  const handleSaveBtcFee = async () => {
    if (!btcFee.maxFeePerByte || Number(btcFee.maxFeePerByte) < 1)
      return showDanger("Maximum fee per byte cannot be less than 0");

    try {
      const res = await post(`admin/transactions/setBtcFee`, btcFee);
      if (res.data.success) {
        showSuccess(res.data.message);
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message);
      refetch();
    }
  }, [value, refetch]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <h2 className="text-xl">Rates</h2>
        <div className="flex gap-8 mt-14">
          <div className="w-8/12">
            <div className="flex gap-5">
              <div className="relative w-3/12">
                <label>Sell Rate</label>
                <input
                  type="number"
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                  value={sell}
                  onChange={(e) => setSell(e.target.value)}
                />
                <span className="font-bold absolute top-[41px] left-4">
                  {currency("ngn")}
                </span>
              </div>
              <div className="relative w-3/12">
                <label>Buy Rate</label>
                <input
                  type="number"
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                  value={buy}
                  onChange={(e) => setBuy(e.target.value)}
                />
                <span className="font-bold absolute top-[41px] left-4">
                  {currency("ngn")}
                </span>
              </div>
              <div className="relative w-3/12">
                <label>BTC Sell Percent</label>
                <input
                  type="number"
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                  value={btcPercent}
                  onChange={(e) => setBtcPercent(e.target.value)}
                />
                <span className="font-bold absolute top-[41px] left-4">%</span>
              </div>
              <VButton
                status={status}
                onClick={handleSubmit}
                className="w-4/12 px-20 py-3 mt-8 font-light text-white bg-black rounded-lg"
              >
                Save
              </VButton>
            </div>
          </div>
        </div>

        <div className="mt-14">
          <h2 className="text-xl">Bitcoin Sweeping Fee</h2>
          <div className="flex gap-5 mt-5 md:w-8/12">
            <div className="relative w-4/12">
              <label>Maximum Fee Per Byte</label>
              <input
                type="number"
                className="w-full py-3 pl-20 mt-1 border-gray-400 rounded"
                value={btcFee.maxFeePerByte}
                name="maxFeePerByte"
                onChange={handleSetBtcFee}
              />
              <span className="font-bold absolute top-[41px] left-4">
                Satoshi
              </span>
            </div>
            <div className="relative w-4/12">
              <label>Minimum Input Amount</label>
              <input
                type="number"
                className="w-full py-3 mt-1 border-gray-400 rounded pl-14"
                value={btcFee.minInputAmount}
                name="minInputAmount"
                onChange={handleSetBtcFee}
              />
              <span className="font-bold absolute top-[41px] left-4">BTC</span>
            </div>
            <div className="relative w-4/12">
              <label>Minimum Sweep Amount</label>
              <input
                type="number"
                className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                value={btcFee.minTotalSweep}
                name="minTotalSweep"
                onChange={handleSetBtcFee}
              />
              <span className="font-bold absolute top-[41px] left-4">
                {currency("usd")}
              </span>
            </div>
          </div>
          <div className="flex gap-5 mt-5 md:w-8/12">
            <div className="relative w-4/12">
              <label>Minimum Default Input</label>
              <input
                type="number"
                className="w-full py-3 pl-20 mt-1 border-gray-400 rounded"
                value={btcFee.minDefaultAmount}
                name="minDefaultAmount"
                onChange={handleSetBtcFee}
              />
              <span className="font-bold absolute top-[41px] left-4">BTC</span>
            </div>
            <div className="relative w-4/12">
              <label>Maximum Input Amount</label>
              <input
                type="number"
                className="w-full py-3 mt-1 border-gray-400 rounded pl-14"
                value={btcFee.maxDefaultAmount}
                name="maxDefaultAmount"
                onChange={handleSetBtcFee}
              />
              <span className="font-bold absolute top-[41px] left-4">BTC</span>
            </div>
          </div>
          <VButton
            status={status}
            onClick={handleSaveBtcFee}
            className="px-20 py-3 mt-8 font-light text-white bg-black rounded-lg md:w-4/12"
          >
            Save
          </VButton>
        </div>
      </Main>
    </Container>
  );
};
export default Rates;
