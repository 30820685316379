import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VendorNav from "components/layout/VendorNav";
import VendorSidebar from "components/layout/VendorSidebar";
import { Input } from "components/misc";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { showDanger, showSuccess, validatePassword } from "utilities/misc";
import { logout, patch } from "utilities/network";

const ResetPassword: React.FC = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const {
    execute: changePassword,
    status,
    value,
  } = useAsync(
    () =>
      patch(`vendor/profile/changePassword`, {
        currentPassword: oldPassword,
        newPassword: password,
        confirmPassword: confirmPassword,
      }),
    false
  );

  const handleSubmit = () => {
    if (!validatePassword(oldPassword)) {
      return showDanger("Old password should be at least 6 characters long");
    }
    if (!validatePassword(password)) {
      return showDanger("Password should be at least 6 characters long");
    }
    if (password !== confirmPassword) {
      return showDanger("Passwords do not match");
    }

    changePassword();
  };

  useEffect(() => {
    if (value?.data?.data?.success) {
      showSuccess(value?.data?.message || "Password changed successfully");
      logout();
    }
  }, [value]);

  return (
    <Container isVendor>
      <VendorSidebar />

      <Main isVendor>
        <VendorNav title="Reset Password" />

        <div className="mt-10">
          <Input
            value={oldPassword}
            placeholder=""
            label="Current Password"
            onChange={(e) => setOldPassword(e.target.value)}
            type="password"
          />

          <Input
            value={password}
            placeholder=""
            label="New Password"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />

          <Input
            value={confirmPassword}
            placeholder=""
            label="Confirm New Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
          />

          <VButton
            onClick={handleSubmit}
            status={status}
            className="bg-orange-300 py-4 mt-10 text-white w-full"
          >
            Change Password
          </VButton>
        </div>
      </Main>
    </Container>
  );
};

export default ResetPassword;
