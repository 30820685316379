import AdminSidebar from "components/layout/AdminSidebar";
import Container from "components/layout/Container";
import Main from "components/layout/Main";
import VButton from "components/ui/VButton";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import currency from "utilities/currency";
import { showDanger, showSuccess } from "utilities/misc";
import { post } from "utilities/network";

const CreditUser: React.FC = () => {
  const [amount, setAmount] = useState("");
  const [userName, setUserName] = useState("");
  const [creditUserType, setType] = useState<any>("earning");

  const {
    execute: creditUser,
    status,
    value,
  } = useAsync(
    () =>
      post(`admin/transactions/creditUser`, {
        amount,
        userName,
        creditUserType,
      }),
    false
  );

  const handleSubmit = () => {
    if (!userName || userName.length < 3)
      return showDanger("Enter valid username");
    if (!amount || Number(amount) < 1)
      return showDanger("Amount cannot be less than 0");
    creditUser();
  };

  useEffect(() => {
    if (value?.data?.success) {
      showSuccess(value?.data?.message);
      setUserName("");
      setAmount("");
    }
  }, [value]);

  return (
    <Container>
      <AdminSidebar />
      <Main className="mx-5 mt-5 text-left">
        <h2 className="text-xl">Credit User</h2>
        <div className="flex gap-8 mt-14">
          <div className="w-8/12">
            <div className="relative mb-5 md:w-10/12">
              <label>Type</label>
              <select
                className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                value={creditUserType}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="earning">Earning</option>
                <option value="refund">Refund</option>
              </select>
            </div>
            <div className="flex gap-5">
              <div className="relative w-5/12">
                <label>Username</label>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                />
                <span className="absolute top-[41px] left-4">@</span>
              </div>
              <div className="relative w-5/12">
                <label>Amount</label>
                <input
                  type="number"
                  className="w-full py-3 pl-8 mt-1 border-gray-400 rounded"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <span className="font-bold absolute top-[41px] left-4">
                  {currency("ngn")}
                </span>
              </div>
            </div>

            <div className="text-center">
              <VButton
                status={status}
                onClick={handleSubmit}
                className="w-4/12 px-20 py-3 mt-8 font-light text-white bg-black rounded-lg"
              >
                Credit
              </VButton>
            </div>
          </div>
        </div>
      </Main>
    </Container>
  );
};
export default CreditUser;
